import { createPost, deletePost, fetchData, fetchDataEachRow, updatePost } from "../backendApi/index.js";

export const fetchContractorsList = async (
  page,
  pageSize,
  searchField,
  searchTerm,
) => {
  try {
    const response = await fetchData(`/primaryContractors?page=${page}&pageSize=${pageSize}&column=${searchField}&search=${searchTerm}`)
    return response.data;
  } catch (error) {
    console.error("Error fetching contractors:", error);
    throw error;
  }
};

export const getContractorById = async (id) => {
  try {
    const response = await fetchDataEachRow(`/primaryContractors/${id}`)
    return response.data.data;
  } catch (error) {
    console.error("Error fetching contractor details:", error);
    throw error;
  }
};

export const createContractor = async (contractorData) => {
  try {
    const response = await createPost(`/primaryContractors`, contractorData)
    return response.data;
  } catch (error) {
    console.error("Error creating contractor:", error);
    throw error;
  }
};

export const updateContractor = async (id, contractorData) => {
  try {
    const response = await updatePost(`/primaryContractors/${id}`,contractorData)
    return response.data;
  } catch (error) {
    console.error("Error updating contractor:", error);
    throw error;
  }
};

export const deleteContractor = async (id) => {
  try {
    const response = await deletePost(`/primaryContractors/${id}`)
    return response.data;
  } catch (error) {
    console.error("Error deleting contractor:", error);
    throw error;
  }
};

export const fetchCompanyTypes = async () => {
  try {
    const response = await fetchData(`/companyType`)
    return response.data;
  } catch (error) {
    console.error("Error deleting contractor:", error);
    throw error;
  }
};
