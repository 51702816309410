import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Button, Form, Input, message, Space } from "antd";
import {
  getConUserByID,
  updateContractUser,
} from "../../services/contractUserService";

const ContractUserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    loadUserDetails();
  }, [id]);

  const loadUserDetails = async () => {
    try {
      setLoading(true);
      const userData = await getConUserByID(id);
      setUser(userData);
      form.setFieldsValue(userData);
    } catch (error) {
      console.error("Error loading user details:", error);
      message.error("Failed to load user details");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      await updateContractUser(id, values);
      message.success("User details updated successfully");
      setEditing(false);
      loadUserDetails();
    } catch (error) {
      console.error("Error updating user details:", error);
      message.error("Failed to update user details");
    }
  };

  const handleCancel = () => {
    form.setFieldsValue(user);
    setEditing(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Card
      title="Contract User Details"
      extra={
        editing ? (
          <Space>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
          </Space>
        ) : (
          <Button type="primary" onClick={handleEdit}>
            Edit
          </Button>
        )
      }
    >
      <Form form={form} layout="vertical">
        <Form.Item name="fullName" label="Full Name">
          {editing ? <Input /> : <span>{user?.fullName}</span>}
        </Form.Item>
        <Form.Item name="email" label="Email">
          {editing ? <Input /> : <span>{user?.email}</span>}
        </Form.Item>
        <Form.Item name="role" label="Role">
          {editing ? <Input /> : <span>{user?.role}</span>}
        </Form.Item>
        {/* Add more fields as needed */}
      </Form>
    </Card>
  );
};

export default ContractUserDetails;
