import React, { useContext, useEffect, useState } from 'react';
import '../../../styles/Panel.scss';
import { Tabs } from 'antd';
import UserList from '../panelHead/UserList';
import { dataContext } from '../../../context/data';
import EmployeesTabs from './EmployeesTabs';
import ComplianceTabs from './ComplianceTabs';
import JobRolesTabs from './JobRolesTabs';
import UsersTabs from './UsersTabs';
import CompanyTabs from './CompanyTabs';
import GoBack from "../../../assets/icons/leftArrow.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import UserPanelSearch from '../panelHead/userPanelSearch';
import JobRoleList from '../../jobRole/JobRoleList';


function PrimaryUsersDetailsTabs({ userType }) {
  const { userData, setUserData } = useContext(dataContext);
  const { searchState } = useContext(dataContext);
  const [userName, setUserName] = useState('');
   const { id } = useLocation().state;
   console.log("id", id)
  const { TabPane } = Tabs;
  const history = useHistory();
  //console.log("searchState", searchState);

  const tabStyle = {
    fontSize: "1.3vw !important",
    fontWeight: "600",
  };

  const contractTabs = [
    "Employed",
    "Available",
    "Incomplete profile",
    "Yet to be verified",
  ];

  const handleAddUserClick = () => {
    setUserData((prev) => ({
      ...prev,
      isModalVisible: true, // Example: Show a modal or perform an action
    }));
    console.log("Add User button clicked!");
  };

  const isAddUserEnabled =
    (userType === 'spectra' && userData.activeTabIndex === "4") ||
    (userType === 'primary' && userData.activePrimaryTabIndex === "1");

  // Reset the active tab index when the component is mounted
  useEffect(() => {
    if (userType === 'spectra') {
      setUserData((pre) => ({
        ...pre,
        activeTabIndex: "1", // Reset to the first tab for Spectra users
      }));
    } else if (userType === 'contract') {
      setUserData((pre) => ({
        ...pre,
        activeContractTabIndex: "1", // Reset to the first tab for Contract users
      }));
    } else if (userType === 'primary') {
      setUserData((pre) => ({
        ...pre,
        activePrimaryTabIndex: "1", // Reset to the first tab for Primary users
      }));
    }
  }, [userType, setUserData]);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const response = await fetch(`https://urchin-app-fi4og.ondigitalocean.app/api/web/primaryEmployeers?id=${id}`);
        if (response.ok) {
          
          const data = await response.json();
          const user = data?.data?.find((user) => user.id === id); 
          setUserName(user?.name); // Assuming the API returns { name: "User Name" }
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (id) {
      fetchUserName();
    }
  }, [id]);

  return (
    <>
      {/* <span
        className="leftArw"
        onClick={() => {
          // Set activeTabIndex to 3 before going back
          setUserData((prev) => ({
            ...prev,
            activeTabIndex: "3", // Set the tab index to 3
          }));
          history.goBack(); // Go back to the previous page
        }} // Go back to the previous page
        style={{ cursor: "pointer" }}
      >
        <img src={GoBack} alt="go back" /> Profile
      </span> */}
      <div
  className="header-row"
  style={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "1rem",
  }}
>
  {/* Left Section: Back Arrow, User Name, and Search Box */}
  <div style={{ display: "flex", alignItems: "center" }}>
    <span
      className="leftArw"
      onClick={() => {
        setUserData((prev) => ({
          ...prev,
          activeTabIndex: "3", // Set the tab index to 3
        }));
        history.goBack(); // Go back to the previous page
      }}
      style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
    >
      <img
        src={GoBack}
        alt="go back"
        style={{ width: "20px", height: "20px", objectFit: "contain" }}
      />
    </span>
    {/* User Name and Search Box Next to Each Other */}
    <div style={{ display: "flex", alignItems: "center" }}>
      <span
        style={{
          fontSize: "1.1rem",
          fontWeight: "500",
          color: "#000000",
        }}
      >
        {userName}
      </span>
      <div style={{marginLeft:'2rem'}}>
      {/* Conditionally render the search box near the name */}
      {userType === "spectra" && userData.activeTabIndex === "4" && (
        <UserPanelSearch
          talents={searchState.talents}
          contractor={searchState.contractor}
          userType={searchState.userType}
          columnTalent={searchState.columnTalent}
          columns={searchState.columns}
          contractorColumns={searchState.contractorColumns}
          selectedColumn={searchState.selectedColumn}
          //style={{ marginLeft: "2rem" }} // Add margin for spacing between name and search box
        />
      )}
      </div>
    </div>
  </div>

  {/* Right Section: Add User Button */}
  {isAddUserEnabled && (
    <div style={{ paddingRight: "1rem" }}>
      <button
        className="btn btn-primary tabBtn"
        onClick={handleAddUserClick}
        style={{ borderRadius: "3px" }}
      >
        Add User
      </button>
    </div>
  )}
</div>


      {userType === 'spectra' && (
        <div className="tabs">
          <Tabs
            defaultActiveKey="1"
            activeKey={userData.activeTabIndex}
            onChange={(tabidx) =>
              setUserData((pre) => ({
                ...pre,
                activeTabIndex: tabidx,
                filterValue: [],
                filterQuery: {},
                page: 0,
              }))
            }
            className="tabSlider"
            size="large"
          >
            <TabPane tabKey="1" className="tabSlider" style={tabStyle} tab="Employees Tab" key="1">
              <EmployeesTabs />
            </TabPane>
            <TabPane tabKey="2" className="tabSlider" style={tabStyle} tab="Compliance Tab" key="2">
              <ComplianceTabs />
            </TabPane>
            <TabPane tabKey="3" className="tabSlider" style={tabStyle} tab="Job Roles Tab" key="3">
              <JobRoleList  primaryEmployerId={id} />
            </TabPane>
            <TabPane tabKey="4" className="tabSlider" style={tabStyle} tab="Users Tab" key="4">
              <UsersTabs />
            </TabPane>
            <TabPane tabKey="5" className="tabSlider" style={tabStyle} tab="Company Tab" key="5">
              <CompanyTabs />
            </TabPane>
          </Tabs>
        </div>
      )}
      {userType === 'contract' && (
        <div className="tabs">
          <div className="tabSlider tab-container-vmm">
            {contractTabs.map((name, i) => {
              return (
                <h1
                  className={userData.activeContractTabIndex === `${i + 1}` ? 'tabs active' : 'tabs'}
                  key={`${i + 1}`}
                  onClick={() =>
                    setUserData((pre) => ({
                      ...pre,
                      activeContractTabIndex: `${i + 1}`,
                      selectedRows: [],
                      filterValue: [],
                      filterQuery: {},
                      page: 0,
                    }))
                  }
                >
                  {name}
                </h1>
              );
            })}
          </div>
          <UserList talents={true} userType="contract" />
        </div>
      )}
      {userType === 'primary' && (
        <div className="tabs ">
          <Tabs
            defaultActiveKey="1"
            activeKey={userData.activePrimaryTabIndex}
            onChange={(tabidx) =>
              setUserData((pre) => ({
                ...pre,
                activePrimaryTabIndex: tabidx,
                filterValue: [],
                filterQuery: {},
                page: 0,
              }))
            }
            className="tabSlider"
            size="large"
          >
            <TabPane className="tabSlider" style={tabStyle} tab="Talents" key="1">
              <UserList talents={true} userType={userType} />
            </TabPane>
          </Tabs>
        </div>
      )}
    </>
  );
}

export default PrimaryUsersDetailsTabs;
