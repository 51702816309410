import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import axios from "axios";
import sortIcon from "../../../assets/icons/sort.svg";
import { Link, useHistory, useLocation } from "react-router-dom";

const EmployeesTabs = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState(null); // Manage sort order
  const [from, setFrom] = useState(0); // Track pagination
  const { id } = useLocation().state;

  const pageSize = 10; // Number of items per fetch

  const columns = [
    { title: "", dataIndex: "", sort: false },
    { title: "Name", dataIndex: "fullName", sort: true },
    { title: "Mobile", dataIndex: "mobileNumber", sort: false },
    { title: "State", dataIndex: "state", sort: true },
    { title: "Pincode", dataIndex: "pincode", sort: false },
    { title: "Assigned Status", dataIndex: "is_employed", sort: false },
  ];

  const fetchMoreData = async () => {
    try {
      const response = await axios.post(
        "https://urchin-app-fi4og.ondigitalocean.app/api/web/dashboard",
        {
          from,
          size: pageSize,
          type: "initial",
          sortColumn: null,
          primaryEmployeerId: [id],
        }
      );

      const fetchedData = response?.data?.data || [];

      // Remove duplicates by checking unique `id` or `dataIndex`
      const uniqueData = fetchedData.filter(
        (item) => !data.some((existingItem) => existingItem.id === item.id)
      );

      setData((prev) => [...prev, ...uniqueData]);
      setFrom((prev) => prev + pageSize); // Update `from` for the next fetch
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setIsLoading(false);
    }
  };

  const handleSort = (dataIndex) => {
    const sortedData = [...data];
    const isAscending = sortOrder === "asc";

    sortedData.sort((a, b) => {
      if (a[dataIndex] < b[dataIndex]) return isAscending ? 1 : -1;
      if (a[dataIndex] > b[dataIndex]) return isAscending ? -1 : 1;
      return 0;
    });

    setSortOrder(isAscending ? "desc" : "asc");
    setData(sortedData);
  };

  useEffect(() => {
    fetchMoreData(); // Initial fetch
  }, []);

  return (
    <div className="responsiveTable">
      <InfiniteScroll
        dataLength={data.length}
        next={fetchMoreData}
        hasMore={true}
        scrollableTarget="respTableScroll"
      >
        {isLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : data.length === 0 ? (
          <div className="noDataFound">
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                height: "80vh",
                fontSize: "24px",
                color: "#777",
                alignItems: "center",
              }}
            >
              No Data Found
            </h3>
          </div>
        ) : (
          <div className="tableWraper" id="respTableScroll" style={{paddingTop:"2rem"}}>
            <table>
              <thead>
                <tr>
                  {columns.map((field, i) => (
                    <th key={i} className="field head">
                      {field.title}{" "}
                      {field.sort && (
                        <img
                          style={{ cursor: "pointer" }}
                          width="15px"
                          height="15px"
                          onClick={() => handleSort(field.dataIndex)}
                          src={sortIcon}
                          alt="sort"
                        />
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, idx) => (
                  <tr key={idx}>
                    {columns.map((field, i) => (
                      <td key={i}>
                        {field.dataIndex === "is_employed"
                          ? item.status?.is_employed
                            ? "Assigned"
                            : "Not Assigned"
                          : item[field.dataIndex]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default EmployeesTabs;
