import React, { useState, useEffect, useCallback, useRef } from "react";
import { Table, Button, Input, Space, Spin, Select, message } from "antd";
import { useHistory } from "react-router-dom";
import { MinusCircleOutlined } from "@ant-design/icons";
import { fetchJobPosts, deleteJobPost } from "../../services/jobPostService";
import JobPostForm from "./JobPostForm";
import "./JobPostDetails.css";
import DeletePostButton from '../../assets/icons/deletePostBtn.svg'


const { Option } = Select;

const JobPostList = () => {
  const [jobPosts, setJobPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const history = useHistory();
  const [searchField, setSearchField] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");

  const observer = useRef();
  const lastPostElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const loadJobPosts = useCallback(
    async (pageNum) => {
      if (!hasMore && pageNum !== 1) return;
      setLoading(true);
      try {
        const searchParams = {
          searchField,
          searchTerm,
        };
        const response = await fetchJobPosts(pageNum, 10, searchParams);
        setJobPosts((prevPosts) =>
          pageNum === 1 ? response.data : [...prevPosts, ...response.data]
        );
        setHasMore(response.data.length === 10); // Assuming 10 is the page size
        setPage(pageNum);
      } catch (error) {
        console.error("Error loading job posts:", error);
        message.error("Failed to load job posts");
      } finally {
        setLoading(false);
      }
    },
    [searchField, searchTerm]
  );

  useEffect(() => {
    setJobPosts([]);
    setPage(1);
    setHasMore(true);
    loadJobPosts(1);
  }, [searchField, searchTerm, loadJobPosts]);

  useEffect(() => {
    if (page > 1) {
      loadJobPosts(page);
    }
  }, [loadJobPosts, page]);

  const handleCreate = () => {
    setEditingPost(null);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteJobPost(id);
      message.success("Job post deleted successfully");
      setJobPosts([]);
      setPage(1);
      setHasMore(true);
      loadJobPosts(1);
    } catch (error) {
      console.error("Error deleting job post:", error);
      message.error("Failed to delete job post");
    }
  };

  const handleSearch = (value, field) => {
    if (field === "term") {
      setSearchTerm(value);
    } else {
      setSearchField(value);
    }
  };

  const handleRowClick = (record) => {
    history.push(`job-posts/${record.id}`);
  };

  const columns = [
    { title: "JOB ID", dataIndex: "jobCode", key: "jobCode", className: 'classId' },
    { title: "DESIGNATION", dataIndex: "designation", key: "designation", className: 'data' },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      className: 'descriptionData'
    },
    {
      title: "POSTED BY",
      dataIndex: "postedBy",
      key: "postedBy",
      render: () => "Talntworx Technologies",
      className: 'data'
    },
    {
      title: "PRIMARY EMPLOYER",
      dataIndex: "primaryEmployerName",
      key: "primaryEmployerName",
      className: 'data'
    },
    {
      title: "LOCATION",
      key: "location",
      render: (_, record) => `${record.district}, ${record.state}`,
      className: 'data'
    },
    {
      title: "SALARY",
      key: "salary",
      render: (_, record) =>
        `₹${record.salaryFrom} - ₹${record.salaryTo} per ${record.salaryPerPeriod}`,
      className: 'data'
    },
    {
      title: "ACTIONS",
      key: "actions",
      render: (_, record) => (
        <Space>
          {/* <Button
            type="text"
            icon={<DeletePostButton />}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(record.id);
            }}
            className="text-red-500 hover:text-red-600"
            shape="circle"
          /> */}
          <img src={DeletePostButton} onClick={(e) => {
              e.stopPropagation();
              handleDelete(record.id);
            }}/>
        </Space>
      ),
    },
  ];

  return (
    <div
      className="post-list-container h-screen flex flex-col"
      style={{ marginBottom: "90px" }}
    >
      <div className="testttt" style={{ margin: "20px 0 0 10px" }}>
        <Space size="middle" className="flex-grow mb-4">
          <Select
            style={{ width: 150 }}
            value={searchField}
            onChange={(value) => handleSearch(value, "field")}
          >
            <Option value="All">All</Option>
            <Option value="designation">Designation</Option>
            <Option value="description">Description</Option>
            <Option value="jobCode">Job ID</Option>
            <Option value="postedBy">Posted By</Option>
          </Select>
          <Input
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value, "term")}
            style={{ width: 200 }}
          />
        </Space>
        <Button
          style={{ float: "right", height: '47px', right: '50px' }}
          type="primary"
          onClick={handleCreate}
          className="mb-4"
        >
          New Job Post
        </Button>
      </div>
      <Table
        className="jobTableList"
        rowClassName={"table-row-light"}
        columns={columns}
        dataSource={jobPosts}
        rowKey="id"
        loading={loading}
        onRow={(record, index) => ({
          onClick: () => handleRowClick(record),
          ref: index === jobPosts.length - 1 ? lastPostElementRef : null,
        })}
        pagination={false}
      />
      {loading && hasMore && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Spin />
        </div>
      )}
      <JobPostForm
        onClose={() => setModalVisible(false)}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingPost(null);
        }}
        onSuccess={() => {
          setModalVisible(false);
          setEditingPost(null);
          setJobPosts([]);
          setPage(1);
          setHasMore(true);
          loadJobPosts(1);
        }}
        initialValues={editingPost}
      />
    </div>
  );
};

export default JobPostList;
