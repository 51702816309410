import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// const config = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID
// };

// DEVELOPMENT AND UAT Firebase Config. 
// const config = {
//   apiKey: "AIzaSyAa4EacB-CFw-tqgoDL71qS3U9cd4pvMBE",
//   authDomain: "prism-dev-uat.firebaseapp.com",
//   projectId: "prism-dev-uat",
//   storageBucket: "prism-dev-uat.appspot.com",
//   messagingSenderId: "506800015789",
//   appId: "1:506800015789:web:f49f97ccf2f92fa443b924",
//   measurementId: "G-C0VGE78D69"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyAvTw3qCvhrtHbaTICzxwNj0ta5Npr1o0U",
//   authDomain: "prism-b4236.firebaseapp.com",
//   projectId: "prism-b4236",
//   storageBucket: "prism-b4236.appspot.com",
//   messagingSenderId: "191370584741",
//   appId: "1:191370584741:web:b2624de7f5e1df4054634c",
//   measurementId: "G-L968HJFVCR"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCVSyBo4LAbfKbINPTFW7zOySSpMenWihc",
  authDomain: "prism-b4236.firebaseapp.com",
  projectId: "prism-b4236",
  storageBucket: "prism-b4236.appspot.com",
  messagingSenderId: "191370584741",
  appId: "1:191370584741:web:3651cd722b3903d054634c",
  measurementId: "G-VJC3B6QR7P"
};

const app = initializeApp(firebaseConfig);
// console.log("app", app);

export default app;

// export const firebaseAuth = getAuth(app);