import React, { useEffect, useState } from "react";
import "../../styles/dashBoard.scss";
import {
  Button,
  Modal,
  Select,
  Input,
  Form,
  Cascader,
  Checkbox,
  Dropdown,
  Space,
  Menu,
  Progress,
  message,
} from "antd";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  orderBy,
  Timestamp,
  limit,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import { PieChart, Pie, Sector, Cell } from "recharts";
import viewIconChart from "../../assets/icons/viewIconChart.svg";
import viewIconLabel from "../../assets/icons/viewIconLabel.svg";
import downArrow from "../../assets/icons/downArrow.svg";
import upArrow from "../../assets/icons/upArrow.svg";
import whatappIcon from "../../assets/icons/whatappIcon.svg";
import { useContext } from "react";
import { dataContext } from "../../context/data";
import { useAuthContext } from "../../context/auth";
import { useHistory } from "react-router-dom";
import app from "../../firebase";
import axios from "axios";
// import axios from "../../axiosConfig/index";

import baseUrl from "../../utils/baseUrl";
import { fetchData, createPost } from "../../backendApi";

const db = getFirestore(app);

const Empchildren = [
  {
    name: "Assigned",
    value: "assigned",
  },
  {
    name: "Unassigned",
    value: "unassigned",
  },
];
const statusSelect = [
  {
    name: "Incomplete Profile",
    value: "incomplete_profile",
  },
  {
    name: "Completed Profile",
    value: "completed_profile",
  },
  {
    name: "Yet To Be Verified",
    value: "yet_to_be_verified",
  },
  {
    name: "Employed",
    value: "employed",
  },
  {
    name: "Available",
    value: "available",
  },
];
function DashBoardPieChart({
  filter,
  objData,
  chartData,
  filterOption,
  userPath,
  totalTalent,
}) {
  const [contractValue, setContractValue] = useState([]);
  const [totalTalents, setTotalTalents] = useState();
  const [massMessageModal, setMassMessageModal] = useState(false);
  const [messages, setMessage] = useState("");
  const [empCheck, setEmpCheck] = useState(true);
  const [selectedConId, setSelectedConId] = useState([]);
  const [selectedEmpId, setSelectedEmpId] = useState([]);
  const [contractorList, setContractorList] = useState([]);
  const [employerList, setEmployerList] = useState([]);
  const [subModal, setSubModal] = useState(false);
  const [clearForm, setClearForm] = useState("");
  const userContext = useContext(dataContext);

  const [selectUserType, setSelectUserType] = useState(false);
  const [whatsappMessage, setWhatsappMessage] = useState(false);

  const { Option } = Select;
  const { TextArea } = Input;
  const auth = useAuthContext();
  const [selectedArray, setSelectedArray] = useState([]);
  const [arrowImg, setArrowImg] = useState(downArrow);
  const history = useHistory();
  const [form] = Form.useForm();
  const COLORS = !auth.isPrimary
    ? ["#EE3964", "#F01CB5", "#34B53A", "#4339F2"]
    : ["#34B53A", "#EE3964", "#4339F2", "#F01CB5"];
  const backgroundColor = !auth.isPrimary
    ? ["#FFE2E2", "#FFE5F9", "#EAFFE0", "#EBEAFF"]
    : ["#EAFFE0", "#FFE2E2", "#EBEAFF", "#FFE6F9"];
  useEffect(() => {
    userContext.setDashboardData((pre) => {
      return {
        ...pre,
        contractValue,
        talentPage: 0,
        talentLastfetch: undefined,
      };
    });
    userContext.setDashboardData((pre) => {
      return { ...pre, DblastFetch: undefined, page: 0, totalTalentPage: 0 };
    });
  }, [contractValue]);
  useEffect(() => {
    async function filterContra() {
      const res = await fetchData(`/dashboard/primaryContractor`);
      if (res?.data?.data?.length > 0) {
        setContractorList(res?.data?.data);
      }
    }
    function filterEmplo() {
      filterOption?.map(async (itm) => {
        const querySnapshot = await getDocs(
          query(
            collection(db, "talent_users"),
            where("status.current_primary_employer_id", "==", itm.id)
          )
        );
        if (querySnapshot.docs.length > 0) {
          setEmployerList((current) => [...current, itm]);
        }
      });
    }
    if (contractorList.length == 0 && employerList.length == 0) {
      filterContra();
      filterEmplo();
    }
  }, []);
  const statusSearchQueryGenarator = (stus = "") => {
    if (stus === "employed")
      return [
        where("status.is_approved", "==", true),
        where("status.is_completed", "==", true),
        where("status.is_submitted", "==", true),
        where("status.is_employed", "==", true),
      ];
    else if (stus === "available")
      return [
        where("status.is_approved", "==", true),
        where("status.is_completed", "==", true),
        where("status.is_submitted", "==", true),
        where("status.is_employed", "==", false),
      ];
    else if (stus === "completed_profile")
      return [
        where("status.is_approved", "==", false),
        where("status.is_completed", "==", true),
        where("status.is_submitted", "==", false),
        where("status.is_employed", "==", false),
      ];
    else if (stus === "yet_to_be_verified")
      return [
        where("status.is_approved", "==", false),
        where("status.is_submitted", "==", true),
      ];
    else if (stus === "incomplete_profile")
      return [
        where("status.is_submitted", "==", false),
        where("status.is_completed", "==", false),
      ];
    else if (stus === "all") {
      return;
    }
  };
  const handleClassName = () => {
    var checkList = document.getElementById("list1");
    if (checkList.classList.contains("visible")) {
      checkList.classList.remove("visible");
      setArrowImg(downArrow);
    } else {
      checkList.classList.add("visible");
      setArrowImg(upArrow);
    }
  };
  const handleSelectAll = (e) => {
    let value = e.target.checked;
    var ele = document.getElementsByClassName("checkBox");

    for (var i = 0; i < ele.length; i++) {
      if (value == true) {
        ele[i].checked = true;
      } else {
        ele[i].checked = false;
        document.getElementById("select_any_one").checked = false;
        document.getElementById("assigned").checked = false;
        document.getElementById("unassigned").checked = false;
      }
    }
    if (value) {
      setSelectUserType(false);
      setEmpCheck(false);
      let temp = [
        "incomplete_profile",
        "completed_profile",
        "yet_to_be_verified",
        "employed",
        "available",
        "assigned",
        "unassigned",
      ];
      setSelectedArray(temp);
    } else {
      setSelectUserType(true);
      setEmpCheck(true);
      setSelectedArray([]);
    }
  };
  const handleEmployer = (e) => {
    const { value, checked } = e.target;
    let array = [...selectedArray];
    document.getElementById("select_any_one").checked = false;
    document.getElementById("unassigned").disabled = true;
    document.getElementById("assigned").disabled = true;
    if (checked) {
      setEmpCheck(false);
      array.push(value);
      setSelectedArray(array);
      document.getElementById("employerList").style.display = "block";
      if (
        array.includes("incomplete_profile") &&
        array.includes("completed_profile") &&
        array.includes("yet_to_be_verified") &&
        array.includes("available") &&
        array.includes("employed")
      ) {
        var allCheDoc = document.getElementById("allCheck");
        allCheDoc.checked = true;
      }
    } else {
      setEmpCheck(true);
      document.getElementById("employerList").style.display = "none";
      let temp = array.filter((itm) => itm !== value);
      let secondTemp = temp.filter((itm) => itm !== "assigned");
      let thirdTemp = secondTemp.filter((itm) => itm !== "unassigned");
      setSelectedArray(thirdTemp);
      var docChe = document.getElementById("allCheck");
      docChe.checked = false;
      var empChildDoc = document.getElementById("assigned");
      empChildDoc.checked = false;
      var empChldDoc = document.getElementById("unassigned");
      empChldDoc.checked = false;
    }
  };
  const handleCheckBoxValue = (e) => {
    setSelectUserType(false);

    const { value, checked } = e.target;
    let array = [...selectedArray];
    if (checked) {
      array.push(value);
      setSelectedArray(array);
      // let stavalue = statusSearchQueryGenarator(value);
      if (
        array.includes("incomplete_profile") &&
        array.includes("completed_profile") &&
        array.includes("yet_to_be_verified") &&
        array.includes("available") &&
        array.includes("employed")
      ) {
        var allCheckDoc = document.getElementById("allCheck");
        allCheckDoc.checked = true;
      }
    } else {
      const findEleIndex = array.indexOf(value);
      array.splice(findEleIndex, 1);
      if (array.length === 0) {
        setSelectUserType(true);
      }
      if (!auth.isPrimary) {
        var doc = document.getElementById("allCheck");
        doc.checked = false;
        let temp = array.filter((itm) => itm !== value);
        setSelectedArray(temp);
      }
      let temp = array.filter((itm) => itm !== value);
      setSelectedArray(temp);
    }
  };
  const handleTalents = (e, type) => {
    sessionStorage.setItem("dashboardStatusType", type);
    history.push({
      pathname: `./${userPath}/totalTalents`,
      state: { name: type, userType: auth.isSpectra ? "spectra" : "" },
    });

    userContext.setDashboardData((pre) => {
      return { ...pre, totalTalentPage: 0 };
    });
  };

  const massMessageSubmit = () => {
    if (!messages) {
      setWhatsappMessage(true);
    }
    if (selectedArray.length === 0) {
      setSelectUserType(true);
    }

    if (messages !== "" && selectedArray.length > 0) {
      let object = {
        message: messages,
        // contractor_id: auth.isContract
        //   ? localStorage.getItem("contractor_id")
        //   : selectedConId,
        // primary_employer_id: auth.isPrimary
        //   ? localStorage.getItem("primary_employer_id")
        //   : selectedEmpId,
        status: selectedArray,
      };

      const status = object.status;
      const primary_employer_id = object.primary_employer_id;
      const contractor_id = object.contractor_id;
      var mobile_no;
      if (auth.isSpectra) {
        if (
          object.status.length === 0 &&
          object.contractor_id.length === 0 &&
          object.primary_employer_id.length === 0
        ) {
          message.loading({
            content: "Loading...",
            key: "massMessage",
            duration: 0,
          });
          // axios
          //   .post(
          //     `http://localhost:7000/api/web/dashboard/spectraMassMessage`,
          //     {
          //       title: "mass_message_whatsapp_spectra_user",
          //       message: messages,
          //       type: "full_talent",
          //       primaryContractorId: auth.isContract
          //         ? localStorage.getItem("contractor_id")
          //         : selectedConId,
          //       primaryEmployerId: selectedEmpId,
          //     }
          //   )

          // createPost(`/dashboard/spectraMassMessage`, {
          //   title: "mass_message_whatsapp_spectra_user",
          //   message: messages,
          //   type: "full_talent",
          //   primaryContractorId: auth.isContract
          //     ? localStorage.getItem("contractor_id")
          //     : selectedConId,
          //   primaryEmployerId: selectedEmpId,
          // })
          //   .then((response) => {
          //     console.log("is_spectra_all_talent_mass_message", response);
          //     // mobile_no = response;
          //     if (response.data.status) {
          //       setSelectUserType(false);
          //       setWhatsappMessage(false);
          //       message.success({
          //         content: "Message sent Successfully",
          //         key: "massMessage",
          //       });
          //     } else {
          //       setSelectUserType(false);
          //       setWhatsappMessage(false);
          //       message.destroy({
          //         content: response.message,
          //         key: "massMessage",
          //       });
          //     }
          //   })
          //   .catch((error) => {
          //     setSelectUserType(false);
          //     setWhatsappMessage(false);
          //     console.log(error);
          //   });
          async function whatsappMessage() {
            const response = await axios.post(
              `https://urchin-app-fi4og.ondigitalocean.app/api/web/dashboard/spectraMassMessage`,
              {
                title: "mass_message_whatsapp_spectra_user",
                type: "condition_talent",
                query: paramsValueEmp,
                message: messages,
                primaryContractorId: auth.isContract
                  ? localStorage.getItem("contractor_id")
                  : selectedConId,
                primaryEmployerId: selectedEmpId,
                status: status,
              }
            );

            if (response.data.status === 200) {
              // /     console.log("is_spectra_all_talent_mass_message", response);
              mobile_no = response;
              if (response.data.status) {
                setSelectUserType(false);
                setWhatsappMessage(false);
                message.success({
                  content: "Message sent Successfully",
                  key: "massMessage",
                });
              } else {
                setSelectUserType(false);
                setWhatsappMessage(false);
                message.destroy({
                  content: response.message,
                  key: "massMessage",
                });
              }
              message.success({
                content: "Message sent Successfully",
                key: "massMessage",
              });
            } else {
              message.destroy({
                content: response.message,
                key: "massMessage",
              });
            }
          }
          whatsappMessage();
        } else {
          if (status.length > 0) {
            // var paramsValueEmp = {
            //   bool: {
            //     must: [
            //       {
            //         bool: {
            //           should: [],
            //         },
            //       },
            //       {
            //         bool: {
            //           should: [],
            //         },
            //       },
            //       {
            //         bool: {
            //           should: [],
            //         },
            //       },
            //     ],
            //   },
            // };
            var paramsValueEmp = [];
            status.forEach((val, i) => {
              if (val === "incomplete_profile") {
                paramsValueEmp.push(
                  {
                    status: {
                      is_completed: false,
                      is_submitted: true,
                      is_employed: false,
                    },
                  },
                  {
                    status: {
                      is_completed: false,
                      is_submitted: false,
                      is_employed: false,
                    },
                  },
                  {
                    status: {
                      is_completed: null,
                      is_submitted: null,
                      is_employed: null,
                    },
                  }
                );
              }
              if (val === "completed_profile") {
                const completedStatus = {
                  "status.is_approved": false,
                  "status.is_completed": true,
                  "status.is_submitted": false,
                  "status.is_employed": false,
                };
                paramsValueEmp.push(completedStatus);
              }
              if (val === "yet_to_be_verified") {
                const yetToBeVerifiedStatus = {
                  "status.is_approved": false,
                  "status.is_submitted": true,
                  "status.is_employed": false,
                };
                paramsValueEmp.push(yetToBeVerifiedStatus);
              }
              if (val === "available") {
                const availableStatus = {
                  "status.is_approved": true,
                  "status.is_completed": true,
                  "status.is_submitted": true,
                  "status.is_employed": false,
                };
                paramsValueEmp.push(availableStatus);
              }
              if (val === "assigned") {
                const assignedStatus = {
                  "status.is_assigned": true,
                  "status.is_employed": true,
                };
                paramsValueEmp.push(assignedStatus);
              }
              if (val === "unassigned") {
                const unassignedStatus = {
                  "status.is_assigned": false,
                  "status.is_employed": true,
                };
                paramsValueEmp.push(unassignedStatus);
              }
              if (
                val === "employed" &&
                !(status.includes("assigned") || status.includes("unassigned"))
              ) {
                const assignedStatus = {
                  "status.is_employed": true,
                };
                paramsValueEmp.push(assignedStatus);
              }
            });
          }
          if (contractor_id?.length > 0) {
            if (status.length === 0) {
              var paramsValueEmp = { bool: { should: [] } };
              contractor_id.forEach((val) => {
                paramsValueEmp.push({
                  primaryContractorUserId: val,
                });
              });
            } else {
              contractor_id.forEach((val) => {
                paramsValueEmp.push({
                  primaryContractorUserId: val,
                });
              });
            }
          }
          // if (primary_employer_id.length > 0 && status.includes("employed")) {
          //   ;
          //   primary_employer_id.forEach((val) => {
          //     paramsValueEmp.push({
          //       match: {
          //         "status.current_primary_employer_id": val,
          //       },
          //     });
          //   });
          // }
          // console.log("paramsValueEmp", paramsValueEmp);
          // message.loading({
          //   content: "Loading...",
          //   key: "massMessage",
          //   duration: 0,
          // });
          async function whatsappMessage() {
            const response = await axios.post(
              `https://urchin-app-fi4og.ondigitalocean.app/api/web/dashboard/spectraMassMessage`,
              {
                title: "mass_message_whatsapp_spectra_user",
                type: "condition_talent",
                query: paramsValueEmp,
                message: messages,
                primaryContractorId: auth.isContract
                  ? localStorage.getItem("contractor_id")
                  : selectedConId,
                primaryEmployerId: selectedEmpId,
                status: status,
              }
            );

            if (response.data.status === 200) {
              message.success({
                content: "Message sent Successfully",
                key: "massMessage",
              });
            } else {
              message.destroy({
                content: response.message,
                key: "massMessage",
              });
            }
          }
          whatsappMessage();
        }
      } else if (auth.isPrimary) {
        console.log("from primary");
        console.log("object", object);
        if (
          (object.status.length === 0 && object.contractor_id.length === 0) ||
          (object.status.length > 0 && object.contractor_id.length === 0)
        ) {
          message.loading({
            content: "Loading...",
            key: "massMessage",
            duration: 0,
          });
          axios
            .post(`${baseUrl}/primaryEmployerMassMessage`, {
              title: "mass_message_whatsapp_spectra_user",
              type: "full_talent",
              message: messages,
              employer_id: primary_employer_id,
            })
            .then((response) => {
              console.log("is_employer_talent_mass_message", response);
              // mobile_no = response;
              if (response.data.status) {
                message.success({
                  content: "Message sent Successfully",
                  key: "massMessage",
                });
              } else {
                message.destroy({
                  content: response.message,
                  key: "massMessage",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          if (
            (status.includes("assigned") &&
              status.includes("unassigned") &&
              contractor_id.length > 0) ||
            (status.length === 0 && contractor_id.length > 0)
          ) {
            let paramsValueEmp = {
              bool: {
                must: [
                  {
                    bool: {
                      must: [
                        {
                          match: {
                            "status.current_primary_employer_id":
                              primary_employer_id,
                          },
                        },
                      ],
                    },
                  },
                  {
                    bool: {
                      should: [],
                    },
                  },
                ],
              },
            };
            contractor_id.forEach((val) => {
              // paramsValueEmp.push({
              //   primaryContractorUserId: val,
              // });
            });
            message.loading({
              content: "Loading...",
              key: "massMessage",
              duration: 0,
            });
            axios
              .post(`${baseUrl}/primaryEmployerMassMessage`, {
                title: "mass_message_whatsapp_spectra_user",
                type: "isEmployerMassMessage",
                message: messages,
                employer_id: primary_employer_id,
                query: paramsValueEmp,
              })
              .then((response) => {
                console.log("is_employer_talent_mass_message", response);
                // mobile_no = response;
                if (response.data.status) {
                  message.success({
                    content: "Message sent Successfully",
                    key: "massMessage",
                  });
                } else {
                  message.destroy({
                    content: response.message,
                    key: "massMessage",
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            if (status.length > 0) {
              status.forEach((val, i) => {
                if (val === "assigned") {
                  paramsValueEmp = {
                    bool: {
                      must: [
                        {
                          bool: {
                            must: [
                              {
                                match: {
                                  "status.current_primary_employer_id":
                                    primary_employer_id,
                                },
                              },
                              {
                                match: {
                                  "status.is_assigned": true,
                                },
                              },
                              {
                                match: {
                                  "status.is_employed": true,
                                },
                              },
                            ],
                          },
                        },
                        {
                          bool: {
                            should: [],
                          },
                        },
                      ],
                    },
                  };
                }
                if (val === "unassigned") {
                  paramsValueEmp = {
                    bool: {
                      must: [
                        {
                          bool: {
                            must: [
                              {
                                match: {
                                  "status.current_primary_employer_id":
                                    primary_employer_id,
                                },
                              },
                              {
                                match: {
                                  "status.is_assigned": false,
                                },
                              },
                              {
                                match: {
                                  "status.is_employed": true,
                                },
                              },
                            ],
                          },
                        },
                        {
                          bool: {
                            should: [],
                          },
                        },
                      ],
                    },
                  };
                }
              });
              if (contractor_id.length > 0) {
                contractor_id.forEach((val) => {
                  paramsValueEmp.bool.must[1].bool.should.push({
                    match: {
                      "status.current_contractor_id": val,
                    },
                  });
                });
              }
              message.loading({
                content: "Loading...",
                key: "massMessage",
                duration: 0,
              });
              axios
                .post(`${baseUrl}/primaryEmployerMassMessage`, {
                  title: "mass_message_whatsapp_spectra_user",
                  type: "isEmployerMassMessage",
                  message: messages,
                  employer_id: primary_employer_id,
                  query: paramsValueEmp,
                })
                .then((response) => {
                  console.log(
                    "is_employer_talent_mass_message_one_status_and_one_or_more_contractor",
                    response
                  );
                  // mobile_no = response;
                  if (response.data.status) {
                    message.success({
                      content: "Message sent Successfully",
                      key: "massMessage",
                    });
                  } else {
                    message.destroy({
                      content: response.message,
                      key: "massMessage",
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }
        }
      } else {
        if (status.length === 0 && primary_employer_id.length === 0) {
          message.loading({
            content: "Loading...",
            key: "massMessage",
            duration: 0,
          });
          axios
            .post(`${baseUrl}/contractorMassMessage`, {
              title: "mass_message_whatsapp_spectra_user",
              type: "full_talent",
              message: messages,
              contractor_id,
            })
            .then((response) => {
              console.log("is_contractor_talent_mass_message", response);
              // mobile_no = response;
              if (response.data.status) {
                message.success({
                  content: "Message sent Successfully",
                  key: "massMessage",
                });
              } else {
                message.destroy({
                  content: response.message,
                  key: "massMessage",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          paramsValueEmp = {
            bool: {
              must: [
                {
                  bool: {
                    should: [], // status query
                  },
                },
                {
                  bool: {
                    should: [], // contractor id
                  },
                },
                {
                  bool: {
                    should: [], // primary id
                  },
                },
              ],
            },
          };
          paramsValueEmp.bool.must[1].bool.should.push({
            match: {
              "status.current_contractor_id": contractor_id,
            },
          });
          if (status.length > 0) {
            status.forEach((val, i) => {
              if (val === "incomplete_profile") {
                paramsValueEmp.bool.must[0].bool.should.push(
                  {
                    bool: {
                      must: [
                        {
                          match: {
                            "status.is_completed": false,
                          },
                        },
                        {
                          match: {
                            "status.is_submitted": true,
                          },
                        },
                        {
                          match: {
                            "status.is_employed": false,
                          },
                        },
                      ],
                    },
                  },
                  {
                    bool: {
                      must: [
                        {
                          match: {
                            "status.is_completed": false,
                          },
                        },
                        {
                          match: {
                            "status.is_submitted": false,
                          },
                        },
                        {
                          match: {
                            "status.is_employed": false,
                          },
                        },
                      ],
                    },
                  }
                );
              }
              if (val === "completed_profile") {
                const completedStatus = {
                  bool: {
                    must: [
                      {
                        match: {
                          "status.is_approved": false,
                        },
                      },
                      {
                        match: {
                          "status.is_completed": true,
                        },
                      },
                      {
                        match: {
                          "status.is_submitted": false,
                        },
                      },
                      {
                        match: {
                          "status.is_employed": false,
                        },
                      },
                    ],
                  },
                };
                paramsValueEmp.bool.must[0].bool.should.push(completedStatus);
              }
              if (val === "yet_to_be_verified") {
                const yetToBeVerifiedStatus = {
                  bool: {
                    must: [
                      {
                        match: {
                          "status.is_approved": false,
                        },
                      },
                      {
                        match: {
                          "status.is_submitted": true,
                        },
                      },
                      {
                        match: {
                          "status.is_employed": false,
                        },
                      },
                    ],
                  },
                };
                paramsValueEmp.bool.must[0].bool.should.push(
                  yetToBeVerifiedStatus
                );
              }
              if (val === "available") {
                const availableStatus = {
                  bool: {
                    must: [
                      {
                        match: {
                          "status.is_approved": true,
                        },
                      },
                      {
                        match: {
                          "status.is_completed": true,
                        },
                      },
                      {
                        match: {
                          "status.is_submitted": true,
                        },
                      },
                      {
                        match: {
                          "status.is_employed": false,
                        },
                      },
                    ],
                  },
                };
                paramsValueEmp.bool.must[0].bool.should.push(availableStatus);
              }
              if (status.includes("employed_any_one") && val === "assigned") {
                const assignedStatus = {
                  bool: {
                    must: [
                      {
                        match: {
                          "status.is_assigned": true,
                        },
                      },
                      {
                        match: {
                          "status.is_employed": true,
                        },
                      },
                    ],
                  },
                };
                paramsValueEmp.bool.must[0].bool.should.push(assignedStatus);
              }
              if (status.includes("employed_any_one") && val === "unassigned") {
                const unassignedStatus = {
                  bool: {
                    must: [
                      {
                        match: {
                          "status.is_assigned": false,
                        },
                      },
                      {
                        match: {
                          "status.is_employed": true,
                        },
                      },
                    ],
                  },
                };
                paramsValueEmp.bool.must[0].bool.should.push(unassignedStatus);
              }
            });
          }
          if (primary_employer_id.length > 0 && status.includes("employed")) {
            primary_employer_id.forEach((val) => {
              paramsValueEmp.bool.must[2].bool.should.push({
                match: {
                  "status.current_primary_employer_id": val,
                },
              });
            });
          }
          message.loading({
            content: "Loading...",
            key: "massMessage",
            duration: 0,
          });
          axios
            .post(`${baseUrl}/contractorMassMessage`, {
              title: "mass_message_whatsapp_spectra_user",
              type: "contractor_talent",
              message: messages,
              query: paramsValueEmp,
            })
            .then((response) => {
              console.log(
                "is_contractor_condition_talent_mass_message",
                response
              );
              // mobile_no = response;
              if (response.data.status) {
                message.success({
                  content: "Message sent Successfully",
                  key: "massMessage",
                });
              } else {
                message.destroy({
                  content: response.message,
                  key: "massMessage",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      // form.resetFields();
      // setMassMessageModal(false);
      handleMassMessageModal();
      //  mobile_no = [{"contact": "919952825293"}];
      // mobile_no.forEach((val)=>{
      //   console.log("val", val.contact);
      //   // whatsAppMessage(val.contact)
      // })
    } else {
      setClearForm("error");
      console.log("Type somethimg in message box");
    }
    // } else {
    //   setSelectUserType(true);
    //   setWhatsappMessage(true);
    // }
  };
  const handleMassMessageModal = () => {
    setClearForm("");
    setMassMessageModal(false);
    form.resetFields();
    var ele = document.getElementsByClassName("checkBox");
    for (var i = 0; i < ele.length; i++) {
      ele[i].checked = false;
    }
    setSelectedArray([]);
    setMessage("");
    setSelectedEmpId([]);
    setSelectedConId([]);
    setEmpCheck(true);
    if (!auth.isPrimary) {
      document.getElementById("employerList").style.display = "none";
    }
  };
  const selectAnyOne = (e) => {
    if (e.target.checked) {
      document.getElementById("assigned").checked = true;
      document.getElementById("assigned").disabled = false;
      document.getElementById("unassigned").disabled = false;
    } else {
      document.getElementById("assigned").checked = false;
      document.getElementById("unassigned").checked = false;
      document.getElementById("assigned").disabled = true;
      document.getElementById("unassigned").disabled = true;
    }
    const checked = e.target.checked;
    const value = e.target.name;
    let array = [...selectedArray];
    if (checked) {
      array.push(value, "assigned");
      setSelectedArray(array);
    } else {
      var doc = document.getElementById("allCheck");
      doc.checked = false;
      let temp = array.filter((itm) => itm !== value);
      temp = temp.filter((itm) => itm !== "assigned");
      temp = temp.filter((itm) => itm !== "unassigned");
      setSelectedArray(temp);
    }
  };

  const empAssignOrUnassign = (e) => {
    let array = [...selectedArray];
    if (e.target.id == "assigned") {
      let temp = array.filter((itm) => itm !== "unassigned");
      if (temp.indexOf("assigned") == -1) {
        temp.push("assigned");
      }
      setSelectUserType(false);
      setSelectedArray(temp);
    } else {
      let temp = array.filter((itm) => itm !== "assigned");
      if (temp.indexOf("unassigned") == -1) {
        temp.push("unassigned");
      }
      setSelectedArray(temp);
      setSelectUserType(false);
    }
  };
  return (
    <div
      className="leftCover"
      style={{
        width: "50%",
      }}
    >
      <div className="chartHeader">
        <div>
          <h3>Dashboard for Talent data</h3>
        </div>
        {auth.isSpectra && (
          <div>
            <Select
              placeholder="Search Contractor"
              mode="multiple"
              showSearch
              showArrow
              size="large"
              maxTagCount={1}
              listHeight={200}
              allowClear
              style={{ minWidth: "18vw" }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              onChange={(value) => {
                setContractValue(value);
              }}
            >
              {filter?.map((itm, i) => (
                <Option
                  key={i}
                  value={itm.id}
                  disabled={
                    contractValue.length > 9
                      ? contractValue.includes(itm.id)
                        ? false
                        : true
                      : false
                  }
                >
                  {itm.name}
                </Option>
              ))}
            </Select>
          </div>
        )}
      </div>
      <div className="chart">
        <PieChart width={400} height={250}>
          <Pie
            data={chartData}
            cx={200}
            cy={120}
            innerRadius={105}
            outerRadius={120}
            cornerRadius={3}
            fill="#8884d8"
            paddingAngle={3}
            dataKey="value"
          >
            {chartData.map((entry, index) => {
              if (entry.value !== 0) {
                return <Cell key={`cell-${index}`} fill={entry.color} />;
              }
            })}
          </Pie>
        </PieChart>
        <div className="chartLabel">
          <h3>Total Talents</h3>
          <h2>{totalTalent}</h2>
          <img
            src={viewIconChart}
            alt="view button"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleTalents(e, "total")}
          ></img>
        </div>
      </div>
      <div className="labels">
        {objData.map((itm, i) => {
          return (
            <div
              className="labelList"
              style={{
                opacity:
                  itm.name === "Ready for Employement" ||
                  itm.name === "Past Employees" ||
                  itm.name === "Unassigned"
                    ? "0.4"
                    : "1",
                backgroundColor: backgroundColor[i],
              }}
            >
              <div className="ListBullet">
                <div
                  className="bullet"
                  style={{
                    borderWidth: "5px",
                    borderStyle: "solid",
                    borderColor: COLORS[i],
                  }}
                ></div>
              </div>
              <div>
                <p style={{ marginBottom: 0 }}>{itm.name}</p>
                <p style={{ color: COLORS[i], marginBottom: 0 }}>{itm.value}</p>
              </div>
              {itm.name === "Ready for Employement" ||
              itm.name === "Past Employees" ||
              itm.name === "Unassigned" ? (
                ""
              ) : (
                <div className="viewIcon">
                  <img
                    src={viewIconLabel}
                    alt="view button"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleTalents(e, itm.dataIndex)}
                  ></img>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="massMessageBtn">
        <Button
          size="large"
          type="primary"
          onClick={() => setMassMessageModal(true)}
        >
          <span>Mass message over whatsapp</span>
          <img src={whatappIcon}></img>
        </Button>
        <Modal
          visible={massMessageModal}
          className="massMessageModal"
          onCancel={handleMassMessageModal}
          footer={null}
        >
          <div className="modalHeader">
            <h2>Mass message over Whatsapp</h2>
          </div>
          <Form layout="vertical" form={form}>
            <div className="firstInput">
              <h4>Message</h4>
              <Form.Item
                name="message"
                status={clearForm}
                // rules={[{ required: true, message: "Enter your message" }]}
                help={
                  clearForm === "error"
                    ? whatsappMessage && "Enter your message"
                    : ""
                }
              >
                <TextArea
                  value={messages}
                  placeholder="Enter your message"
                  rows={5}
                  style={{
                    fontSize: "16px",
                    borderColor: clearForm === "error" ? "red" : "#d9d9d9",
                  }}
                  onChange={(e) => {
                    if (e.target.value) {
                      setWhatsappMessage(false);
                    } else {
                      setWhatsappMessage(true);
                    }
                    setMessage(e.target.value);
                  }}
                />
                {/* {selectUserType && (
                  <p style={{ color: "red" }}>Please Enter Message</p>
                )} */}
              </Form.Item>
            </div>
            <div>
              <h4>Send recipents</h4>
              <Form.Item
                help={
                  clearForm === "error"
                    ? selectUserType && "Enter select recipents"
                    : ""
                }
              >
                <div id="list1" className="dropdown-check-list" tabindex="100">
                  <span className="anchor" onClick={handleClassName}>
                    <div>
                      Filter Talent Status{" "}
                      {selectedArray.length == 0 ? null : (
                        <span
                          style={{
                            backgroundColor: "#0361fe",
                            color: "white",
                            padding: "2px 7px",
                            marginLeft: "5px",
                          }}
                        >
                          {selectedArray.length}
                        </span>
                      )}
                    </div>{" "}
                    <img src={arrowImg}></img>
                  </span>
                  <ul className="items">
                    {auth.isPrimary && (
                      <li>
                        {Empchildren.map((child) => {
                          return (
                            <li>
                              <input
                                type="checkbox"
                                className="checkBox"
                                value={child.value}
                                onChange={(e) => handleCheckBoxValue(e)}
                              />
                              <span>{child.name}</span>
                            </li>
                          );
                        })}
                      </li>
                    )}
                    {!auth.isPrimary && (
                      <li>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="checkbox"
                            id="allCheck"
                            className="checkBox"
                            onChange={handleSelectAll}
                          />
                          <span className="head">Select all</span>
                        </div>
                        <ul className="option">
                          {statusSelect.map((itm, i) => {
                            return (
                              <li>
                                {i !== 3 ? (
                                  <div className="inputBox">
                                    <input
                                      type="checkbox"
                                      className="checkBox"
                                      value={itm.value}
                                      onChange={(e) => handleCheckBoxValue(e)}
                                    />
                                    <span>{itm.name}</span>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="inputBox">
                                      <input
                                        type="checkbox"
                                        className="checkBox"
                                        value={itm.value}
                                        onChange={(e) => handleEmployer(e)}
                                      />
                                      <span>{itm.name}</span>
                                    </div>
                                    <ul
                                      id="employerList"
                                      style={{ display: "none" }}
                                    >
                                      <div>
                                        <input
                                          type="checkbox"
                                          id={"select_any_one"}
                                          name="employed_any_one"
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                            accentColor: "#0361fe",
                                            background: "white",
                                          }}
                                          onClick={(e) => selectAnyOne(e)}
                                        />
                                        <span>Select atleast any one</span>
                                      </div>
                                      {Empchildren.map((child) => {
                                        return (
                                          <li>
                                            <input
                                              type="radio"
                                              id={child.value}
                                              style={{
                                                height: "20px",
                                                width: "20px",
                                                accentColor: "#0361fe",
                                                background: "white",
                                              }}
                                              value={child.value}
                                              name="employedValue"
                                              onClick={(e) =>
                                                empAssignOrUnassign(e)
                                              }
                                            />
                                            <span>{child.name}</span>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    )}
                  </ul>
                </div>
              </Form.Item>
              {(auth.isPrimary || auth.isSpectra) && (
                <Form.Item name="contracor">
                  <Select
                    placeholder="Filter Contractor"
                    size="large"
                    mode="multiple"
                    maxTagCount="responsive"
                    allowClear
                    showSearch
                    listHeight={200}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(value) => {
                      setSelectedConId(value);
                    }}
                  >
                    {contractorList?.map((itm, i) => (
                      <Option
                        key={i}
                        value={itm.id}
                        disabled={
                          selectedConId.length > 9
                            ? selectedConId.includes(itm.id)
                              ? false
                              : true
                            : false
                        }
                      >
                        {itm.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {(auth.isContract || auth.isSpectra) && (
                <Form.Item name="primary_employer">
                  <Select
                    placeholder="Filter Primary employer"
                    size="large"
                    mode="multiple"
                    allowClear
                    showSearch
                    maxTagCount="responsive"
                    listHeight={200}
                    disabled={empCheck}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(value) => {
                      setSelectedEmpId(value);
                    }}
                  >
                    {filterOption?.map((itm, i) => (
                      <Option
                        key={i}
                        value={itm.id}
                        disabled={
                          selectedEmpId.length > 9
                            ? selectedEmpId.includes(itm.id)
                              ? false
                              : true
                            : false
                        }
                      >
                        {itm.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>
            <div className="modalFooter">
              <div className="footerbtns">
                <Form.Item>
                  <Button
                    key="back"
                    type="secondary"
                    className="btn"
                    size="large"
                    style={{ marginRight: "1.5rem" }}
                    onClick={handleMassMessageModal}
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    key="submit"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    className="btn"
                    onClick={massMessageSubmit}
                  >
                    Send
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>
      </div>
      <div className="subscription">
        <div>
          <h3>{auth.isSpectra ? "Subscription requests" : "Subscription"}</h3>
        </div>
        {auth.isSpectra ? (
          <div className="subDetail">
            <div className="total">
              <div>
                <h3>Total</h3>
                <p>50</p>
              </div>
            </div>
            <div className="userValue">
              <div className="subUser">
                <h4>Contractor</h4>
                <Progress
                  type="circle"
                  percent={80}
                  format={(percent) => `${percent}`}
                  width={50}
                  strokeColor="#0361fe"
                  trailColor="#F8F0FF"
                />
              </div>
              <div className="subUser">
                <h4>Principal Employers</h4>
                <Progress
                  type="dashboard"
                  percent={100}
                  format={(percent) => `${percent}`}
                  width={50}
                  strokeColor="#0361fe"
                  trailColor="#F8F0FF"
                />
              </div>
            </div>
            <div className="viewAllbtn">
              <Button type="primary">View All</Button>
            </div>
          </div>
        ) : (
          <div className="subReq">
            <div>
              <p>
                In commonly used to demonstrate the visual form publishing and
                graphic design, Lorem ipsum is a placeholder
                <strong> “Pay roll” </strong> and{" "}
                <strong> “Attendence report” </strong>text commonly used to
                demonstrate the visual.
              </p>
            </div>
            <div>
              <Button
                type="primary"
                size="large"
                style={{ fontWeight: "bold" }}
                onClick={() => setSubModal(true)}
                disabled={true}
              >
                Get Subscribed
              </Button>
              <Modal
                visible={subModal}
                onCancel={() => setSubModal(false)}
                footer={null}
                centered
              >
                <div style={{ textAlign: "center" }}>
                  <h2>Payroll & Attendance Subscription</h2>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    margin: "5%",
                  }}
                >
                  <p>
                    To access the “payroll and attedance” feature send us a
                    request
                  </p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button type="secondary" onClick={() => setSubModal(false)}>
                    Cancel
                  </Button>
                  <Button type="primary" style={{ marginLeft: "15px" }}>
                    Request
                  </Button>
                </div>
              </Modal>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default DashBoardPieChart;
