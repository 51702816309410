import React, { useState } from "react";
import { Tabs } from "antd";
import JobRoleList from "../component/jobRole/JobRoleList.js";
import JobPostList from "../component/jobPost/JobPostList.js";

const { TabPane } = Tabs;

export default function JobManagement() {
  const [activeKey, setActiveKey] = useState("1");

  const handleTabChange = (key) => {
    setActiveKey(key);
    console.log(key);
  };

  return (
    <div className="topTab">
      <Tabs
        destroyInactiveTabPane
        defaultActiveKey="1"
        activeKey={activeKey}
        onChange={handleTabChange}
        className="tabs"
      >
        <TabPane tab="Job posted" key="1" className="tabSlider">
          {activeKey === "1" && <JobPostList />}
        </TabPane>
      </Tabs>
    </div>
  );
}
