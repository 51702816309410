import React from "react";
import TopBar from "../component/TopBar";
import { Route, Switch } from "react-router-dom";
import DashBoard from "../component/DashBoard";
import JobList from "../component/JobList";
import "../styles/navBar.scss";
import UserPanel from "../component/UserPanel";
import UserDetailsBase from "../component/panel/UserDetails/UserDetailsBase";
import "../firebase";
// import TotalTalents from '../component/dashboard/TotalTalents';
import TotalTalentsView from "../component/dashboard/TotalTalentsView";
import Requests from "../component/Requests";
import RoleDetailBase from "../component/joblist/RoleBase";
import JobPostDetails from "../component/jobPost/JobPostDetails";
import JobRoleDetails from "../component/jobRole/JobRoleDetails";
function SpectrawiseAdmin(props) {
  const userType = "spectra";
  const userPath = "Talntworx";

  return (
    <div className="body">
      <TopBar userPath={userPath} />

      <div className="content">
        <Switch>
          <Route path="/Talntworx" exact>
            <DashBoard userType={userType} userPath={userPath} />
          </Route>
          <Route path="/Talntworx/userPanel">
            <UserPanel userType={userType} userPath={userPath} />
          </Route>
          <Route path="/Talntworx/jobpost">
            <JobList userType={userType} userPath={userPath} />
          </Route>
          <Route path="/Talntworx/requests">
            <Requests userType={userType} userPath={userPath} />
          </Route>
          <Route path={"/" + userPath + "/user"}>
            <UserDetailsBase userType={userType} userPath={userPath} />
          </Route>
          <Route path={"/" + userPath + "/totalTalents"}>
            <TotalTalentsView userPath={userPath} />
          </Route>
          <Route path="/Talntworx/job-roles/:id">
            <JobRoleDetails />
          </Route>
          <Route path="/Talntworx/job-posts/:id">
            <JobPostDetails />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default SpectrawiseAdmin;
