import {
  Modal,
  AutoComplete,
  Input,
  Select,
  message,
  notification,
  DatePicker,
  TimePicker,
  Button,
  Tooltip,
  Form,
  Space,
} from "antd";
//import AssignTalent from "./AssignTalent";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  addDoc,
  firestore,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  getFirestore,
  Timestamp,
  deleteDoc,
  query,
  setDoc,
  where,
  serverTimestamp,
  get,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import axios from "axios";
import { useContext } from "react";
import { dataContext } from "../../../context/data";


const functions_url = process.env.REACT_APP_FUNCTIONS_URL;
const HeadUsersTabs = ({ userType, primaryEmployeerNameView }) => {
  const db = getFirestore();
  const { id } = useLocation().state;
  const [form] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [isModalVisible, setisModalVisible] = useState(false);
  const { userData, setUserData } = useContext(dataContext);
  const [isEnrollModalVisible, setisEnrollModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  //for modal details
  const [userTypeSelected, setUserTypeSelected] = useState(
    "primary_employer_user"
  );
  const [businessName, setBusinessName] = useState("");
  
  const [editKey, setEditKey] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [{ primary_employers, contractors }, setPrimaryAndContract] = useState({
    primary_employers: [],
    contractors: [],
  });
  const [businessID, setBusinessID] = useState("");
  const [primaryEmpData, setPrimaryEmpData] = useState([]);
  const userContext = useContext(dataContext);
  const [imageValue, setImageValue] = useState(null);
  const [uploadErr, setUploadErr] = useState(false);
  const [imageUploads, setImageUpload] = useState([]);
  const [verify, setVerify] = useState(false);
  const [panIdName, setPanIdName] = useState("");
  const [contractDetails, setContractDetails] = useState({
    bname: "",
    company: "",
    address: "",
    pan: "",
    gstin: "",
    epf: "",
    esic: "",
    pt: "",
    tan: "",
    linkedPrimaryEmployers: [],
  });
  const [primaryDetails, setPrimaryDetails] = useState({
    bname: "",
    company: "",
    address: "",
    pan: "",
    gstin: "",
    epf: "",
    esic: "",
    pt: "",
    tan: "",
  });
  const [error, setError] = useState({
    pan: false,
    gstin: false,
    epf: false,
    esic: false,
    pt: false,
    tan: false,
  });

  // console.log("contractDetails", contractDetails)

  const { Option } = Select;
  const storage = getStorage();

  const [isHovered, setIsHovered] = useState(false);
  //console.log(userData.editUser.mnum, "userData primary fetch", phoneNumber)
  
//   const onImageChange = (event) => {
//     if (event.target.files && event.target.files[0]) {
//       if (
//         event.target.files[0].type === "image/png" &&
//         event.target.files[0].size < 200000
//       ) {
//         setImageValue(URL.createObjectURL(event.target.files[0]));
//         setImageUpload(event.target.files[0]);
//         setUploadErr(false);
//       } else {
//         setUploadErr(true);
//         setImageUpload([]);
//       }
//     }
//   };
  const closeUpoloadImg = () => {
    setImageValue(false);
  };

  const showErrorModal = (errorMessages) => {
    Modal.error({
      title: "Validation Errors",
      content: (
        <ul>
          {errorMessages.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      ),
      okText: "Close",
    });
  };

  useEffect(() => {
    setUserTypeSelected(
      userData?.activeTabIndex == "4"
        ? "contractor_user"
        : userData?.activeTabIndex == "5"
        ? "primary_employer_user"
        : userData?.activeTabIndex == "6"
        ? "spectrawise_bizops_user"
        : ""
    );
  }, [userData?.activeTabIndex]);

  useEffect(() => {
    if (userTypeSelected === "spectrawise_bizops_user")
      setBusinessName("Talntworx Technologies ");
    else setBusinessName("");
  }, [userTypeSelected]);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const metadata = {
    contentType: "image/jpeg",
  };
//   async function uploadImg(str) {
//     try {
//       // if (str === "" || /[]{}sd/.test(str))
//       const StrRef = ref(
//         getStorage(),
//         `/docImages/${moment().format("YYYYMMDD_hhmmss_SSS")}`
//       );
//       await uploadString(StrRef, str, "data_url");
//       const res = await getDownloadURL(StrRef);
//       console.log(res);
//       return res;
//     } catch (err) {
//       console.log(err);
//       return str;
//     }

//     // docIploadString(${moment()})
//   }

  async function handleModal(modal) {
    if (userData?.activeTabIndex == "6")
      setBusinessName("Talntworx Technologies ");
    else setBusinessName("");
    setPhoneNumber("");
    setFullName("");
    setCountryCode("+91");
    setBusinessID("");
    if (["4", "5", "6"].includes(userData?.activeTabIndex)) {
      //get contractors and primary employers business name
      message.loading({
        content: "Please wait...",
        duration: 0,
        key: "fetching",
      });

      const primary_employers = [];
      const contractors = [];
      const q = query(collection(db, "primary_employers"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        primary_employers.push({ id: doc.id, name: doc.data().name });
      });
      const qcontra = query(collection(db, "contractors"));
      const snapshotContra = await getDocs(qcontra);
      snapshotContra.forEach((doc) => {
        contractors.push({ id: doc.id, name: doc.data().name });
      });
      setPrimaryAndContract((pre) => {
        return { contractors, primary_employers };
      });
      message.destroy("fetching");
    }
    setisModalVisible(true);
    setImageValue(false);
    setContractDetails({
      bname: "",
      company: "",
      address: "",
      pan: "",
      gstin: "",
      epf: "",
      esic: "",
      pt: "",
      tan: "",
      linkedPrimaryEmployers: [],
    });
    setPrimaryDetails({
      bname: "",
      company: "",
      address: "",
      pan: "",
      gstin: "",
      epf: "",
      esic: "",
      pt: "",
      tan: "",
    });
    setError({
      pan: false,
      gstin: false,
      epf: false,
      esic: false,
      pt: false,
      tan: false,
    });
    setVerify(false);
  }

// async function handleModalSave() {
//     try {
//       if (
//         !phoneNumber.match(/^[6789]\d{9}$/) &&
//         ["4"].includes(userData?.activeTabIndex)
//       ) {
//         message.error({ content: "Please enter correct mobile number" });
//         return;
//       }
//       if (userData?.activeTabIndex === "3" && uploadErr) {
//         return;
//       }
  
//       message.loading({ content: "Saving...", duration: 0, key: "saving" });
  
//       if (editKey === null) {
//         try {
//           const chkMobileQ = query(
//             collection(db, "all_users"),
//             where("mobile_number", "==", countryCode + phoneNumber)
//           );
//           const chkMobile = await getDocs(chkMobileQ);
//           if (!chkMobile.empty) {
//             message.error({
//               content: "Mobile number already exists",
//               key: "saving",
//             });
//             return;
//           }
  
//           // Prepare the data to send to the API
//           const requestData = {
//             fullName: fullName,
//             bizopId: "1c785939-aa5e-4c27-bf76-207725070781",
//             mobileNumber: `${countryCode}${phoneNumber}`,
//             primaryEmployeerId: id,
//             allUserId: "1585ee5e-a10c-4a37-b9a3-74099fa64bc0",
//           };
//   //console.log(requestData, "requestData")
//           // Make the API call to save data in the employeer_users table
//           const response = await axios.post(
//             "https://urchin-app-fi4og.ondigitalocean.app/api/web/employeerUsers",
//             requestData
//           );
//          // console.log("API Response:", response.data);
        
//           if (response?.data?.status === 201 || response?.data?.status === 200) {
//             message.success({
//               content: "Created successfully",
//               key: "saving",
//             });
//             setisModalVisible(false);
//             setUserData((pre) => ({ ...pre, reload: !pre.reload }));
//           } else {
//             message.error({
//               content: `Unexpected response: ${response?.data?.message}`,
//               key: "saving",
//             });
//           }
//         } catch (e) {
//           console.error("Error adding document: ", e);
//           message.error({ content: "Something went wrong", key: "saving" });
//         }
//       } else {
//         try {
//           if (phoneNumber !== userData.editUser?.mnum.slice(3)) {
//             const chkMobileQ = query(
//               collection(db, "all_users"),
//               where("mobile_number", "==", countryCode + phoneNumber)
//             );
//             const chkMobile = await getDocs(chkMobileQ);
//             if (!chkMobile.empty) {
//               message.error({
//                 content: "Mobile number already exists",
//                 key: "saving",
//               });
//               return;
//             }
//           }
  
//           const requestData = {
//             fullName: fullName,
//             bizopId: "1c785939-aa5e-4c27-bf76-207725070781",
//             mobileNumber: `${countryCode}${phoneNumber}`,
//             primaryEmployeerId: id,
//             allUserId: "1585ee5e-a10c-4a37-b9a3-74099fa64bc0",
//           };
  
//           // Update API call for the existing user
//           await axios.put(
//             `https://urchin-app-fi4og.ondigitalocean.app/api/web/employeerUsers/${editKey}`,
//             requestData
//           );
  
//           message.success({
//             content: "Updated successfully",
//             key: "saving",
//           });
  
//           setisModalVisible(false);
//           setUserData((pre) => {
//             return { ...pre, reload: !pre.reload };
//           });
//         } catch (e) {
//           console.error("Error updating document: ", e);
//           message.error({ content: "Something went wrong", key: "saving" });
//         }
//       }
//     } catch (err) {
//       console.error(err);
//       message.error({
//         content: "Unexpected error occurred",
//         key: "saving",
//       });
//     }
//   }
  
async function handleModalSave() {
  try {
    const normalizedPhoneNumber = phoneNumber.startsWith(countryCode)
      ? phoneNumber.slice(countryCode.length)
      : phoneNumber;

    // Validate phone number format
    if (
      !normalizedPhoneNumber.match(/^[6789]\d{9}$/) &&
      ["4"].includes(userData?.activeTabIndex)
    ) {
      message.error({ content: "Please enter correct mobile number" });
      return;
    }

    message.loading({ content: "Saving...", duration: 0, key: "saving" });

    const requestData = {
      fullName: fullName, // Ensure fullName is taken from state
      bizopId: "1c785939-aa5e-4c27-bf76-207725070781",
      mobileNumber: `${countryCode}${normalizedPhoneNumber}`,
      primaryEmployeerId: id
     // allUserId: "1585ee5e-a10c-4a37-b9a3-74099fa64bc0",
    };

    if (editKey === null) {
      // Add new user logic
      const chkMobileQ = query(
        collection(db, "all_users"),
        where("mobile_number", "==", requestData.mobileNumber)
      );
      const chkMobile = await getDocs(chkMobileQ);
      if (!chkMobile.empty) {
        message.error({
          content: "Mobile number already exists",
          key: "saving",
        });
        return;
      }

      const response = await axios.post(
        "https://urchin-app-fi4og.ondigitalocean.app/api/web/employeerUsers",
        requestData
      );

      if (response?.data?.status === 201 || response?.data?.status === 200) {
        message.success({
          content: "Created successfully",
          key: "saving",
        });
        setisModalVisible(false);
        setUserData((prev) => ({
          ...prev,
          isModalVisible: false, // Example: Show a modal or perform an action
        }));
        setUserData((prev) => ({ ...prev, reload: !prev.reload }));
      } else {
        message.error({
          content: `Unexpected response: ${response?.data?.message}`,
          key: "saving",
        });
      }
    } else {
      // Update existing user logic
      if (phoneNumber !== userData.editUser?.mnum.slice(3)) {
        const chkMobileQ = query(
          collection(db, "all_users"),
          where("mobile_number", "==", requestData.mobileNumber)
        );
        const chkMobile = await getDocs(chkMobileQ);
        if (!chkMobile.empty) {
          message.error({
            content: "Mobile number already exists",
            key: "saving",
          });
          return;
        }
      }
      const response = await axios.put(
        `https://urchin-app-fi4og.ondigitalocean.app/api/web/employeerUsers/${editKey}`,
        requestData
      );

      if (response?.data?.status === 201 || response?.data?.status === 200) {
        message.success({
          content: "Updated successfully",
          key: "saving",
        });
        setUserData((prev) => ({
          ...prev,
          isModalVisible: false, // Example: Show a modal or perform an action
        }));
        setisModalVisible(false);
        setUserData((prev) => ({ ...prev, reload: !prev.reload }));
      } else {
        message.error({
          content: `Unexpected response: ${response?.data?.message}`,
          key: "saving",
        });
      }

      // await axios.put(
      //   `http://localhost:7000/api/web/employeerUsers/employeerUsers/${editKey}`,
      //   requestData
      // );

      // message.success({
      //   content: "Updated successfully",
      //   key: "saving",
      // });
      // setisModalVisible(false);
      // setUserData((prev) => ({ ...prev, reload: !prev.reload }));
    }
  } catch (err) {
    console.error(err);
    message.error({
      content: "Unexpected error occurred",
      key: "saving",
    });
  }
}


  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current && userData.editUser) {
      if (localStorage.getItem("count") == 1) {
        async function fetch() {
          if (["2", "3", "4", "5", "6"].includes(userData?.activeTabIndex)) {
            //get contractors and primary employers business name
            message.loading({
              content: "Please wait...",
              duration: 0,
              key: "fetching",
            });

            const primary_employers = [];
            const contractors = [];
            const q = query(collection(db, "primary_employers"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              primary_employers.push({ id: doc.id, name: doc.data().name });
            });
            const qcontra = query(collection(db, "contractors"));
            const snapshotContra = await getDocs(qcontra);
            // console.log("contractors",)
            snapshotContra.forEach((doc) => {
              contractors.push({ id: doc.id, name: doc.data().name });
              // console.log("contractors",doc.data())
            });
            setPrimaryAndContract((pre) => {
              return { contractors, primary_employers };
            });
            message.destroy("fetching");
            setisModalVisible(true);
          }
        }
        fetch();
      } else {
        localStorage.setItem("count", 1);
      }
      localStorage.setItem("count", 2);
      localStorage.setItem("editUsrMnum", userData.editUser?.mnum);
      setBusinessName(userData.editUser?.bName || "");
      setPhoneNumber(userData.editUser?.mnum || "");
      setFullName(userData.editUser?.name || "");
      setCountryCode(userData.editUser?.mnum?.slice(0, 3) || "+91");
      setBusinessID(userData.editUser?.bId || "");
      setEditKey(userData.editUser?.key || "");
      setImageValue(userData.editUser?.logo || "");
      setVerify(
        userData.editUser?.pan || userData.editUser?.pan !== "" ? true : false
      );
      {
        userData.activeTabIndex == "2" && setContractDetails(userData.editUser);
      }
      {
        userData.activeTabIndex == "3" &&
          setPrimaryDetails((pre) => {
            return {
              bname: userData.editUser?.bname,
              company: userData.editUser?.company,
              address: userData.editUser?.address,
              pan: userData.editUser?.pan,
              gstin: userData.editUser?.gstin,
              epf: userData.editUser?.epf,
              esic: userData.editUser?.esic,
              pt: userData.editUser?.pt,
              tan: userData.editUser?.tan,
            };
          });
      }
      // console.log(userData.editUser);
    } else {
      isMounted.current = true;
    }
  }, [userData.editUserTrigger]);

  function handleModalCancel(e) {
    e.preventDefault();
    const userTypeCheck =
      userData?.activeTabIndex == "4"
        ? "contractor_user"
        : userData?.activeTabIndex == "5"
        ? "primary_employer_user"
        : userData?.activeTabIndex == "6"
        ? "spectrawise_bizops_user"
        : "";
    setUserTypeSelected(userTypeCheck);
    // setBusinessID(userData?.activeTabIndex);
    setContractDetails({
      bname: "",
      company: "",
      address: "",
      pan: "",
      gstin: "",
      epf: "",
      esic: "",
      pt: "",
      tan: "",
      linkedPrimaryEmployers: [],
    });
    setPrimaryDetails({
      bname: "",
      company: "",
      address: "",
      pan: "",
      gstin: "",
      epf: "",
      esic: "",
      pt: "",
      tan: "",
    });
    setError({
      pan: false,
      gstin: false,
      epf: false,
      esic: false,
      pt: false,
      tan: false,
    });
    setImageValue(false);
    setisModalVisible(false);
    setVerify(false);
      setUserData((prev) => ({
      ...prev,
      isModalVisible: false, // Example: Show a modal or perform an action
    }));
  }

  
  useEffect(() => {
    async function fetch() {
      let temp = [];
      const q = query(collection(db, "primary_employers"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        temp.push({ value: doc.data().name, id: doc.id, key: doc.id });
      });
      // console.log(temp);
      setPrimaryEmpData(temp);
    }
    if (userType === "spectra" && isModalVisible) fetch();
  }, [isModalVisible]);

  useEffect(() => {
    async function fetch() {
      let temp = [];
      const docRef = doc(
        db,
        "contractors",
        localStorage.getItem("contractor_id")
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const contractorData = docSnap.data();
        const linkedPrimaryEmployersList =
          contractorData.linked_primary_employers;
        linkedPrimaryEmployersList?.map((doc) => {
          temp.push({ value: doc.name, id: doc.id, key: doc.id });
        });
        setPrimaryEmpData(temp);
      } else {
        console.log("No such document!");
      }
    }
    if (userType === "contract" || isEnrollModalVisible) fetch();
  }, [isEnrollModalVisible]);


  async function validateNumber() {
    let allClear = {
      pan: false,
      gstin: false,
      pt: false,
      tan: false,
      epf: false,
      esic: false,
    };
    let collectionName =
      userData.activeTabIndex == "2" ? "contractors" : "primary_employers";
    const Gstin = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const tan = /^([A-Z]){4}([0-9]){5}([A-Z]){1}?$/;

    message.loading({ content: "Validating...", key: "validate", duration: 0 });

    //GSTIN Validation
    //   if (contractDetails.gstin || primaryDetails.gstin) {
    //     let value = contractDetails.gstin || primaryDetails.gstin;

    //     if (Gstin.test(value)) {
    //       const q = query(
    //         collection(db, collectionName),
    //         where("gstin_number", "==", value)
    //       );
    //       const qRes = await getDocs(q);
    //     if (value !== userData.editUser?.gstin && !qRes.empty) {
    //       await showMessageWithDelay(`GSTIN number - ${value} already exists`, 3000);
    //     } else {
    //       allClear.gstin = true;
    //     }
    //   } else {
    //     setError((pre) => {
    //       return { ...pre, gstin: true };
    //     });
    //   }
    // }

    let errorMessages = [];

    // GSTIN Validation
    if (contractDetails.gstin || primaryDetails.gstin) {
      let value = contractDetails.gstin || primaryDetails.gstin;

      // Check if the GSTIN is valid
      if (Gstin.test(value)) {
        const q = query(
          collection(db, collectionName),
          where("gstin_number", "==", value)
        );
        const qRes = await getDocs(q);

        if (value !== userData.editUser?.gstin && !qRes.empty) {
          errorMessages.push(`GSTIN number - ${value} already exists`);
          setError((prev) => ({ ...prev, gstin: true }));
        } else {
          allClear.gstin = true;
          setError((prev) => ({ ...prev, gstin: false }));
        }
      } else {
        errorMessages.push(`Invalid GSTIN number: ${value}`);
        setError((prev) => ({ ...prev, gstin: true }));
      }
    } else {
      setError((prev) => ({ ...prev, gstin: true }));
    }

    //PT Validation

    //   if (
    //     (contractDetails.pt.length > 10 && userData?.activeTabIndex === "2") ||
    //     (primaryDetails.pt.length > 10 && userData?.activeTabIndex === "3")
    //   ) {
    //     let value =
    //       userData.activeTabIndex == "2" ? contractDetails.pt : primaryDetails.pt;
    //     const q = query(
    //       collection(db, collectionName),
    //       where("pt_reg_number", "==", value)
    //     );
    //     const qRes = await getDocs(q);
    //   if (value !== userData.editUser?.pt && !qRes.empty) {
    //     await showMessageWithDelay(`PT Reg. number - ${value} already exists`, 3000);
    //   } else {
    //     allClear.pt = true;
    //   }
    // } else if (
    //   (contractDetails.pt.length < 10 && userData?.activeTabIndex === "2") ||
    //   (primaryDetails.pt.length < 10 && userData?.activeTabIndex === "3")
    // ) {
    //   setError((pre) => {
    //     return { ...pre, pt: true };
    //   });
    // }

    // PT Validation
    if (
      (contractDetails.pt.length > 10 && userData?.activeTabIndex === "2") ||
      (primaryDetails.pt.length > 10 && userData?.activeTabIndex === "3")
    ) {
      let value =
        userData.activeTabIndex == "2" ? contractDetails.pt : primaryDetails.pt;
      const q = query(
        collection(db, collectionName),
        where("pt_reg_number", "==", value)
      );
      const qRes = await getDocs(q);

      if (value !== userData.editUser?.pt && !qRes.empty) {
        errorMessages.push(`PT Reg. number - ${value} already exists`);
        setError((prev) => ({ ...prev, pt: true }));
      } else {
        allClear.pt = true;
        setError((prev) => ({ ...prev, pt: false }));
      }
    } else if (
      (contractDetails.pt.length < 10 && userData?.activeTabIndex === "2") ||
      (primaryDetails.pt.length < 10 && userData?.activeTabIndex === "3")
    ) {
      errorMessages.push("PT Reg. number must be greater than 10 characters");
      setError((prev) => ({ ...prev, pt: true }));
    }

    //TAN Validation

    //   if (contractDetails.tan || primaryDetails.tan) {
    //     let value = contractDetails.tan || primaryDetails.tan;
    //     if (tan.test(value)) {
    //       const q = query(
    //         collection(db, collectionName),
    //         where("tan_number", "==", value)
    //       );
    //       const qRes = await getDocs(q);
    //     if (value !== userData.editUser?.tan && !qRes.empty) {
    //       await showMessageWithDelay(`TAN number - ${value} already exists`, 5000);
    //     } else {
    //       allClear.tan = true;
    //     }
    //   } else {
    //     setError((pre) => {
    //       return { ...pre, tan: true };
    //     });
    //   }
    // }

    // TAN Validation
    if (contractDetails.tan || primaryDetails.tan) {
      let value = contractDetails.tan || primaryDetails.tan;
      if (tan.test(value)) {
        const q = query(
          collection(db, collectionName),
          where("tan_number", "==", value)
        );
        const qRes = await getDocs(q);

        if (value !== userData.editUser?.tan && !qRes.empty) {
          errorMessages.push(`TAN number - ${value} already exists`);
          setError((prev) => ({ ...prev, tan: true }));
        } else {
          allClear.tan = true;
          setError((prev) => ({ ...prev, tan: false }));
        }
      } else {
        errorMessages.push(`Invalid TAN number: ${value}`);
        setError((prev) => ({ ...prev, tan: true }));
      }
    }

    //EPF Validation
    //   if (contractDetails.epf || primaryDetails.epf) {
    //     let value =
    //       userData.activeTabIndex == "2"
    //         ? contractDetails.epf
    //         : primaryDetails.epf;
    //     const q = query(
    //       collection(db, collectionName),
    //       where("epf_reg_number", "==", value)
    //     );
    //     const qRes = await getDocs(q);
    //   if (value !== userData.editUser?.epf && !qRes.empty) {
    //     await showMessageWithDelay(`EPF Reg. number - ${value} already exists`, 3000);
    //   } else {
    //     allClear.epf = true;
    //   }
    // }

    // EPF Validation
    if (contractDetails.epf || primaryDetails.epf) {
      let value =
        userData.activeTabIndex == "2"
          ? contractDetails.epf
          : primaryDetails.epf;
      const q = query(
        collection(db, collectionName),
        where("epf_reg_number", "==", value)
      );
      const qRes = await getDocs(q);

      if (value !== userData.editUser?.epf && !qRes.empty) {
        errorMessages.push(`EPF Reg. number - ${value} already exists`);
        setError((prev) => ({ ...prev, epf: true }));
      } else {
        allClear.epf = true;
        setError((prev) => ({ ...prev, epf: false }));
      }
    }

    //ESIC Validation
    //   if (contractDetails.esic || primaryDetails.esic) {
    //     let value =
    //       userData.activeTabIndex == "2"
    //         ? contractDetails.esic
    //         : primaryDetails.esic;
    //     const q = query(
    //       collection(db, collectionName),
    //       where("esic_reg_number", "==", value)
    //     );
    //     const qRes = await getDocs(q);
    //   if (value !== userData.editUser?.esic && !qRes.empty) {
    //     await showMessageWithDelay(`ESIC Reg. number - ${value} already exists`, 2000);
    //   } else {
    //     allClear.esic = true;
    //   }
    // }

    // ESIC Validation
    if (contractDetails.esic || primaryDetails.esic) {
      let value =
        userData.activeTabIndex == "2"
          ? contractDetails.esic
          : primaryDetails.esic;
      const q = query(
        collection(db, collectionName),
        where("esic_reg_number", "==", value)
      );
      const qRes = await getDocs(q);

      if (value !== userData.editUser?.esic && !qRes.empty) {
        errorMessages.push(`ESIC Reg. number - ${value} already exists`);
        setError((prev) => ({ ...prev, esic: true }));
      } else {
        allClear.esic = true;
        setError((prev) => ({ ...prev, esic: false }));
      }
    }

    // After all validations, if there are any errors, show them in a single popup
    // if (errorMessages.length > 0) {
    //   const combinedMessage = errorMessages.join("\n");
    //   await showMessageWithDelay(combinedMessage, 1000); // Show all errors in one popup
    // }

    if (errorMessages.length > 0) {
      showErrorModal(errorMessages); // Display errors in Modal
    }

    if (
      allClear.gstin &&
      allClear.pt &&
      allClear.tan &&
      allClear.epf &&
      allClear.esic
    ) {
      message.destroy("validate");
      handleModalSave();
    } else {
      console.log(allClear);
      message.destroy("validate");
    }
  }

  function formSubmit(e) {
    e.preventDefault();
    if (userData?.activeTabIndex === "2") {
      validateNumber();
    } else if (userData?.activeTabIndex === "3") {
      validateNumber();
    } else if (
      userData?.activeTabIndex === "4" ||
      userData?.activeTabIndex === "5" ||
      userData?.activeTabIndex === "6"
    ) {
      handleModalSave();
    }
  }
//   const addCompanyDetails = (e) => {
//     let field = e.target.name;
//     let value = e.target.value;
//     setError((pre) => {
//       return { ...pre, [field]: false };
//     });
//     if (userData?.activeTabIndex === "2") {
//       setContractDetails((pre) => {
//         return { ...pre, [field]: value };
//       });
//     } else if (userData?.activeTabIndex === "3") {
//       setPrimaryDetails((pre) => {
//         return { ...pre, [field]: value };
//       });
//     }
//   };

//   function onlyNumberKey(evt) {
//     // Only ASCII character in that range allowed
//     var ASCIICode = evt.which ? evt.which : evt.keyCode;
//     if (
//       (ASCIICode > 47 && ASCIICode < 58) ||
//       ASCIICode == 8 ||
//       ASCIICode == 46 ||
//       (ASCIICode > 36 && ASCIICode < 41) ||
//       (ASCIICode > 95 && ASCIICode < 106)
//     )
//       return true;
//     return evt.preventDefault();
//   }

  // const verification = async (e) => {
  //   e.preventDefault();
  //   let url = `${functions_url}/verifyCompanyPan`;
  //   // let url = "http://127.0.0.1:5001/prism-dev-uat/us-central1/verifyCompanyPan"
  //   let regex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
  //   let panNumber, collectionName;
  //   if (userData?.activeTabIndex === "2") {
  //     collectionName = "contractors";
  //     panNumber = contractDetails.pan;
  //   } else if (userData?.activeTabIndex === "3") {
  //     collectionName = "primary_employers";
  //     panNumber = primaryDetails.pan;
  //   }
  //   if (regex.test(panNumber)) {
  //     const q = query(
  //       collection(db, collectionName),
  //       where("pan_number", "==", panNumber)
  //     );
  //     const qRes = await getDocs(q);
  //     if (panNumber !== userData.editUser?.pan && !qRes.empty) {
  //       message.error({
  //         content: `Pan number - ${panNumber} is already exists`,
  //         key: "saving",
  //       });
  //     } else {
  //       message.loading({
  //         content: "verifing...",
  //         key: "panVerify",
  //         duration: 0,
  //       });
  //       axios
  //         .post(url, {
  //           id_number: panNumber,
  //         })
  //         .then((res) => {
  //           if (res.status === 200) {
  //             const {
  //               data: { data, status_code, success, message_code },
  //             } = res.data;
  //             if (
  //               status_code === 200 &&
  //               success &&
  //               message_code === "success"
  //             ) {
  //               console.log(data.category);
  //               console.log(data.full_name);
  //               setPanIdName(data.full_name);
  //               setVerify(true);
  //               message.success({
  //                 content: "verified successfully!",
  //                 key: "panVerify",
  //               });
  //             } else {
  //               message.error({
  //                 content: "Verification Failed",
  //                 key: "panVerify",
  //               });
  //             }
  //           } else {
  //             message.error({
  //               content: "Inital Request Failed",
  //               key: "panVerify",
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           message.destroy({
  //             content: "Something went wrong",
  //             key: "panVerify",
  //           });
  //         });
  //     }
  //   } else {
  //     setError((pre) => {
  //       return { ...pre, pan: true };
  //     });
  //   }
  // };

//   const verification = async (e) => {
//     e.preventDefault();
//     let url = `${functions_url}/verifyCompanyPan`;
//     let regex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
//     let panNumber, collectionName;

//     if (userData?.activeTabIndex === "2") {
//       collectionName = "contractors";
//       panNumber = contractDetails.pan;
//     } else if (userData?.activeTabIndex === "3") {
//       collectionName = "primary_employers";
//       panNumber = primaryDetails.pan;
//     }

//     if (!regex.test(panNumber)) {
//       setError((pre) => ({ ...pre, pan: true }));
//       return;
//     }

//     try {
//       const q = query(
//         collection(db, collectionName),
//         where("pan_number", "==", panNumber)
//       );
//       const qRes = await getDocs(q);

//       if (panNumber !== userData.editUser?.pan && !qRes.empty) {
//         message.error({
//           content: `Pan number - ${panNumber} already exists`,
//           key: "saving",
//         });
//         return;
//       }

//       message.loading({
//         content: "Verifying...",
//         key: "panVerify",
//         duration: 0,
//       });

//       const response = await axios.post(url, {
//         id_number: panNumber,
//       });

//       const { data, status_code, success, message_code } = response.data.data;

//       if (status_code === 200 && success && message_code === "success") {
//         console.log(data.category);
//         console.log(data.full_name);
//         setPanIdName(data.full_name);
//         setVerify(true);
//         message.success({
//           content: "Verified successfully!",
//           key: "panVerify",
//         });
//       } else {
//         throw new Error("Verification failed");
//       }
//     } catch (error) {
//       console.error("Verification error:", error);
//       message.error({
//         content:
//           error.response?.status === 500
//             ? "Server error. Please try again later."
//             : "Verification failed. Please check your PAN and try again.",
//         key: "panVerify",
//       });
//     }
//   };

//   const populatingJobRoleData = (values) => {
//     const obj = jobRolesList.find((obj) => obj.id == values);
//     form.setFieldsValue({
//       grosssalary: obj.gross_salary,
//       salary_per_period: obj.salary_per_period,
//       earnings: obj.earnings,
//       deductions: obj.deductions,
//       basic_salary: obj.basic_salary ? obj.basic_salary : 0,
//       da: obj.da ? obj.da : 0,
//       hra: obj.hra ? obj.hra : 0,
//       special_allowances: obj.special_allowance ? obj.special_allowance : 0,
//       l_t_allowances: obj.l_t_allowances ? obj.l_t_allowances : 0,
//       employer_pf: obj.employer_pf ? obj.employer_pf : 0,
//       employer_esi: obj.employer_esi ? obj.employer_esi : 0,
//     });
//   };

//   function handleSalaryValidate(e, type) {
//     console.log(e);
//     let value = e.target.value;
//     if (
//       value.match(/^[0-9]{8}$/) ||
//       e.code == "Minus" ||
//       e.code == "NumpadSubtract"
//     ) {
//       console.log("it is working");
//       e.preventDefault();
//     }
//     if (
//       type == "1" &&
//       e.target.value.length == 0 &&
//       (e.code == "Numpad0" || e.key == 0)
//     ) {
//       e.preventDefault();
//     }
//   }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (userData.isModalVisible) {
      setEditKey(null);
      handleModal();
      setUploadErr(false);
    }
  }, [userData.isModalVisible]);

  return (
    // for contract user
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
     
      <span>
        {userType !== "primary" && (
          <div className="flexBox">
            <div>
              {/* <button
                className="btn btn-primary tabBtn"
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => {
                  setEditKey(null);
                  handleModal();
                  setUploadErr(false);
                }}
              >
                Add user
              </button> */}
            </div>
          </div>
        )}
      </span>

      {/* for Talntworx user */}
      <Modal
        onCancel={handleModalCancel}
        className={
          userData.activeTabIndex == "2"
            ? "modalClass contractorModal"
            : "modalClass" && userData.activeTabIndex == "3"
            ? "modalClass contractorModal"
            : "modalClass"
        }
        title="Basic Modal"
        visible={isModalVisible}
        centered
      >
        <div className="ModalContainer" style={{ width: "300" }}>
          {(userType === "spectra" && userData.activeTabIndex === "1") ||
          userType === "contract" ? (
           null
          ) : (
            <form onSubmit={formSubmit}>
              <h3 className="modalHeader">
                {userData?.activeTabIndex === "4"
                  ? editKey
                    ? "Update Primary Employer Users"
                    : "Add Primary Employer Users"
                  : ""}
              </h3>
              <div className="modalFirstLineInput">
               
              </div>

            
              {userData?.activeTabIndex === "4" ||
              userData?.activeTabIndex === "5" ||
              userData?.activeTabIndex === "6" ? (
                <div>
                  <div>
                    <label id="name" className="label_text" htmlFor="name">
                      Name
                    </label>
                  </div>
                  <div>
                    <input
                      required
                      type="text"
                      value={fullName}
                      onChange={(e) => setFullName(e.currentTarget.value)}
                      id="name"
                      className="labelOnBorderInput"
                      placeholder={
                        userData?.activeTabIndex === "2" ? "Company name" : null
                      }
                    ></input>
                  </div>
                  <div className="modalMiddleLineInput">
                    <div className="ModalphoneNumberInput">
                      <div>
                        <div>
                          <label
                            className="phoneNumberLabel"
                            htmlFor="mobileNumber"
                          >
                            Mobile number
                          </label>
                          <div>
                            <Select
                              required
                              onChange={(val) => setCountryCode(val)}
                              className="contryCode"
                              defaultValue="+91"
                            >
                              <Option key="1" value="+91">
                                +91
                              </Option>
                              <Option key="2" value="+82">
                                +82
                              </Option>
                              <Option key="3" value="+82">
                                +72
                              </Option>
                              <Option key="4" value="+82">
                                +53
                              </Option>
                            </Select>
                            <input
                              required
                              value={phoneNumber.startsWith(countryCode) ? phoneNumber.slice(countryCode.length) : phoneNumber}
                              onChange={(e) => {
                                setPhoneNumber(e.currentTarget.value);
                              }}
                              className="phoneNumberInput"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modalUserTypeSelect">
                      <div>
                        <label>User type</label>
                      </div>
                      <div class="modalPhoneNumberInput" style={{width:"100%"}}>
                        {/* <select
                          required
                          value={userTypeSelected}
                          onChange={(e) => {
                            setUserTypeSelected(e.currentTarget.value);
                            setBusinessName("");
                            setBusinessID("");
                          }}
                          className="userSelectors"
                          placeholder="Select UserType"
                        >
                          <option value="primary_employer_user">
                            Primary Employer User
                          </option>
                          <option value="contractor_user">
                            Contractor User
                          </option>
                          <option value="spectrawise_bizops_user">
                            Talntworx Bizops
                          </option>
                        </select> */}
                        <input
                              required
                              value={"Primary Employer User"}
                              disabled
                              onChange={(e) => {
                                setUserTypeSelected(e.currentTarget.value);
                              }}
                              className="labelOnBorderInput"
                              type="text"
                            />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="modalBottomLineInput" id="name">
                      Business name
                    </label>
                    {userTypeSelected !== "spectrawise_bizops_user" && (
                      <input
                        type="text"
                        disabled
                        onChange={(e) => {
                          setBusinessName(e.currentTarget.value);
                        }}
                        value={primaryEmployeerNameView}
                        htmlFor="name"
                        placeholder=" "
                        className="labelOnBorderInput"
                      ></input>
                    )}
                    {/* {userTypeSelected !== "spectrawise_bizops_user" && (
                      <select
                        required
                        type="text"
                        value={primaryEmployeerNameView}
                        onChange={(e) => {
                          setBusinessName(
                            e.currentTarget.selectedOptions[0].innerText
                          );
                          setBusinessID(e.currentTarget.value);
                        }}
                        htmlFor="name"
                        className="labelOnBorderInput"
                      >
                        <option hidden></option>
                        {userTypeSelected === "primary_employer_user" &&
                          primary_employers.map((d) => (
                            <option value={d.id}>{d.name}</option>
                          ))}

                        {userTypeSelected === "contractor_user" &&
                          contractors.map((d) => (
                            <option value={d.id}>{d.name}</option>
                          ))}
                      </select>
                    )} */}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="modalFooter">
                <button className="cancelButton" onClick={handleModalCancel}>
                  Cancel
                </button>
                <button
                  className="saveButton"
                  type="submit"
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      userData.activeTabIndex == "2" ||
                      userData.activeTabIndex == "3"
                        ? verify
                          ? "#0361fe"
                          : "#DEB3FF"
                        : "8F00FF",
                  }}
                  disabled={
                    userData.activeTabIndex == "2" ||
                    userData.activeTabIndex == "3"
                      ? !verify
                      : false
                  }
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default HeadUsersTabs;
