import React, { useState } from "react";
import { Timestamp } from "@firebase/firestore";
import moment from "moment";
import { Input, DatePicker, Form } from "antd";

function Tab3({ ImgPopup, data, isEdit, setUserData }) {
  const [errorMessage, setErrorMessage] = useState({
    contractor: {
      contracter_name: "",
    },
    employer: {
      work_location: "",
    },
  });

  const initialPanDOB = data.identity?.pan?.dob
    ? moment(new Date(data.identity?.pan?.dob.seconds * 1000))
    : null;

  const [joinDate, setJoinDate] = useState(initialPanDOB ?? null);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const fieldName = e.target.getAttribute("data-fieldname"); // Access extra field
  };

  const handleDobChange = (date) => {
    const formattedDate = date ? date.toDate() : null;

    const formattedDate1 = date ? date.format("DD/MM/YYYY") : null;
    setUserData((prev) => ({
      ...prev,
      contractor:
        prev.contractor && Array.isArray(prev.contractor) && prev.contractor[0]
          ? [
              {
                ...prev.contractor[0],
                date_of_joining: formattedDate,
              },
            ]
          : [],
    }));
  };

  const handlePanDobChange = (date) => {
    const formattedDate = date ? date.toDate() : null;
    setJoinDate(date);
    setUserData((prev) => ({
      ...prev,
      contractor:
        prev.contractor && Array.isArray(prev.contractor) && prev.contractor[0]
          ? [
              {
                ...prev.contractor[0],
                date_of_joining: formattedDate,
              },
            ]
          : [],
    }));
  };

  return (
    <>
      <div className="detailsContainer">
        <h4 className="cardDetailsHead">Contractor</h4>
        <div className="innerContainer">
          <div className="fieldData">
            <div className="labelField">Contractor name</div>
            <div className="fieldDetail">
              {data?.primaryContractor?.name
                ? data?.primaryContractor?.name
                : "N/A"}
            </div>
          </div>
          <div className="fieldData">
            <div className="labelField">Date of Joining</div>
            <div className="fieldDetail">N/A</div>
          </div>

          <div className="fieldData">
            <div className="labelField">Date of Exit</div>
            <div className="fieldDetail">N/A</div>
          </div>
        </div>
      </div>
      <div className="detailsContainer">
        <h4 className="cardDetailsHead">Employer</h4>
        <div className="innerContainer">
          <div className="fieldData">
            <div className="labelField">Employer name</div>
            <div className="fieldDetail">
              {data?.primaryEmployer?.name
                ? data?.primaryEmployer?.name
                : "N/A"}
            </div>
          </div>
          <div className="fieldData">
            <div className="labelField">Date of Joining</div>
            <div className="fieldDetail">N/A</div>
          </div>
          <div className="fieldData">
            <div className="labelField">Date of Exit</div>
            <div className="fieldDetail">N/A</div>
          </div>
          <div className="fieldData">
            <div className="labelField">Work location</div>
            <div className="fieldDetail">
              {data?.primaryEmployer?.address
                ? data?.primaryEmployer?.address
                : "N/A"}
            </div>
          </div>
          <div className="fieldData">
            <div className="labelField">PF</div>
            <div className="fieldDetail">N/A</div>
          </div>
          <div className="fieldData">
            <div className="labelField">ESIC</div>
            <div className="fieldDetail">N/A</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tab3;
