import { createPost, deletePost, fetchData, fetchDataEachRow,updatePost } from "../backendApi";

export const fetchJobRolesList = async (page, pageSize, column, search, primaryEmployerId='') => {
  try {
    const response = await fetchData(`/jobRole?page=${page}&pageSize=${pageSize}&search=${search}&column=${column}&primaryEmployerId=${primaryEmployerId}`)
    return response.data.data;
  } catch (error) {
    console.error("Error fetching job roles:", error);
    throw error;
  }
};

export const getJobRoleById = async (id) => {
  try {
    const response = await fetchDataEachRow(`/jobRole/${id}`)
    return response.data.data;
  } catch (error) {
    console.error("Error fetching job roles:", error);
    throw error;
  }
};

export const createJobRole = async (jobRole) => {
  try {
    const response = await createPost(`/jobRole`, jobRole)
    return response.data;
  } catch (error) {
    console.error("Error creating job role:", error);
    throw error;
  }
};

export const updateJobRole = async (id, jobRole) => {
  try {
    const response = await updatePost(`/jobRole/${id}`, jobRole);
    return response.data;
  } catch (error) {
    console.error("Error updating job role:", error);
    throw error;
  }
};

export const deleteJobRole = async (id) => {
  try {
    const response = await deletePost(`/jobRole/${id}`)
    return response.data;
  } catch (error) {
    console.error("Error deleting job role:", error);
    throw error;
  }
};