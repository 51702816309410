import React from "react";
import { Modal, Form, Input, Button } from "antd";

const ContractUserForm = ({
  visible,
  onClose,
  onSubmit,
  initialValues,
  primaryContractorId,
  primaryCname,
}) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    let isMounted = true;

    if (visible) {
      if (isMounted) {
        form.setFieldsValue({
          ...initialValues,
          businessName: primaryCname, // Set businessName from primaryCname
        });
      }
    } else {
      form.resetFields();
    }

    return () => {
      isMounted = false; // Cleanup
    };
  }, [visible, initialValues, form, primaryCname]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSubmit({
        ...values,
        primaryContractorId, // Include primaryContractorId in the payload
        businessName: primaryCname, // Explicitly include businessName
      });
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  return (
    <Modal
      visible={visible}
      title={initialValues ? "Edit Contract User" : "New Contract User"}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {initialValues ? "Update" : "Create"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="fullName"
          label="Full Name"
          rules={[{ required: true, message: "Please enter the full name" }]}
        >
          <Input />
        </Form.Item>
        <div>
                    <label className="modalBottomLineInput" id="name">
                      Business name
                    </label>
        <input
                        type="text"
                        disabled
                        value={primaryCname}
                        htmlFor="name"
                        placeholder=" "
                        className="labelOnBorderInput"
                      ></input>
                      </div>

<Form.Item
  name="mobileNumber"
  label="Mobile Number"
  rules={[
    { required: true, message: "Please enter the mobile number" },
    {
      pattern: /^[6-9]\d{9}$/, // Regular expression for a 10-digit Indian mobile number starting with 6-9
      message: "Please enter a valid 10-digit mobile number",
    },
  ]}
>
  <Input
    type="text" // Use text type to avoid numeric-to-string mismatch
    maxLength={10} // Limit input to 10 digits
    onKeyPress={(event) => {
      // Prevent non-numeric input
      if (!/^\d$/.test(event.key)) {
        event.preventDefault();
      }
    }}
  />
</Form.Item>

      </Form>
    </Modal>
  );
};

export default ContractUserForm;
