import React, { useEffect, useState } from "react";
import loginPicture from "../../assets/imgs/bg-img.svg";
import "./styles/mobileNumber.scss";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../context/auth";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { message } from "antd";
import loadingIcon from "../../assets/icons/loading.gif";
import axios from "axios";

function MobileNumber() {
  const db = getFirestore();
  const auth = useAuthContext();
  const history = useHistory();
  const [otpStatus, setotpStatus] = useState("enterPhoneNumber");
  const [otp, setotp] = useState(new Array(6).fill(""));
  const [phoneNumber, setphoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  // let confirmationResult;
  const authFirebase = getAuth();
  const signInPhoneNumber = () => {
    signInWithPhoneNumber(
        authFirebase,
      `+91${phoneNumber}`,
      window.recaptchaVerifier
    )
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setotp(new Array(6).fill("")); // Clear OTP inputs
        setotpStatus("enterOtp");
        setLoading(false);
        message.destroy("signin");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error({ content: "Something went wrong", key: "signin" });
      });
  };

  useEffect(() => {
    // Initialize the reCAPTCHA verifier
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          console.log("reCAPTCHA solved", response);
          // Once reCAPTCHA is solved, trigger the sign-in flow
          // This will send the OTP
        },
        "expired-callback": () => {
          console.error("reCAPTCHA expired, please try again.");
        },
      },
      authFirebase
    );
  }, [authFirebase]);

  const sendOTP = async () => {
    try {
      setLoading(true);

      // Ensure phone number is valid before continuing
      if (!phoneNumber || phoneNumber.length !== 10) {
       
        message.error("Please enter a valid 10-digit phone number.");
        setLoading(false);
        return;
      }
      // Trigger phone number sign-in with reCAPTCHA verification
      // const phoneNumberWithCode = `+91${phoneNumber}`;
      signInPhoneNumber();
      // await signInWithPhoneNumber(
      //   authFire,
      //   phoneNumberWithCode,
      //   window.recaptchaVerifier
      // )
      //   .then((confirmationResult) => {
      //     window.confirmationResult = confirmationResult;
      //     message.success("OTP sent successfully.");
      //     setLoading(false);
      //   })
      //   .catch((error) => {
      //     console.error("Error during sign-in with phone number:", error);
      //     message.error("Failed to send OTP. Please try again.");
      //     setLoading(false);
      //   });
    } catch (e) {
      console.error("Unexpected error:", e);
      message.error("An error occurred. Please try again.");
      setLoading(false);
    }
  };

  const confirmCode = () => {
    setLoading(true);
    window.confirmationResult
      .confirm(otp.join("")) // Join OTP array to send as a string
      .then(async (res) => {
        let refreshedTokenId = await getAuth().currentUser?.getIdToken();
        if (refreshedTokenId) {
          localStorage.setItem("OTP_Token_v2", refreshedTokenId);
        }
        localStorage.setItem("uid", res.user.uid);
        const resUserType = await axios.get(
          `https://urchin-app-fi4og.ondigitalocean.app/api/web/userType/${phoneNumber}`
        );
        if (resUserType.status === 200) {
          let { userType } = resUserType.data.data.UserType;
          if (userType) {
            switch (userType) {
              case "Bizops":
                auth.signIn({
                  token: res.user.stsTokenManager.refreshToken,
                  isSpectra: true,
                  isContract: false,
                  isPrimary: false,
                });
                history.push("Talntworx");
                localStorage.setItem("userType", "spectraWise");
                break;
              case "Contractor":
                auth.signIn({
                  token: res.user.stsTokenManager.refreshToken,
                  isSpectra: false,
                  isContract: true,
                  isPrimary: false,
                });
                const contractorDocRef = doc(
                  db,
                  "contractor_users",
                  localStorage.getItem("uid")
                );
                const contractorDocRes = await getDoc(contractorDocRef);
                if (contractorDocRes.exists()) {
                  localStorage.setItem(
                    "contractor_id",
                    contractorDocRes.data().personal.contractor_id
                  );
                  history.push("contract");
                } else {
                  message.error({
                    content: "Can't get contractor details",
                    key: "verification",
                  });
                }
                break;
              case "primary_employer_user":
                auth.signIn({
                  token: res.user.stsTokenManager.refreshToken,
                  isSpectra: false,
                  isContract: false,
                  isPrimary: true,
                });
                const employerDocRef = doc(
                  db,
                  "primary_employer_users",
                  localStorage.getItem("uid")
                );
                const employerDocRes = await getDoc(employerDocRef);
                if (employerDocRes.exists()) {
                  localStorage.setItem(
                    "primary_employer_id",
                    employerDocRes.data().personal.contractor_id
                  );
                  history.push("primary");
                } else {
                  message.error({
                    content: "Can't get employer details",
                    key: "verification",
                  });
                }
                break;
              default:
                message.error({
                  content: "Something went wrong",
                  key: "verification",
                });
            }
          } else {
            setLoading(false);
            message.error({
              content: "User doesn't exist",
              key: "verification",
            });
            console.log("No such document!");
          }
          setLoading(false);
          message.destroy("verification");
        } else {
          setLoading(false);
          message.error({ content: "User doesn't exist", key: "verification" });
          console.log("No such document!");
          setLoading(false);
          message.destroy("verification");
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error({
          content: "Invalid OTP, please enter a valid OTP",
          key: "verification",
        });
        console.log(err);
      });
  };

  const handleChange = (evt, index) => {
    if (evt.keyCode === 8) {
      setotp((pre) => pre.map((item, i) => (i === index ? "" : item)));
      if (evt.currentTarget.previousElementSibling) {
        evt.currentTarget.previousElementSibling.focus();
      }
      return;
    }
    if (isNaN(evt.key)) return false;
    setotp((pre) => pre.map((item, i) => (i === index ? evt.key : item)));
    //focusing on the next input//

    if (evt.currentTarget.nextSibling) {
      evt.currentTarget.nextSibling.focus();
    }
    // console.log('the value in the OTP state is :', otp)
  };

  const validateAsNumber = (e) => {
    if (
      e.keyCode === 8 ||
      e.keyCode === 46 ||
      e.keyCode === 37 ||
      e.keyCode === 39 ||
      e.keyCode === 13
    )
      return true;
    if (isNaN(e.key)) e.preventDefault();
    else return true;
    // if (e.keyCode === 8 || e.keyCode === 46
    //     || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 13 || e.keycode > 96 || e.keycode < 105) {
    //     return true;
    // }
    // else if (e.keyCode < 48 || e.keyCode > 57) {
    //     e.preventDefault();
    // }
  };

  const handlePhoneNumberChange = (e) => {
    setphoneNumber(e.target.value);
    // console.log(phoneNumber)
  };

  return (
    <div>
      {/* <div id="sign-in-button"></div> */}
      <div className="loginPageContainer">
        <div className="loginPageLeft">
          <h3 className="loginPageHeader">TALNTX</h3>
          {otpStatus === "enterPhoneNumber" ? (
            <>
              {" "}
              <div className="loginPageNotificationPage">
                <h1 className="subHeader">Enter your mobile number</h1>
                <p>You will recieve a verification code</p>
              </div>
              <form
                onSubmit={(data) => {
                  data.preventDefault();
                  sendOTP();
                }}
              >
                <div className="phoneInput">
                  <div className="selectCountryCode">
                    <div className="countryCode" id="countryCode">
                      <p>+91</p>
                    </div>
                    <input
                      className="inputPhoneNumber"
                      value={phoneNumber}
                      required
                      maxLength="10"
                      autoFocus
                      type="tel"
                      onKeyDown={validateAsNumber}
                      onChange={handlePhoneNumberChange}
                    />
                  </div>
                </div>
                <button
                  className={
                    loading
                      ? "phoneNumberSubmitButton disabled"
                      : "phoneNumberSubmitButton"
                  }
                  href="/otpValidation"
                  value="submit"
                  type="submit"
                  id="sign-in-button"
                >
                  Next{" "}
                  {loading && (
                    <img src={loadingIcon} style={{ width: "1.5rem" }} />
                  )}
                </button>
              </form>
            </>
          ) : (
            <>
              <div className="otpPage">
                <div className="loginPageNotificationPage" maxLength="1">
                  <h1>Enter verification code </h1>
                  <p>We sent a verification code to {`+91 ${phoneNumber}`}</p>
                </div>

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    confirmCode();
                  }}
                >
                  <div className="otpPage loginPagePhoneNumber">
                    {otp.map((item, index) => {
                      return (
                        <input
                          className="otpInput"
                          maxLength="1"
                          type="text"
                          key={index}
                          autoFocus={index === 0 ? true : false}
                          required
                          value={item}
                          onChange={() => null}
                          onKeyUp={(e) => {
                            handleChange(e, index);
                          }}
                          onFocus={(e) => {
                            e.target.select();
                          }}
                        />
                      );
                    })}
                  </div>

                  <div className="resendingLinks">
                    <a href="#a" id="sign-in-button" onClick={() => sendOTP()}>
                      Send the code again
                    </a>
                  </div>
                  <div className="resendingLinks">
                    <a
                      href="#a"
                      onClick={() => setotpStatus("enterPhoneNumber")}
                    >
                      Change the phone number
                    </a>
                  </div>

                  <button
                    className={
                      loading
                        ? "phoneNumberSubmitButton disabled"
                        : "phoneNumberSubmitButton"
                    }
                    type="submit"
                  >
                    Login{" "}
                    {loading && (
                      <img src={loadingIcon} style={{ width: "1.5rem" }} />
                    )}
                  </button>
                </form>
              </div>
            </>
          )}
        </div>
        <div className="loginPageRight">
          <div>
            <img
              src={loginPicture}
              className="loginPagePicture"
              alt="home background"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileNumber;
