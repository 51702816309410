import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import searchIcon from "../../../assets/icons/search.svg";
import "../../../styles/Panel.scss";
import ResponsiveTable from "./ResponsiveTable";
import TabHead from "./TabHead";
import filterIcon from "../../../assets/icons/filter.svg";
import { dataContext } from "../../../context/data";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  orderBy,
  Timestamp,
  updateDoc,
  limit,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import app from "../../../firebase";
import { useAuthContext } from "../../../context/auth";
import moment from "moment";
import { message, Select, Input, DatePicker, Modal, Form, Button } from "antd";
import { async } from "@firebase/util";
import UserPanelSearch from "./userPanelSearch";
import MoreOption from "./MoreOption";
// import axios from "axios";
import axios from "../../../axiosConfig/index";
import baseUrl from "../../../utils/baseUrl";
import { createPost, fetchData } from "../../../backendApi";

const db = getFirestore(app);

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    sort: true,
    filter: "text",
    filterValue: "",
    sorter: (a, b) => a.name - b.name,
    width: 50,
  },
  {
    title: "Mobile Number",
    filter: "text",
    filterValue: "",
    dataIndex: "mnum",
    sort: false,
    width: 50,
  },
  {
    title: "Business name",
    filter: "text",
    filterValue: "",
    dataIndex: "bName",
    sort: true,
    sorter: (a, b) => a.bName - b.bName,
    width: 50,
  },
];

function UserList({ talents, contractor, userType }) {
  const { userData, setUserData } = useContext(dataContext);
  const [data, setData] = useState([]);
  const [dataTalent, setDataTalent] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState([
    0, 1, 2, 3, 5, 6, 9, 10, 4,
  ]);
  const auth = useAuthContext();
  const userContext = useContext(dataContext);
console.log(data, "data222")
  useEffect(() => {
    async function fetch() {
      console.log("fetching data:", data);
      const colName = auth.isSpectra
        ? "spectrawise_bizops_users"
        : auth.isContract
        ? "contractor_users"
        : auth.isPrimary
        ? "primary_employer_users"
        : "";
      const colPosData = await getDoc(
        doc(db, colName, localStorage.getItem("uid"))
      );
      // console.log(colPosData.data().settings.talent_user_column_list);;
      let selCoList = [];
      setColumnTalent((pre) => {
        const d1 = [],
          d = colPosData
            .data()
            ?.settings?.talent_user_column_list.map((didx) =>
              didx === "full_name" ? "name" : didx
            );
        d?.map((didx) => {
          if (didx !== "dob") {
            d1.push(pre.filter((itm) => itm.dataIndex === didx)[0]);
          }
        });
        selCoList = [
          ...d1.map((itm) => {
            return { ...itm, visibility: true };
          }),
          ...pre
            .filter((itm) => !d.includes(itm.dataIndex))
            .map((itm) => {
              return { ...itm, visibility: false };
            }),
        ];
        console.log("selcollist", selCoList);
        return selCoList;
      });
      setSelectedColumn((pre) => {
        let selColuList = [];
        selCoList.forEach((itm, idx) => {
          if (itm.visibility) selColuList.push(idx);
        });
        return selColuList;
      });
    }
    if (
      (auth.isSpectra && userContext.userData.activeTabIndex === "1") ||
      auth.isContract ||
      auth.isPrimary
    )
      fetch();
  }, []);
  const statusFinderTalent = (s) => {
    if (
      s?.status.is_approved &&
      s?.status.is_completed &&
      s?.status.is_submitted &&
      s?.status.is_employed
    )
      s = "Employed";
    else if (
      s?.status.is_approved &&
      s?.status.is_completed &&
      s?.status.is_submitted
    )
      s = "Available"; //need to clarify
    else if (
      !s?.status.is_approved &&
      s?.status.is_completed &&
      !s?.status.is_submitted
    )
      s = "Completed Profile";
    else if (!s?.status.is_approved && s?.status.is_submitted)
      s = "Yet To Be Verified";
    //if (!s?.is_approved && !s?.is_completed && s?.is_submitted)
    else s = "Incomplete Profile";
    return s;
  };
  const changeTalentData = (d) => {
    let s = statusFinderTalent(d);
    let bankAC = d.bank?.account_number;
    let aadhaar = d.identity?.aadhaar.aadhaar_number;
    const Masknumber = (num) => {
      return num.replace(/.(?=.{4,}$)/g, "X");
    };
    return {
      key: d?.id,
      data: d,
      contractor: d?.PrimaryContractor?.name ? d?.PrimaryContractor?.name : "",
      employer: d?.PrimaryEmployeer?.name ? d?.PrimaryEmployeer?.name : "",
      employer_id: d?.status?.current_primary_employer_id
        ? d?.status?.current_primary_employer_id
        : "",
      status: s,
      name: d?.personal?.full_name,
      dob: d?.personal?.dob
        ? moment(parseInt(d.personal?.dob)).format("DD-MM-YYYY")
        : "",
      address: `${d.contact?.address_line_1 ? d.contact?.address_line_1 : ""},
      ${d?.contact?.address_line_2 ? d?.contact?.address_line_2 : ""},
      ${d?.contact?.city || ""},
      ${d?.contact?.state || ""},
      ${d?.contact?.country || ""}-${d.contact?.pincode || ""}`,
      mnum: d?.contact?.mobile_number || "",
      gender: d?.personal?.gender || "",
      bGroup: d?.personal?.blood_group || "",
      email: d?.contact?.email || "",
      aadhaar: Masknumber(aadhaar || ""),
      pan: d?.identity?.pan?.pan_number || "",
      pincode: d?.contact?.pincode || "",
      bankAC: Masknumber(bankAC || ""),
      handicapped: d?.personal?.is_handicapped
        ? d?.personal.handicapped_reason
        : "NO",
    };
  };
  //fetch Contracter and employer details
  const getCatagory = async (type, sVal = "") => {
    try {
      let d = [],
        q = query(collection(db, type), where("name", ">=", sVal));
      const res = await getDocs(q);
      res.forEach((itm) => d.push({ ...itm.data(), id: itm.id }));
      return d;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setUserData((pre) => {
      return { ...pre, sortColumn: undefined };
    });
  }, []);

  const statusSearchQueryGenarator = (stus = "") => {
    if (stus === "Employed" || userData?.filterValue?.[0]?.sval === "Employed")
      return [
        where("status.is_approved", "==", true),
        where("status.is_completed", "==", true),
        where("status.is_submitted", "==", true),
        where("status.is_employed", "==", true),
      ];
    else if (
      stus === "Available" ||
      userData?.filterValue?.[0]?.sval === "Available"
    )
      return [
        where("status.is_approved", "==", true),
        where("status.is_completed", "==", true),
        where("status.is_submitted", "==", true),
        where("status.is_employed", "==", false),
      ];
    else if (
      stus === "Completed Profile" ||
      userData?.filterValue?.[0]?.sval === "Completed Profile"
    )
      return [
        where("status.is_approved", "==", false),
        where("status.is_completed", "==", true),
        where("status.is_submitted", "==", false),
        where("status.is_employed", "==", false),
      ];
    else if (
      stus === "Yet To Be Verified" ||
      userData?.filterValue?.[0]?.sval === "Yet To Be Verified"
    )
      return [
        where("status.is_approved", "==", false),
        where("status.is_submitted", "==", true),
      ];
    else if (
      stus === "Incomplete Profile" ||
      userData?.filterValue?.[0]?.sval === "Incomplete Profile"
    )
      return [
        where("status.is_submitted", "==", false),
        where("status.is_completed", "==", false),
      ];
    else if (stus === "All" || userData?.filterValue?.[0]?.sval === "All") {
      return;
    }
  };

  useEffect(() => {
    // dashboardData.filterType
    if (
      typeof userData.filterValue !== "undefined" &&
      userData.filterValue.length > 0
    ) {
      var field_name = "";
      if (userData.filterValue[0].dataIndex === "all") {
        field_name = "all";
      } else if (userData.filterValue[0].dataIndex === "name") {
        field_name = "personal.full_name";
      } else if (userData.filterValue[0].dataIndex === "status") {
        field_name = "status";
      } else if (userData.filterValue[0].dataIndex === "dob") {
        field_name = "personal.dob";
      } else if (userData.filterValue[0].dataIndex === "mnum") {
        field_name = "contact.mobile_number";
      } else if (userData.filterValue[0].dataIndex === "address") {
        field_name = "address";
      } else if (userData.filterValue[0].dataIndex === "gender") {
        field_name = "personal.gender";
      } else if (userData.filterValue[0].dataIndex === "bGroup") {
        field_name = "personal.blood_group";
      } else if (userData.filterValue[0].dataIndex === "email") {
        field_name = "email";
      } else if (userData.filterValue[0].dataIndex === "aadhaar") {
        field_name = "identity.aadhaar.aadhaar_number";
      } else if (userData.filterValue[0].dataIndex === "pan") {
        field_name = "identity.pan.pan_number";
      } else if (userData.filterValue[0].dataIndex === "bankAC") {
        field_name = "bank.account_number";
      } else if (userData.filterValue[0].dataIndex === "handicapped") {
        if (userData.filterValue[0].sval === "All") {
          field_name = "personal.is_handicapped";
        } else {
          field_name = "personal.handicapped_reason";
        }
      } else if (userData.filterValue[0].dataIndex === "contractor") {
        field_name = "primaryContractorUserId";
      } else if (userData.filterValue[0].dataIndex === "employer") {
        field_name = "primaryEmployeerId";
      } else if (userData.filterValue[0].dataIndex === "pincode") {
        field_name = "contact.pincode";
      }
    }
    function fetchSpectra() {
      message.loading({ content: "Loading...", duration: 0, key: "userList" });
      const talentData = [];
      if (
        typeof userData.filterValue !== "undefined" &&
        userData.filterValue.length > 0
      ) {
        if (userData.sortColumn) {
          axios
            .post(`${baseUrl}/spectraTalentData`, {
              type: "field",
              field_name: field_name,
              field_value:
                userData.filterValue[0].dataIndex === "handicapped" &&
                userData.filterValue[0].sval === "All"
                  ? true
                  : userData.filterValue[0].sval,
              sortColumn: [userData.sortColumn],
              title: "userpanel_spectra_field_fetch",
            })
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          axios
            .post(`https://urchin-app-fi4og.ondigitalocean.app/api/web/talnt`, {
              title: "userpanel_spectra_field_fetch",
              type: "field",
              field_value:
                userData.filterValue[0].dataIndex === "handicapped" &&
                userData.filterValue[0].sval === "All"
                  ? true
                  : userData.filterValue[0].sval,
              field_name: field_name,
            })
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else if (Object.keys(userData.filterQuery).length > 0) {
        if (userData.sortColumn) {
          axios
            .post(`${baseUrl}/FilterTalentDetails`, {
              title: "Talent filter",
              query: userData.filterQuery,
              sortColumn: [userData.sortColumn],
              index_name: "talent_users",
            })
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          const talentData = [];
          axios
            .post(`https://urchin-app-fi4og.ondigitalocean.app/api/web/talnt`, {
              title: "Talent filter",
              index_name: "talent_users",
              query: userData.filterQuery,
              from: userData.page * 20,
              size: 20,
              type: "All",
              option: "Filter",
            })
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else if (userData.page >= 0) {
        if (userData.sortColumn) {
          axios
            .post(`${baseUrl}/spectraTalentData`, {
              title: "userpanel_spectra_fetch",
              type: "All",
              size: 20 + userData.page * 20,
              sortColumn: [userData.sortColumn],
            })
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          async function fetchTalntData() {
            try {
              const response = await axios
                .post("https://urchin-app-fi4og.ondigitalocean.app/api/web/talnt", {
                  title: "userpanel_spectra_fetch",
                  type: "All",
                  from: userData.page * 20,
                  size: 20,
                })
                .then((response) => {
                  const talentData = [];
                  response.data.talentDetails.forEach((val) => {
                    talentData.push(changeTalentData(val));
                  });
                  if (talentData.length > 0) {
                    setDataTalent((pre) => {
                      if (userData.page > 0) {
                        return [...pre, ...talentData];
                      } else return talentData;
                    });
                  } else {
                    setDataTalent((pre) => {
                      if (userData.page > 0) {
                        return [...pre, ...talentData];
                      } else return talentData;
                    });
                  }
                  message.destroy("userList");
                })
                .catch((error) => {
                  console.log(error);
                });
            } catch (e) {
              console.log(e);
            }
          }
          fetchTalntData()
        }
      }
    }

    function fetchPrimary() {
      message.loading({ content: "Loading...", duration: 0, key: "userList" });
      if (
        typeof userData.filterValue !== "undefined" &&
        userData.filterValue.length > 0
      ) {
        const talentData = [];
        const primaryId = localStorage.getItem("primary_employer_id");
        let is_assign = "true";
        if (userData.activePrimaryTabIndex === "2") {
          is_assign = "false";
        }
        if (userData.sortColumn) {
          axios
            .post(`${baseUrl}/primaryTalentData`, {
              title: "userpanel_spectra_field_fetch",
              type: "field",
              primaryId,
              is_assign,
              field_name: field_name,
              field_value:
                userData.filterValue[0].dataIndex === "handicapped" &&
                userData.filterValue[0].sval === "All"
                  ? true
                  : userData.filterValue[0].sval,
              sortColumn: [userData.sortColumn],
            })
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          axios
            .post(`${baseUrl}/primaryTalentData`, {
              title: "userpanel_spectra_field_fetch",
              type: "field",
              primaryId,
              is_assign,
              field_name: field_name,
              field_value:
                userData.filterValue[0].dataIndex === "handicapped" &&
                userData.filterValue[0].sval === "All"
                  ? true
                  : userData.filterValue[0].sval,
            })
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else if (Object.keys(userData.filterQuery).length > 0) {
        const talentData = [];
        let is_assign = "true";
        if (userData.activePrimaryTabIndex === "2") {
          is_assign = "false";
        }
        userData.filterQuery.push({
          "status.is_assigned": is_assign,
        });
        if (userData.sortColumn) {
          axios
            .post(`${baseUrl}/FilterTalentDetails`, {
              title: "Talent filter",
              index_name: "talent_users",
              query: userData.filterQuery,
              sortColumn: [userData.sortColumn],
            })
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          axios
            .post(`${baseUrl}/FilterTalentDetails`, {
              title: "Talent filter",
              index_name: "talent_users",
              query: userData.filterQuery,
            })
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else if (userData.page >= 0) {
        const empId = localStorage.getItem("primary_employer_id");
        let is_assign = "true";
        if (userData.activePrimaryTabIndex === "2") {
          is_assign = "false";
        }
        if (userData.sortColumn) {
          axios
            .post(`${baseUrl}/primaryTalentData`, {
              title: "userpanel_spectra_fetch",
              type: "All",
              empId,
              is_assign,
              size: 20 + userData.page * 20,
              sortColumn: [userData.sortColumn],
            })
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          axios
            .post(`${baseUrl}/primaryTalentData`, {
              title: "userpanel_spectra_fetch",
              type: "All",
              empId,
              is_assign,
              from: userData.page * 20,
              size: 20,
            })
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent((pre) => {
                  if (userData.page > 0) {
                    return [...pre, ...talentData];
                  } else return talentData;
                });
              } else {
                setDataTalent((pre) => {
                  if (userData.page > 0) {
                    return [...pre, ...talentData];
                  } else return talentData;
                });
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }

    function fetchContractor() {
      message.loading({ content: "Loading...", duration: 0, key: "userList" });

      if (
        typeof userData.filterValue !== "undefined" &&
        userData.filterValue.length > 0
      ) {
        const conId = localStorage.getItem("contractor_id");
        const ContractorTabIndex = userData.activeContractTabIndex;
        const talentData = [];
        var userType = "";

        if (ContractorTabIndex === "1") {
          userType = "employed";
        } else if (ContractorTabIndex === "2") {
          userType = "available";
        } else if (ContractorTabIndex === "3") {
          userType = "incomplete";
        } else {
          userType = "yet_to_be_verified";
        }
        if (userData.sortColumn) {
          console.log("it is working");
          axios
            .post(`${baseUrl}/contractorTalentData`, {
              title: "userpanel_spectra_field_fetch",
              type: "field",
              conId,
              userType,
              field_name: field_name,
              field_value:
                userData.filterValue[0].dataIndex === "handicapped" &&
                userData.filterValue[0].sval === "All"
                  ? true
                  : userData.filterValue[0].sval,
              sortColumn: [userData.sortColumn],
            })
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          axios
            .post(`${baseUrl}/contractorTalentData`, {
              title: "userpanel_spectra_field_fetch",
              type: "field",
              conId,
              userType,
              field_name: field_name,
              field_value:
                userData.filterValue[0].dataIndex === "handicapped" &&
                userData.filterValue[0].sval === "All"
                  ? true
                  : userData.filterValue[0].sval,
            })
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else if (Object.keys(userData.filterQuery).length > 0) {
        const talentData = [];
        const ContractorTabIndex = userData.activeContractTabIndex;
        if (ContractorTabIndex === "1") {
          userData.filterQuery.push({
            "status.is_approved": true,
            "status.is_completed": true,
            "status.is_submitted": true,
            "status.is_employed": true,
          });
        } else if (ContractorTabIndex === "2") {
          userData.filterQuery.push({
            "status.is_approved": true,
            "status.is_completed": true,
            "status.is_submitted": true,
            "status.is_employed": false,
          });
        } else if (ContractorTabIndex === "3") {
          userData.filterQuery.push({
            "status.is_approved": false,
            "status.is_completed": false,
          });
        } else {
          userData.filterQuery.push({
            "status.is_approved": false,
            "status.is_submitted": true,
            "status.is_employed": false,
          });
        }

        if (userData.sortColumn) {
          axios
            .post(`${baseUrl}/FilterTalentDetails`, {
              title: "Talent filter",
              index_name: "talent_users",
              query: userData.filterQuery,
              sortColumn: [userData.sortColumn],
            })
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          axios
            .post(`${baseUrl}/FilterTalentDetails`, {
              title: "Talent filter",
              index_name: "talent_users",
              query: userData.filterQuery,
            })
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else if (userData.page >= 0) {
        const conId = localStorage.getItem("contractor_id");
        const ContractorTabIndex = userData.activeContractTabIndex;
        var conIdQuery = {
          "status.current_contractor_id": conId,
        };

        if (ContractorTabIndex === "1") {
          conIdQuery.push({
            "status.is_approved": true,
            "status.is_completed": true,
            "status.is_submitted": true,
            "status.is_employed": true,
          });
        } else if (ContractorTabIndex === "2") {
          conIdQuery.push({
            "status.is_approved": true,
            "status.is_completed": true,
            "status.is_submitted": true,
            "status.is_employed": false,
          });
        } else if (ContractorTabIndex === "3") {
          conIdQuery.push({
            "status.is_completed": false,
            "status.is_employed": false,
          });
        } else {
          conIdQuery.push({
            "status.is_approved": false,
            "status.is_submitted": true,
            "status.is_employed": false,
            "status.is_completed": true,
          });
        }

        if (userData.sortColumn) {
          axios
            .post(`${baseUrl}/contractorTalentData`, {
              title: "userpanel_spectra_fetch",
              type: "All",
              conIdQuery,
              size: 20 + userData.page * 20,
              sortColumn: [userData.sortColumn],
            })
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("userList");
            });
        } else {
          axios
            .post(`${baseUrl}/contractorTalentData`, {
              title: "userpanel_spectra_fetch",
              type: "All",
              conIdQuery,
              from: userData.page * 20,
              size: 20,
            })
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              // if(talentData.length > 0){
              setDataTalent((pre) => {
                if (userData.page > 0) {
                  return [...pre, ...talentData];
                } else return talentData;
              });
              // }else{
              // setDataTalent(pre =>{
              //   if (userData.page > 0) {
              //     return [...pre, ...talentData];
              //   }
              //   else return talentData;
              // })
              // }
              message.destroy("userList");
            });
        }
      }
    }

    if (userType === "spectra" && userData.activeTabIndex === "1") {
      fetchSpectra();
    } else if (userType === "primary") {
      fetchPrimary();
    } else if (userType === "contract") {
      fetchContractor();
    }
  }, [
    userData.reload,
    userData.sortColumn,
    userData.page,
    userData.filterValue,
    userData.filterQuery,
    userData.activeContractTabIndex,
    userData.activePrimaryTabIndex,
    userData.activeTabIndex,
    userData.isSort,
  ]);

  //column for talent

  useEffect(() => {
    async function fetch() {
      const i = userData?.activeTabIndex;
      const colecName =
        i === "2"
          ? "contractors"
          : i === "3"
          ? "primary_employers"
          : i === "4"
          ? "contractor_users"
          : i === "5"
          ? "primary_employer_users"
          : "spectrawise_bizops_users";
      let temp = [];
      let q = query(
        collection(db, colecName),
        orderBy(["2", "3"].includes(i) ? "name" : "personal.full_name")
      );
      const querySnapshot = await getDocs(q);
      let count = 0;
      if (["2"].includes(i))
        querySnapshot.forEach((doc) => {
          temp.push({
            key: doc.id,
            bname: doc.data().name,
            company: doc.data().company_type ? doc.data().company_type : "",
            address: doc.data().address ? doc.data().address : "",
            pan: doc.data().pan_number ? doc.data().pan_number : "",
            gstin: doc.data().gstin_number ? doc.data().gstin_number : "",
            epf: doc.data().epf_reg_number ? doc.data().epf_reg_number : "",
            esic: doc.data().esic_reg_number ? doc.data().esic_reg_number : "",
            pt: doc.data().pt_reg_number ? doc.data().pt_reg_number : "",
            tan: doc.data().tan_number ? doc.data().tan_number : "",
            linkedPrimaryEmployers: doc.data().linked_primary_employers
              ? doc.data().linked_primary_employers
              : [],
            logo: doc.data().logo,
          });
          if (querySnapshot.docs.length - 1 === count++) {
            setUserData((pre) => {
              return { ...pre, lastfetch: doc };
            });
          }
        });
      else
        querySnapshot.forEach((doc) => {
          temp.push({
            key: doc.id,
            name: doc.data().personal.full_name,
            mnum: doc.data().personal.mobile_number,
            bName: doc.data().personal.business_name,
            bId: doc.data().personal.contractor_id,
          });
          if (querySnapshot.docs.length - 1 === count++) {
            setUserData((pre) => {
              return { ...pre, lastfetch: doc };
            });
          }
        });
      setData((pre) => {
        if (userData.page > 0) {
          return [...pre, ...temp];
        } else return temp;
      });
      message.destroy("talent");
    }
    async function fetchDataFromAPI() {
      try {
        // Step 1: Fetch all companyType values
        const companyTypeResponse = await axios.get(
          "https://urchin-app-fi4og.ondigitalocean.app/api/web/companyType"
        );

        // Create a map from companyType API response
        const companyTypeMap = {};
        companyTypeResponse.data?.data.forEach((item) => {
          companyTypeMap[item.id] = item.companyType; // Assuming `id` and `companyType` are the keys
        });

        // Step 2: Fetch primaryEmployeers data
        const response = await axios.get(
          "https://urchin-app-fi4og.ondigitalocean.app/api/web/primaryEmployeers"
        );

        // Step 3: Map companyTypeId to companyType and build the data array
        const temp = response.data?.data.map((doc) => {
          const companyTypeId = doc.companyTypeId || ""; // Get the companyTypeId
          const companyType = companyTypeMap[companyTypeId] || "Unknown"; // Map ID to type

          return {
            key: doc.id,
            bname: doc.name || "",
            company: companyType, // Use the mapped companyType value
            address: doc.address || "",
            pan: doc.panNumber || "",
            gstin: doc.gstinNumber || "",
            epf: doc.epfRegNumber || "",
            esic: doc.esicRegNumber || "",
            pt: doc.ptRegNumber || "",
            tan: doc.tanNumber || "",
            linkedPrimaryEmployers: doc.linked_primary_employers || [],
            logo: doc.logo || "",
          };
        });

        // Step 4: Update state with the fetched data
        setData((pre) => (userData.page > 0 ? [...pre, ...temp] : temp));
        message.destroy("talent");
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    }

    async function fetchFromApi() {
      try {
        console.log("Fetching data from API...");
        
        // Use axios to fetch the data
        const response = await axios.get(
          "https://urchin-app-fi4og.ondigitalocean.app/api/web/bizopsUsers"
        );
        
        //console.log(response, "response22");
    
        // Axios responses have data directly under `response.data`
        const apiData = response.data.data;
        //console.log(apiData, "apiData");
    
        // Transform the API data to match the structure of the original code
        const transformedData = apiData.map((item) => ({
          key: item.id, // Assuming `id` exists in the API response
          name: item.fullName, // Adjust this based on the API response
          mnum: item.mobileNumber, // Adjust as needed
          bName: item.businessName, // Adjust as needed
          bId: item.contractorId, // Adjust as needed
        }));
    
        console.log(transformedData, "transformedData");
    
        // Set the transformed data to the existing state
        setData((pre) => {
          if (userData.page > 0) {
            return [...pre, ...transformedData];
          } else return transformedData;
        });
    
        // Clear any loading or error messages
        message.destroy("talent");
      } catch (error) {
        console.error("Error fetching data from the API:", error);
    
        // Display an error message to the user
        message.error("Failed to fetch data from API.");
      }
    }
    

    async function fetchFilteredOtherUsersList() {
      message.loading({
        content: "Loading",
        duration: 0,
        key: "otherUsersList",
      });
      const i = userData?.activeTabIndex;
      const colecName =
        i === "2"
          ? "contractors"
          : i === "3"
          ? "primary_employers"
          : i === "4"
          ? "contractor_users"
          : i === "5"
          ? "primary_employer_users"
          : "spectrawise_bizops_users";
      let sort;
      let filter_field_name = "";
      if (userData.filterValue[0].dataIndex === "all") {
        filter_field_name = "all";
      } else if (userData.filterValue[0].dataIndex === "name") {
        if (["2", "3"].includes(i)) {
          filter_field_name = "name";
        } else {
          filter_field_name = "personal.full_name";
        }
      } else if (userData.filterValue[0].dataIndex === "bName") {
        if (["2", "3"].includes(i)) {
          filter_field_name = "name";
        } else {
          filter_field_name = "personal.business_name";
        }
      } else if (userData.filterValue[0].dataIndex === "bname") {
        filter_field_name = "name";
      } else if (userData.filterValue[0].dataIndex === "mnum") {
        filter_field_name = "personal.mobile_number";
      } else if (userData.filterValue[0].dataIndex === "company") {
        filter_field_name = "company_type";
      } else if (userData.filterValue[0].dataIndex === "address") {
        filter_field_name = "address";
      } else if (userData.filterValue[0].dataIndex === "pan") {
        filter_field_name = "pan_number";
      } else if (userData.filterValue[0].dataIndex === "gstin") {
        filter_field_name = "gstin_number";
      } else if (userData.filterValue[0].dataIndex === "epf") {
        filter_field_name = "epf_reg_number";
      } else if (userData.filterValue[0].dataIndex === "esic") {
        filter_field_name = "esic_reg_number";
      } else if (userData.filterValue[0].dataIndex === "pt") {
        filter_field_name = "pt_reg_number";
      } else if (userData.filterValue[0].dataIndex === "tan") {
        filter_field_name = "tan_number";
      }

      console.log(
        "filter_field_name",
        filter_field_name,
        userData.filterValue[0].dataIndex
      );
      if (["2", "3"].includes(i)) {
        sort = [
          {
            "name.keyword": "asc",
          },
        ];
      } else {
        sort = [
          {
            "personal.full_name.keyword": "asc",
          },
        ];
      }
      const mappingData = (val) => {
        if (["2", "3"].includes(i)) {
          return {
            logo: val.usersList.logo ? val.usersList.logo : "",
            key: val.key,
            bname: val.usersList.name,
            company: val.usersList.company_type
              ? val.usersList.company_type
              : "",
            address: val.usersList.address ? val.usersList.address : "",
            pan: val.usersList.pan_number ? val.usersList.pan_number : "",
            gstin: val.usersList.gstin_number ? val.usersList.gstin_number : "",
            epf: val.usersList.epf_reg_number
              ? val.usersList.epf_reg_number
              : "",
            esic: val.usersList.esic_reg_number
              ? val.usersList.esic_reg_number
              : "",
            pt: val.usersList.pt_reg_number ? val.usersList.pt_reg_number : "",
            linkedPrimaryEmployers: val.usersList.linked_primary_employers
              ? val.UserList.linked_primary_employers
              : [],
            tan: val.usersList.tan_number ? val.usersList.tan_number : "",
          };
        } else if (["4", "5", "6"].includes(i)) {
          return {
            key: val.key,
            name: val.usersList.personal.full_name,
            mnum: val.usersList.personal.mobile_number,
            bName: val.usersList.personal.business_name,
            bId: val.usersList.personal.contractor_id,
          };
        }
      };
      axios
        .post(`${baseUrl}/otherUserList`, {
          title: "spectra_other_users_list",
          index: colecName,
          sortColumn: sort,
          field_name: filter_field_name,
          field_value: userData.filterValue[0].sval,
        })
        .then((response) => {
          const usersDocList = [];
          response.data.usersList.forEach((val) => {
            usersDocList.push(mappingData(val));
          });
          if (usersDocList.length > 0) {
            setData(usersDocList);
          } else {
            setData(usersDocList);
          }
          console.log(usersDocList);
          message.destroy("otherUsersList");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function fetchFilteredEmployeerUsersList() {
      message.loading({
        content: "Loading",
        duration: 0,
        key: "otherUsersList",
      });
    
      const i = userData?.activeTabIndex;
    
      let filter_field_name = "";
    
      const filterValue = userData.filterValue[0];
      const dataIndex = filterValue.dataIndex;
    
      if (dataIndex === "all") {
        filter_field_name = "all";
      } else if (dataIndex === "name") {
        filter_field_name = ["3"].includes(i) ? "name" : "personal.full_name";
      } else if (dataIndex === "bName") {
        filter_field_name = ["3"].includes(i) ? "name" : "personal.business_name";
      } else if (dataIndex === "bname") {
        filter_field_name = "name";
      } else if (dataIndex === "mnum") {
        filter_field_name = "mobileNumber";
      } else if (dataIndex === "company") {
        filter_field_name = "company_type";
      } else if (dataIndex === "address") {
        filter_field_name = "address";
      } else if (dataIndex === "pan") {
        filter_field_name = "panNumber";
      } else if (dataIndex === "gstin") {
        filter_field_name = "gstinNumber";
      } else if (dataIndex === "epf") {
        filter_field_name = "epfRegNumber";
      } else if (dataIndex === "esic") {
        filter_field_name = "esicRegNumber";
      } else if (dataIndex === "pt") {
        filter_field_name = "ptRegNumber";
      } else if (dataIndex === "tan") {
        filter_field_name = "tanNumber";
      }
    
      // console.log(
      //   "filter_field_name",
      //   filter_field_name,
      //   filterValue.dataIndex,
      //   filterValue.sval
      // );
    
      const sort = ["3"].includes(i)
        ? [{ "name.keyword": "asc" }]
        : [{ "personal.full_name.keyword": "asc" }];
    
      const mappingData = (val, companyTypeMapping) => {
        const companyType = companyTypeMapping[val.companyTypeId] || val.companyType || "";
        //console.log("companyType", companyType)
        if (["3"].includes(i)) {
          return {
            logo: val.logo || "",
            key: val.id,
            bname: val.name,
            company: companyType || "",
            address: val.address || "",
            pan: val.panNumber || "",
            gstin: val.gstinNumber || "",
            epf: val.epfRegNumber || "",
            esic: val.esicRegNumber || "",
            pt: val.ptRegNumber || "",
            linkedPrimaryEmployers: val.linked_primary_employers || [],
            tan: val.tanNumber || "",
          };
        } else if (["4", "5", "6"].includes(i)) {
          return {
            key: val.key,
            name: val.usersList.personal.full_name,
            mnum: val.usersList.personal.mobile_number,
            bName: val.usersList.personal.business_name,
            bId: val.usersList.personal.contractor_id,
          };
        }
      };
    
      const apiUrl =
        filter_field_name === "all"
          ? `https://urchin-app-fi4og.ondigitalocean.app/api/web/primaryEmployeers?search=${filterValue.sval}`
          : `https://urchin-app-fi4og.ondigitalocean.app/api/web/primaryEmployeers?column=${filter_field_name}&search=${filterValue.sval}`;
    
     // console.log(apiUrl);
    
      try {
        const response = await axios.get(apiUrl);
        //console.log(response, "response");
        const usersList = response.data.data;

           // Fetch company type data
           //console.log(usersList, "usersList")
           const companyTypeIds = [...new Set(usersList.map((user) =>  user.companyTypeId))];
           const companyTypeMapping = {};
      // console.log(companyTypeIds, "companyTypeIds")
           for (const id of companyTypeIds) {
             if (id) {
               const companyTypeResponse = await axios.get(
                 `https://urchin-app-fi4og.ondigitalocean.app/api/web/companyType?id=${id}`
               );
              // console.log(companyTypeResponse, "companyTypeResponse")
               companyTypeMapping[id] = companyTypeResponse.data.data.companyType;
             }
           }
     //  console.log(companyTypeMapping,"companyTypeMapping")
           const usersDocList = usersList.map((user) => mappingData(user, companyTypeMapping));
    
        //const usersDocList = response.data.data.map(mappingData);  //Hide Before change companyType
        console.log(usersDocList, "usersDocList");
    
        setData(usersDocList);
        message.destroy("otherUsersList");
      } catch (error) {
        console.error(error);
      }
    }

    async function fetchFilteredBizOpsUsersList() {
      message.loading({
        content: "Loading",
        duration: 0,
        key: "bizOpsUsersList",
      });
    
      const i = userData?.activeTabIndex;
      let filter_field_name = "";
    
      const filterValue = userData.filterValue[0];
      const dataIndex = filterValue.dataIndex;
    
      // Determine the filter field name based on the data index and active tab
      if (dataIndex === "all") {
        filter_field_name = "all";
      } else if (dataIndex === "name") {
        filter_field_name = ["3"].includes(i) ? "bizOpsName" : "personal.fullName";
      } else if (dataIndex === "bName") {
        filter_field_name = ["3"].includes(i) ? "bizOpsBusinessName" : "personal.businessName";
      } else if (dataIndex === "mnum") {
        filter_field_name = "mobileNumber";
      } else if (dataIndex === "company") {
        filter_field_name = "bizOpsCompanyType";
      }
    
      // Sorting logic
      const sort = ["3"].includes(i)
        ? [{ "bizOpsName.keyword": "asc" }]
        : [{ "personal.full_name.keyword": "asc" }];
    
      // Data mapping for BizOps users
      const mappingData = (val, companyTypeMapping) => {
        const companyType = companyTypeMapping[val.companyTypeId] || val.companyType || "";
        if (["3"].includes(i)) {
          return {
            logo: val.logo || "",
            key: val.id,
            fullName: val.fullName,
            mobileNumber: val.mobileNumber,
            bname: val.businessName,
            //company: companyType || "",
          };
        } else if (["4", "5", "6"].includes(i)) {
          return {
            key: val.key,
            name: val.fullName,
            mnum: val.mobileNumber,
            bName: val.businessName,
            //bId: val.usersList.personal.contractor_id,
          };
        }
      };
    
      // Construct the API URL based on filter_field_name and filter values
      const apiUrl =
        filter_field_name === "all"
          ? `https://urchin-app-fi4og.ondigitalocean.app/api/web/bizopsUsers?search=${filterValue.sval}`
          : `https://urchin-app-fi4og.ondigitalocean.app/api/web/bizopsUsers?column=${filter_field_name}&search=${filterValue.sval.replace(/^\+91/, '')}`;
    
      try {
        const response = await axios.get(apiUrl);
       
        const usersDocList = response.data.data.map((user) =>
          mappingData(user, response.data.companyTypeMapping || {})
        );
    
        // Update the state with the fetched and mapped data
        setData(usersDocList);
        message.destroy("bizOpsUsersList");
      } catch (error) {
        console.error(error);
        message.error("Failed to load BizOps users list", "bizOpsUsersList");
      }
    }
    

    
    if (userType === "spectra" && userData.activeTabIndex !== "1") {
      if (userData.activeTabIndex === "3") {
        // Check if filterValue is defined and not empty
        if (typeof userData.filterValue !== "undefined" && userData.filterValue.length > 0) {
            fetchFilteredEmployeerUsersList(); // Call the new function for tab index 3
        } else {
            fetchDataFromAPI(); // Call the existing fetchDataFromAPI for tab index 3
        }
    } 
   else if (userData.activeTabIndex === "6") {
      // Check if filterValue is defined and not empty
      if (typeof userData.filterValue !== "undefined" && userData.filterValue.length > 0) {
        //  fetchFilteredEmployeerUsersList(); // Call the new function for tab index 3
        fetchFilteredBizOpsUsersList();
      } else {
        fetchFromApi(); // Call the existing fetchDataFromAPI for tab index 3
      }
  } else {
      if (
        typeof userData.filterValue !== "undefined" &&
        userData.filterValue.length > 0
      ) {
        fetchFilteredOtherUsersList();
      } else {
        fetch();
      }
    }
    }
  }, [
    userData.filterValue,
    userData.activeTabIndex,
    userData.reload,
    userData.page,
    userData.value,
  ]);

  const [columnTalent, setColumnTalent] = useState([
    {
      title: "Status",
      width: 10,
      sort: false,
      filter: "dropDown",
      dropDownList: [
        "Completed Profile",
        "Incomplete Profile",
        "Yet To Be Verified",
        "Employed",
        "Available",
      ],
      filterValue: "",
      dataIndex: "status",
      position: 0,
      visibility: true,
    },
    {
      title: "Name",
      filterValue: "",
      dataIndex: "name",
      width: 100,
      filter: "text",
      sort: true,
      position: 1,
      visibility: true,
    },
    {
      title: "Address",
      filterValue: "",
      dataIndex: "address",
      filter: "text",
      width: 100,
      sort: false,
      position: 3,
      visibility: true,
    },
    {
      title: "Mobile Number",
      filterValue: "",
      dataIndex: "mnum",
      filter: "text",
      width: 100,
      sort: false,
      position: 4,
      visibility: true,
    },
    {
      title: "Gender",
      filterValue: "",
      dataIndex: "gender",
      filter: "dropDown",
      dropDownList: ["Male", "Female", "Others"],
      width: 100,
      sort: false,
      position: 5,
      visibility: true,
    },
    {
      title: "Blood Group",
      width: 100,
      filter: "dropDown",
      dropDownList: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
      sort: false,
      filterValue: "",
      dataIndex: "bGroup",
      position: 6,
      visibility: false,
    },
    {
      title: "Email",
      filter: "text",
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "email",
      position: 7,
      visibility: false,
    },
    {
      title: "Aadhaar Number",
      filter: "text",
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "aadhaar",
      position: 8,
      visibility: true,
    },
    {
      title: "PAN Number",
      filter: "text",
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "pan",
      position: 9,
      visibility: true,
    },
    {
      title: "BANK A/C NUM",
      filter: "text",
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "bankAC",
      position: 10,
      visibility: true,
    },
    {
      title: "Physically Challenged",
      filter: "dropDown",
      dropDownList: [
        "No, Not Physically Challenged",
        "Yes, Visual",
        "Yes, Hearing",
        "Yes, Locomotive",
      ],
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "handicapped",
      position: 11,
      visibility: false,
    },
    {
      title: "Contractor name",
      filter: "text",
      width: 100,
      sort: true,
      filterValue: "",
      dataIndex: "contractor",
      position: 12,
      visibility: true,
    },
    {
      title: "Employer name",
      filter: "text",
      width: 100,
      sort: true,
      filterValue: "",
      dataIndex: "employer",
      position: 13,
      visibility: true,
    },
    {
      title: "Pincode",
      filter: "text",
      width: 100,
      sort: true,
      filterValue: "",
      dataIndex: "pincode",
      position: 13,
      visibility: true,
    },
  ]);

  const contractorColumns = [
    ...(userData.activeTabIndex == "3"
      ? [
          {
            title: "Logo",
            dataIndex: "logo",
            sort: false,
            width: 100,
          },
        ]
      : []),
    {
      title: "Business Name",
      dataIndex: "bname",
      sort: true,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    },
    {
      title: "Company Type",
      dataIndex: "company",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    },
    {
      title: "Address",
      dataIndex: "address",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    },
    {
      title: "PAN Number",
      dataIndex: "pan",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    },
    {
      title: "GSTIN Number",
      dataIndex: "gstin",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    },
    {
      title: "EPF Reg. Number",
      dataIndex: "epf",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    },
    {
      title: "ESIC Reg. Number",
      dataIndex: "esic",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    },
    {
      title: "PT Reg. Number",
      dataIndex: "pt",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    },
    {
      title: "TAN Number",
      dataIndex: "tan",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    },
  ];

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      const colName = auth.isSpectra
        ? "spectrawise_bizops_users"
        : auth.isContract
        ? "contractor_users"
        : auth.isPrimary
        ? "primary_employer_users"
        : "";
      let list = columnTalent
        .filter((itx) => itx.visibility)
        .map((itx) => itx.dataIndex);
      let undefinedList = list.filter(function (element) {
        return element !== undefined;
      });
      if (list.length > 0) {
        console.log(db, colName, localStorage.getItem("uid"), list);
        updateDoc(doc(db, colName, localStorage.getItem("uid")), {
          "settings.talent_user_column_list": undefinedList,
        });
      }
    } else {
      isMounted.current = true;
    }
  }, [columnTalent]);

  return (
    <>
      <div className="search">
        <UserPanelSearch
          talents={talents}
          contractor={contractor}
          userType={userType}
          columnTalent={columnTalent}
          columns={columns}
          contractorColumns={contractorColumns}
          selectedColumn={selectedColumn}
        />
        <div className="searchRight">
          <TabHead userType={userType} />
          {userData.activeTabIndex === "1" && (
            <MoreOption
              columnTalent={columnTalent}
              setColumnTalent={setColumnTalent}
              selectedColumn={selectedColumn}
              setSelectedColumn={setSelectedColumn}
            />
          )}
        </div>
      </div>
      {talents ? (
        <ResponsiveTable
          talents={talents}
          data={dataTalent}
          column={columnTalent}
          setColumn={setColumnTalent}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          userType={userType}
        />
      ) : contractor ? (
        <ResponsiveTable
          setColumnTalent={setColumnTalent}
          disableColumnOrder
          data={data}
          column={contractorColumns}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          userType={userType}
        />
      ) : (
        <ResponsiveTable
          disableColumnOrder
          setColumnTalent={setColumnTalent}
          data={data}
          column={columns}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          userType={userType}
        />
      )}
    </>
  );
}

export default UserList;
