import React, { useContext, useEffect } from "react";
import "../../../styles/Panel.scss";
import { Tabs } from "antd";
import UserList from "./UserList";
import { dataContext } from "../../../context/data";
import ContractorList from "../../contractor/ContractorList";
// import uploadImage from '../../../assets/icons/uploadIcon.svg'
// import downloadImage from '../../../assets/icons/downloadIcon.svg'
// import AssignTalent from './AssignTalent';
// import searchIcon from '../../../assets/icons/search.svg';

function PanelHead({ userType }) {
  const { userData, setUserData } = useContext(dataContext);

  const { TabPane } = Tabs;
  const tabStyle = {
    fontSize: "1.3vw !important",
    fontWeight: "600",
  };

  const contractTabs = [
    "Employed",
    "Available",
    "Incomplete profile",
    "Yet to be verified",
  ];

  return (
    <>
      {userType === "spectra" && (
        <div className="tabs">
          <Tabs
            destroyInactiveTabPane
            defaultActiveKey="1"
            activeKey={userData.activeTabIndex}
            onChange={(tabidx) =>
              setUserData((pre) => {
                return {
                  ...pre,
                  activeTabIndex: tabidx,
                  filterValue: [],
                  filterQuery: {},
                  page: 0,
                };
              })
            }
            className="tabSlider"
            size="large"
          >
            <TabPane
              tabKey="1"
              className="tabSlider"
              style={tabStyle}
              tab="Talents"
              key="1"
            >
              <UserList userType={userType} talents={true} />
            </TabPane>
            <TabPane
              tabKey="2"
              className="tabSlider"
              style={tabStyle}
              tab="Contractors"
              key="2"
            >
              {/* <UserList userType={userType} contractor={true} /> */}
              <ContractorList />
            </TabPane>
            <TabPane
              tabKey="3"
              className="tabSlider"
              style={tabStyle}
              tab="Primary Employers"
              key="3"
            >
              <UserList userType={userType} contractor={true} />
            </TabPane>
            {/* <TabPane
              tabKey="4"
              className="tabSlider"
              style={tabStyle}
              tab="Contractor Users"
              key="4"
            >
              <UserList userType={userType} />
            </TabPane> */}
            {/* <TabPane
              tabKey="5"
              className="tabSlider"
              style={tabStyle}
              tab="Primary Employer Users"
              key="5"
            >
              <UserList userType={userType} />
            </TabPane> */}
            <TabPane
              tabKey="6"
              className="tabSlider"
              style={tabStyle}
              tab=" Talntworx Bizops"
              key="6"
            >
              <UserList userType={userType} />
            </TabPane>
          </Tabs>
        </div>
      )}
      {userType === "contract" && (
        <div className="tabs">
          <div className="tabSlider tab-container-vmm">
            {contractTabs.map((name, i) => {
              return (
                <h1
                  className={
                    userData.activeContractTabIndex === `${i + 1}`
                      ? "tabs active"
                      : "tabs"
                  }
                  key={`${i + 1}`}
                  onClick={() =>
                    setUserData((pre) => {
                      return {
                        ...pre,
                        activeContractTabIndex: `${i + 1}`,
                        selectedRows: [],
                        filterValue: [],
                        filterQuery: {},
                        page: 0,
                      };
                    })
                  }
                >
                  {name}
                </h1>
              );
            })}
          </div>
          <UserList talents={true} userType="contract" />
        </div>
      )}
      {userType === "primary" && (
        <div className="tabs ">
          <Tabs
            defaultActiveKey="1"
            activeKey={userData.activePrimaryTabIndex}
            onChange={(tabidx) =>
              setUserData((pre) => {
                return {
                  ...pre,
                  activePrimaryTabIndex: tabidx,
                  filterValue: [],
                  filterQuery: {},
                  page: 0,
                };
              })
            }
            className="tabSlider"
            size="large"
          >
            <TabPane
              className="tabSlider"
              style={tabStyle}
              tab="Talents"
              key="1"
            >
              <UserList talents={true} userType={userType} />
            </TabPane>
            {/*<TabPane className='tabSlider' style={tabStyle} tab="Unassigned" key="2">
            <UserList talents={true} userType={userType} unassigned={true} />
        </TabPane>*/}
          </Tabs>
        </div>
      )}
    </>
  );
}

export default PanelHead;
