import React, { useContext, useEffect, useState, useCallback, useRef, useMemo } from "react";
import searchIcon from "../../../assets/icons/search.svg";
import "../../../styles/Panel.scss";
//import ResponsiveTable from "./ResponsiveTable";
import ResponsiveTable from "../panelHead/ResponsiveTable";
import TabHead from "../panelHead/TabHead";
import filterIcon from "../../../assets/icons/filter.svg";
import { dataContext } from "../../../context/data";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  orderBy,
  Timestamp,
  updateDoc,
  limit,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import app from "../../../firebase";
import { useAuthContext } from "../../../context/auth";
import moment from "moment";
import { message, Select, Input, DatePicker, Modal, Form, Button } from "antd";
import { async } from "@firebase/util";
import UserPanelSearch from "../panelHead/userPanelSearch";
//import MoreOption from "../MoreOption";
import MoreOption from "../panelHead/MoreOption";
// import axios from "axios";
import axios from "../../../axiosConfig/index";
import baseUrl from "../../../utils/baseUrl";
import { useLocation } from "react-router-dom";
import HeadUsersTabs from "./HeadUsersTabs";


const db = getFirestore(app);

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    sort: true,
    filter: "text",
    filterValue: "",
    sorter: (a, b) => a.name - b.name,
    width: 50,
  },
  {
    title: "Mobile Number",
    filter: "text",
    filterValue: "",
    dataIndex: "mnum",
    sort: false,
    width: 50,
  },
  {
    title: "Business name",
    filter: "text",
    filterValue: "",
    dataIndex: "bName",
    sort: true,
    sorter: (a, b) => a.bName - b.bName,
    width: 50,
  },
];


function UserTabs({ talents, contractor, userType }) {
  const { id } = useLocation().state;
 // console.log(id, "id")
  const { userData, setUserData } = useContext(dataContext);
  const { searchState, setSearchState } = useContext(dataContext);
  const [data, setData] = useState([]);
  const [dataTalent, setDataTalent] = useState([]);
  const [primaryEmployeerNameView, setPrimaryEmployeerNameView] = useState('');
 // console.log(primaryEmployeerNameView, "primaryEmployeerNameView")
  const [selectedColumn, setSelectedColumn] = useState([
    0, 1, 2, 3, 5, 6, 9, 10, 4,
  ]);
  const auth = useAuthContext();
  const userContext = useContext(dataContext);

  //console.log("userData", userData);

  useEffect(() => {
    async function fetch() {
    //console.log("fetching data:",data);
    const colName = auth.isSpectra ? "spectrawise_bizops_users" : auth.isContract ? "contractor_users" : auth.isPrimary ? "primary_employer_users" : "";
    const colPosData = await getDoc(doc(db, colName, localStorage.getItem('uid')));
    // console.log(colPosData.data().settings.talent_user_column_list);;
    let selCoList = [];
    setColumnTalent(pre => {
     const d1 = [], d = colPosData.data()?.settings?.talent_user_column_list.map(didx => didx === 'full_name' ? "name" : didx);
     d?.map(didx => {
       if(didx !== "dob"){
        d1.push(pre.filter(itm => itm.dataIndex === didx)[0])
       }
     })
     selCoList = [...d1.map(itm => { return { ...itm, visibility: true } }), ...pre.filter(itm => !d.includes(itm.dataIndex)).map(itm => { return { ...itm, visibility: false } })];
    // console.log("selcollist",selCoList);
     return selCoList;
    }
    );
    setSelectedColumn(pre => {
     let selColuList = [];
     selCoList.forEach((itm, idx) => {
    if (itm.visibility)
     selColuList .push(idx);
     })
     return selColuList;
    })
     }
     if ((auth.isSpectra && userContext.userData.activeTabIndex === '1') || auth.isContract || auth.isPrimary)
    fetch();
    }, []);
  const statusFinderTalent = (s) => {
    if (
      s?.status.is_approved &&
      s?.status.is_completed &&
      s?.status.is_submitted &&
      s?.status.is_employed
    )
      s = "Employed";
    else if (
      s?.status.is_approved &&
      s?.status.is_completed &&
      s?.status.is_submitted
    )
      s = "Available"; //need to clarify
    else if (
      !s?.status.is_approved &&
      s?.status.is_completed &&
      !s?.status.is_submitted
    )
      s = "Completed Profile";
    else if (!s?.status.is_approved && s?.status.is_submitted)
      s = "Yet To Be Verified";
    //if (!s?.is_approved && !s?.is_completed && s?.is_submitted)
    else s = "Incomplete Profile";
    return s;
  };
  const changeTalentData = (d) => {
    let s = statusFinderTalent(d.talentData);
    let bankAC = d.talentData.bank?.account_number 
    let aadhaar = d.talentData.identity?.aadhaar.aadhaar_number
    const Masknumber=(num)=>
    {
      return num.replace(/.(?=.{4,}$)/g, 'X')
     }

    return {
      key: d.key,
      data: d,
      contractor: d.talentData.status?.current_contractor_name
        ? d.talentData.status?.current_contractor_name
        : "",
      employer: d.talentData.status?.current_primary_employer_name
        ? d.talentData.status?.current_primary_employer_name
        : "",
      employer_id: d.talentData.status?.current_primary_employer_id ? d.talentData.status?.current_primary_employer_id : '',
      status: s,
      name: d.talentData.personal?.full_name,
      dob: d.talentData.personal?.dob 
        ? moment(parseInt(d.talentData.personal?.dob)).format("DD-MM-YYYY")
        : "",
      address: `${d.talentData.contact?.address_line_1},
      ${d.talentData.contact?.address_line_2},
      ${d.talentData.contact?.city},
      ${d.talentData.contact?.state},
      ${d.talentData.contact?.country}-${d.talentData.contact?.pincode}`,
      mnum: d.talentData.contact?.mobile_number,
      gender: d.talentData.personal?.gender,
      bGroup: d.talentData.personal?.blood_group,
      email: d.talentData.contact?.email,
      aadhaar:Masknumber(aadhaar),
      pan: d.talentData.identity?.pan?.pan_number,
      pincode: d.talentData.contact.pincode,
      bankAC: Masknumber(bankAC),
      handicapped: d.talentData.personal?.is_handicapped
        ? d.talentData.personal.handicapped_reason
        : "NO", 
    };
  };
  //fetch Contracter and employer details
 
  useEffect(() => {
    setUserData((pre) => {
      return {...pre, sortColumn: undefined}
    })
  },[])


  useEffect(() => {
    // dashboardData.filterType
    if (
      (typeof userData.filterValue !== "undefined" &&
      userData.filterValue.length > 0)
    ) {
      var field_name = "";
      if (userData.filterValue[0].dataIndex === "all") {
        field_name = "all";
      } else if (userData.filterValue[0].dataIndex === "name") {
        field_name = "personal.full_name";
       
      } else if (userData.filterValue[0].dataIndex === "status") {
        field_name = "status";
      } else if (userData.filterValue[0].dataIndex === "dob") {
        field_name = "personal.dob";
      } else if (userData.filterValue[0].dataIndex === "mnum") {
        field_name = "contact.mobile_number";
      } else if (userData.filterValue[0].dataIndex === "address") {
        field_name = "address";
      } else if (userData.filterValue[0].dataIndex === "gender") {
        field_name = "personal.gender";
      } else if (userData.filterValue[0].dataIndex === "bGroup") {
        field_name = "personal.blood_group";
      } else if (userData.filterValue[0].dataIndex === "email") {
        field_name = "email";
      } else if (userData.filterValue[0].dataIndex === "aadhaar") {
        field_name = "identity.aadhaar.aadhaar_number";
      } else if (userData.filterValue[0].dataIndex === "pan") {
        field_name = "identity.pan.pan_number";
      } else if (userData.filterValue[0].dataIndex === "bankAC") {
        field_name = "bank.account_number";
      } else if (userData.filterValue[0].dataIndex === "handicapped") {
        if (userData.filterValue[0].sval === "All") {
          field_name = "personal.is_handicapped";
        } else {
          field_name = "personal.handicapped_reason";
        }
      } else if (userData.filterValue[0].dataIndex === "contractor") {
        field_name = "status.current_contractor_name";
      } else if (userData.filterValue[0].dataIndex === "employer") {
        field_name = "status.current_primary_employer_name";
      } else if (userData.filterValue[0].dataIndex === "pincode") {
        field_name = "contact.pincode";
      }
    }
    function fetchSpectra() {
    message.loading({content: 'Loading...', duration: 0, key: 'userList'});
      const talentData = [];
      if (
        typeof userData.filterValue !== "undefined" &&
        userData.filterValue.length > 0
      ) {
        if(userData.sortColumn){
          axios
          .post(
            `${baseUrl}/spectraTalentData`,
            {
              type: "field",
              field_name: field_name,
              field_value:
                userData.filterValue[0].dataIndex === "handicapped" &&
                userData.filterValue[0].sval === "All"
                  ? true
                  : userData.filterValue[0].sval,
              sortColumn : [userData.sortColumn],
              title: "userpanel_spectra_field_fetch",
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            }else{
              setDataTalent([])
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/spectraTalentData`,
            {
              title: "userpanel_spectra_field_fetch",
              type: "field",
              field_name: field_name,
              field_value:
                userData.filterValue[0].dataIndex === "handicapped" &&
                userData.filterValue[0].sval === "All"
                  ? true
                  : userData.filterValue[0].sval,
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }
      } else if (Object.keys(userData.filterQuery).length > 0) {
        if(userData.sortColumn){
          axios
          .post(
            `${baseUrl}/FilterTalentDetails`,
            {
              title: "Talent filter",
              query: userData.filterQuery,
              sortColumn : [userData.sortColumn],
              index_name: "talent_users",
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            }else{
              setDataTalent([])
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }else{
          const talentData = [];
          axios
            .post(
              `${baseUrl}/FilterTalentDetails`,
              {
                title: "Talent filter",
                index_name: "talent_users",
                query: userData.filterQuery,
              }
            )
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy('userList');
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else if (userData.page >= 0) {
        if(userData.sortColumn){
          axios
          .post(
            `${baseUrl}/spectraTalentData`,
            {
              title: "userpanel_spectra_fetch",
              type: "All",
              size: 20 + userData.page * 20,
              sortColumn : [userData.sortColumn]
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            }else{
              setDataTalent([])
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }else{
        axios
          .post(
            `${baseUrl}/spectraTalentData`,
            {
              title: "userpanel_spectra_fetch",
              type: "All",
              from: userData.page * 20,
              size: 20,
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent((pre) => {
                if (userData.page > 0) {
                  return [...pre, ...talentData];
                } else return talentData;
              });
            }else{
              setDataTalent((pre) => {
                if (userData.page > 0) {
                  return [...pre, ...talentData];
                } else return talentData;
              });
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }
      }
    }

    function fetchPrimary() {
    message.loading({content: 'Loading...', duration: 0, key: 'userList'});
      if (
        typeof userData.filterValue !== "undefined" &&
        userData.filterValue.length > 0
      ) {
        const talentData = [];
        const primaryId = localStorage.getItem("primary_employer_id");
        let is_assign = "true";
        if (userData.activePrimaryTabIndex === "2") {
          is_assign = "false";
        }
        if(userData.sortColumn){
          axios
          .post(
            `${baseUrl}/primaryTalentData`,
            {
              title: "userpanel_spectra_field_fetch",
              type: "field",
              primaryId,
              is_assign,
              field_name: field_name,
              field_value:
                userData.filterValue[0].dataIndex === "handicapped" &&
                userData.filterValue[0].sval === "All"
                  ? true
                  : userData.filterValue[0].sval,
              sortColumn : [userData.sortColumn],
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/primaryTalentData`,
            {
              title: "userpanel_spectra_field_fetch",
              type: "field",
              primaryId,
              is_assign,
              field_name: field_name,
              field_value:
                userData.filterValue[0].dataIndex === "handicapped" &&
                userData.filterValue[0].sval === "All"
                  ? true
                  : userData.filterValue[0].sval,
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }
      } else if (Object.keys(userData.filterQuery).length > 0) {
        const talentData = [];
        let is_assign = "true";
        if (userData.activePrimaryTabIndex === "2") {
          is_assign = "false";
        }
        userData.filterQuery.bool.must.push({
          match: {
            "status.is_assigned": is_assign,
          },
        });
        if(userData.sortColumn){
          axios
          .post(
            `${baseUrl}/FilterTalentDetails`,
            {
              title: "Talent filter",
              index_name: "talent_users",
              query: userData.filterQuery,
              sortColumn : [userData.sortColumn],
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/FilterTalentDetails`,
            {
              title: "Talent filter",
              index_name: "talent_users",
              query: userData.filterQuery,
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }
      } else if (userData.page >= 0) {
        const empId = localStorage.getItem("primary_employer_id");
        let is_assign = "true";
        if (userData.activePrimaryTabIndex === "2") {
          is_assign = "false";
        }
        if(userData.sortColumn){
          axios
          .post(
            `${baseUrl}/primaryTalentData`,
            {
              title: "userpanel_spectra_fetch",
              type: "All",
              empId,
              is_assign,
              size: 20 + userData.page*20,
              sortColumn : [userData.sortColumn],
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            }else{
              setDataTalent([])
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/primaryTalentData`,
            {
              title: "userpanel_spectra_fetch",
              type: "All",
              empId,
              is_assign,
              from: userData.page * 20,
              size: 20,
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent((pre) => {
                if (userData.page > 0) {
                  return [...pre, ...talentData];
                } else return talentData;
              });
            }else{
              setDataTalent((pre) => {
                if (userData.page > 0) {
                  return [...pre, ...talentData];
                } else return talentData;
              });
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }
      }
    }

    function fetchContractor() {
      message.loading({content: 'Loading...', duration: 0, key: 'userList'});

      if (
        typeof userData.filterValue !== "undefined" &&
        userData.filterValue.length > 0
      ) {
        const conId = localStorage.getItem("contractor_id");
        const ContractorTabIndex = userData.activeContractTabIndex;
        const talentData = [];
        var userType = "";

        if (ContractorTabIndex === "1") {
          userType = "employed";
        } else if (ContractorTabIndex === "2") {
          userType = "available";
        } else if (ContractorTabIndex === "3") {
          userType = "incomplete";
        } else {
          userType = "yet_to_be_verified";
        }
        if(userData.sortColumn){
          console.log('it is working')
          axios
          .post(
            `${baseUrl}/contractorTalentData`,
            {
              title: "userpanel_spectra_field_fetch",
              type: "field",
              conId,
              userType,
              field_name: field_name,
              field_value:
                userData.filterValue[0].dataIndex === "handicapped" &&
                userData.filterValue[0].sval === "All"
                  ? true
                  : userData.filterValue[0].sval,
              sortColumn : [userData.sortColumn],
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/contractorTalentData`,
            {
              title: "userpanel_spectra_field_fetch",
              type: "field",
              conId,
              userType,
              field_name: field_name,
              field_value:
                userData.filterValue[0].dataIndex === "handicapped" &&
                userData.filterValue[0].sval === "All"
                  ? true
                  : userData.filterValue[0].sval,
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }
      } else if (Object.keys(userData.filterQuery).length > 0) {
        const talentData = [];
        const ContractorTabIndex = userData.activeContractTabIndex;
        if (ContractorTabIndex === "1") {
          userData.filterQuery.bool.must.push(
            {
              match: {
                "status.is_approved": true,
              },
            },
            {
              match: {
                "status.is_completed": true,
              },
            },
            {
              match: {
                "status.is_submitted": true,
              },
            },
            {
              match: {
                "status.is_employed": true,
              },
            }
          );
        } else if (ContractorTabIndex === "2") {
          userData.filterQuery.bool.must.push(
            {
              match: {
                "status.is_approved": true,
              },
            },
            {
              match: {
                "status.is_completed": true,
              },
            },
            {
              match: {
                "status.is_submitted": true,
              },
            },
            {
              match: {
                "status.is_employed": false,
              },
            }
          );
        } else if (ContractorTabIndex === "3") {
          userData.filterQuery.bool.must.push(
            {
              match: {
                "status.is_approved": false,
              },
            },
            {
              match: {
                "status.is_completed": false,
              },
            }
          );
        } else {
          userData.filterQuery.bool.must.push(
            {
              match: {
                "status.is_approved": false,
              },
            },
            {
              match: {
                "status.is_submitted": true,
              },
            },
            {
              match: {
                "status.is_employed": false,
              },
            }
          );
        }

        if(userData.sortColumn){
          axios
          .post(
            `${baseUrl}/FilterTalentDetails`,
            {
              title: "Talent filter",
              index_name: "talent_users",
              query: userData.filterQuery,
              sortColumn: [userData.sortColumn]
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/FilterTalentDetails`,
            {
              title: "Talent filter",
              index_name: "talent_users",
              query: userData.filterQuery,
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy('userList');
          })
          .catch((error) => {
            console.log(error);
          });
        }
      } else if (userData.page >= 0) {
        const conId = localStorage.getItem("contractor_id");
        const ContractorTabIndex = userData.activeContractTabIndex;
        var conIdQuery = {
          bool: {
            must: [
              {
                match: {
                  "status.current_contractor_id": conId,
                },
              },
            ],
          },
        };

        if (ContractorTabIndex === "1") {
          conIdQuery.bool.must.push(
            {
              match: {
                "status.is_approved": true,
              },
            },
            {
              match: {
                "status.is_completed": true,
              },
            },
            {
              match: {
                "status.is_submitted": true,
              },
            },
            {
              match: {
                "status.is_employed": true,
              },
            }
          );
        } else if (ContractorTabIndex === "2") {
          conIdQuery.bool.must.push(
            {
              match: {
                "status.is_approved": true,
              },
            },
            {
              match: {
                "status.is_completed": true,
              },
            },
            {
              match: {
                "status.is_submitted": true,
              },
            },
            {
              match: {
                "status.is_employed": false,
              },
            }
          );
        } else if (ContractorTabIndex === "3") {
          conIdQuery.bool.must.push(
            {
              match: {
                "status.is_completed": false,
              },
            },
            {
              match: {
                "status.is_employed": false,
              },
            }
          );
        } else {
          conIdQuery.bool.must.push(
            {
              match: {
                "status.is_approved": false,
              },
            },
            {
              match: {
                "status.is_submitted": true,
              },
            },
            {
              match: {
                "status.is_employed": false,
              },
            },
            {
              match: {
                "status.is_completed": true,
              },
            }
          );
        }

        if(userData.sortColumn){
          axios
          .post(
            `${baseUrl}/contractorTalentData`,
            {
              title: "userpanel_spectra_fetch",
              type: "All",
              conIdQuery,
              size: 20 + userData.page * 20,
              sortColumn : [userData.sortColumn],
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy('userList');
          });
        }else{
          axios
          .post(
            `${baseUrl}/contractorTalentData`,
            {
              title: "userpanel_spectra_fetch",
              type: "All",
              conIdQuery,
              from: userData.page * 20,
              size: 20,
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            // if(talentData.length > 0){
            setDataTalent((pre) => {
              if (userData.page > 0) {
                return [...pre, ...talentData];
              } else return talentData;
            });
            // }else{
            // setDataTalent(pre =>{
            //   if (userData.page > 0) {
            //     return [...pre, ...talentData];
            //   }
            //   else return talentData;
            // })
            // }
            message.destroy('userList');
          });
        }
      }
    }

    if (userType === "spectra" && userData.activeTabIndex === "1") {
      fetchSpectra();
    } else if (userType === "primary") {
      fetchPrimary();
    } else if (userType === "contract") {
      fetchContractor();
    }
  }, [
    userData.reload,
    userData.sortColumn,
    userData.page,
    userData.filterValue,
    userData.filterQuery,
    userData.activeContractTabIndex,
    userData.activePrimaryTabIndex,
    userData.activeTabIndex,
    userData.isSort
  ]);


  //column for talent

  useEffect(() => {
     
    async function fetchDataFromEmployeerUsersAPI(primaryEmployeerId) {
      try {
        // Step 1: Fetch all data from the employeerUsers API
        const response = await axios.get(
          "https://urchin-app-fi4og.ondigitalocean.app/api/web/employeerUsers"
        );
    
        // Step 2: Filter the data based on the primaryEmployeerId
        const filteredData = response.data?.data.filter(
          (doc) => doc.primaryEmployeerId === primaryEmployeerId
        );
      //  console.log(filteredData, "filteredData");
    
        // Step 3: Fetch all data from primaryEmployeers API
        const primaryEmployeerResponse = await axios.get(
          "https://urchin-app-fi4og.ondigitalocean.app/api/web/primaryEmployeers"
        );
  //  console.log(primaryEmployeerResponse, "primaryEmployeerResponse")
        // Step 4: Find the name corresponding to the primaryEmployeerId
        const primaryEmployeer = primaryEmployeerResponse.data?.data.find(
          (doc) => doc.id === primaryEmployeerId
        );
      //  console.log(primaryEmployeer, "primaryEmployeer")
        const primaryEmployeerName = primaryEmployeer?.name || "Unknown";
    setPrimaryEmployeerNameView(primaryEmployeerName)
        // Step 5: Map the filtered data and update bName with the fetched name
        const temp = filteredData.map((doc) => ({
          key: doc.id,
          name: doc.fullName || "",
          mnum: doc.mobileNumber || "",
          bName: primaryEmployeerName, // Use the fetched name here
        }));
    
        // Step 6: Update the state with the processed data
        setData((pre) => (userData.page > 0 ? [...pre, ...temp] : temp));
        message.destroy("talent");
      } catch (error) {
        console.error("Error fetching data from APIs:", error);
      }
    }
    
    // async function fetchFilteredOtherUsersList() {
    //   message.loading({
    //     content: "Loading",
    //     duration: 0,
    //     key: "otherUsersList",
    //   });
    
    //   const i = userData?.activeTabIndex;
    
    //   // Determine filter field name and value
    //   let filter_field_name = "";
    //   let field_value = userData.filterValue?.[0]?.sval || "";
    
    //   if (userData.filterValue?.[0]?.dataIndex === "all") {
    //     filter_field_name = "all";
    //   } else if (userData.filterValue?.[0]?.dataIndex) {
    //     const dataIndex = userData.filterValue[0].dataIndex;
    //     if (["2", "3"].includes(i)) {
    //       filter_field_name = ["name", "bname"].includes(dataIndex)
    //         ? "name"
    //         : dataIndex;
    //     } else {
    //       filter_field_name = dataIndex.includes("name")
    //         ? `personal.${dataIndex}`
    //         : dataIndex;
    //     }
    //   }
    
    // //  console.log("Filter Field Name:", filter_field_name);
    // //  console.log("Field Value:", field_value);
    
    //   // Step 1: Fetch data from API
    //   const apiURL =
    //     i === "2"
    //       ? "https://urchin-app-fi4og.ondigitalocean.app/api/web/contractors"
    //       : i === "3"
    //       ? "https://urchin-app-fi4og.ondigitalocean.app/api/web/primaryEmployeers"
    //       : i === "4"
    //       ? "https://urchin-app-fi4og.ondigitalocean.app/api/web/employeerUsers"
    //       : i === "5"
    //       ? "https://urchin-app-fi4og.ondigitalocean.app/api/web/primaryEmployerUsers"
    //       : "https://urchin-app-fi4og.ondigitalocean.app/api/web/spectrawiseBizopsUsers";
    
    //   const response = await axios.get(apiURL);
    
    //   // Step 2: Map API response to data structure
    //   const allData = response.data?.data.map((doc) => {
    //     if (["2", "3"].includes(i)) {
    //       return {
    //         key: doc.id,
    //         bname: doc.name || "",
    //         company: doc.companyType || "",
    //         address: doc.address || "",
    //         pan: doc.panNumber || "",
    //         gstin: doc.gstinNumber || "",
    //         epf: doc.epfRegNumber || "",
    //         esic: doc.esicRegNumber || "",
    //         pt: doc.ptRegNumber || "",
    //         tan: doc.tanNumber || "",
    //         linkedPrimaryEmployers: doc.linkedPrimaryEmployers || [],
    //         logo: doc.logo || "",
    //       };
    //     } else if (["4"].includes(i)) {
    //       return {
    //         key: doc.id,
    //         name: doc.fullName || "",
    //         mnum: doc.mobileNumber || "",
    //         bName: primaryEmployeerNameView || "",
    //         bId: doc.contractor_id || "",
    //       };
    //     }
    //   });
    
    //   // Step 3: Filter data based on search criteria
    //   const filteredData = allData.filter((doc) => {
    //     if (filter_field_name === "all") {
    //       return Object.values(doc).some((value) =>
    //         value?.toLowerCase()?.includes(field_value.toLowerCase())
    //       );
    //     } else {
    //       return (
    //         doc[filter_field_name]?.toLowerCase().includes(field_value.toLowerCase())
    //       );
    //     }
    //   });
    
    //   // Step 4: Set the filtered data without overwriting
    //   setData(filteredData);
    //   message.destroy("otherUsersList");
    // }

    async function fetchFilteredEmployeerUsersList() {
      message.loading({
        content: "Loading",
        duration: 0,
        key: "otherUsersList",
      });
    
      const i = userData?.activeTabIndex;
    
      let filter_field_name = "";
    
      const filterValue = userData.filterValue[0];
      const dataIndex = filterValue.dataIndex;
    
      if (dataIndex === "all") {
        filter_field_name = "all";
      } else if (dataIndex === "name") {
        filter_field_name = ["3"].includes(i) ? "name" : "fullName";
      } else if (dataIndex === "bName") {
        filter_field_name = ["3"].includes(i) ? "name" : "businessName";
      } else if (dataIndex === "bname") {
        filter_field_name = "name";
      } else if (dataIndex === "mnum") {
        filter_field_name = "mobileNumber";
      } else if (dataIndex === "company") {
        filter_field_name = "company_type";
      } else if (dataIndex === "address") {
        filter_field_name = "address";
      } else if (dataIndex === "pan") {
        filter_field_name = "panNumber";
      } else if (dataIndex === "gstin") {
        filter_field_name = "gstinNumber";
      } else if (dataIndex === "epf") {
        filter_field_name = "epfRegNumber";
      } else if (dataIndex === "esic") {
        filter_field_name = "esicRegNumber";
      } else if (dataIndex === "pt") {
        filter_field_name = "ptRegNumber";
      } else if (dataIndex === "tan") {
        filter_field_name = "tanNumber";
      }
        
      const mappingData = (val) => {
        if (["3"].includes(i)) {
          return {
            logo: val.logo || "",
            key: val.key,
            bname: val.name,
            company: val.companyType || "",
            address: val.address || "",
            pan: val.panNumber || "",
            gstin: val.gstinNumber || "",
            epf: val.epfRegNumber || "",
            esic: val.esicRegNumber || "",
            pt: val.ptRegNumber || "",
            linkedPrimaryEmployers: val.linked_primary_employers || [],
            tan: val.tanNumber || "",
          };
        } else if (["4", "5", "6"].includes(i)) {
          return {
            key: val.key,
            name: val.fullName,
            mnum: val.mobileNumber,
            bName:  primaryEmployeerNameView || "",
            //bId: val.contractor_id,
          };
        }
      };
    
      const apiUrl =
        filter_field_name === "all"
          ? `https://urchin-app-fi4og.ondigitalocean.app/api/web/employeerUsers?search=${filterValue.sval}`
          : `https://urchin-app-fi4og.ondigitalocean.app/api/web/employeerUsers?column=${filter_field_name}&search=${filterValue.sval.replace(/^\+91/, '')}`;
    
     // console.log(apiUrl);
    
      try {
        const response = await axios.get(apiUrl);
        //console.log(response, "response");
    
        const usersDocList = response.data.data.map(mappingData);
        //console.log(usersDocList, "usersDocList");
    
        setData(usersDocList);
        message.destroy("otherUsersList");
      } catch (error) {
        console.error(error);
      }
    }
    


    if(typeof userData.filterValue !== "undefined" && userData.filterValue.length > 0) {
     
      //fetchFilteredOtherUsersList();
      fetchFilteredEmployeerUsersList();
    }
    else{
      fetchDataFromEmployeerUsersAPI(id);
    //  console.log(id,"fetchID")
    }
    
   
    // if (userType === "spectra" && userData.activeTabIndex !== "1"){
    //   if(typeof userData.filterValue !== "undefined" &&
    //   userData.filterValue.length > 0){
    //     fetchFilteredOtherUsersList();
    //   } else if (userData.activeTabIndex === "4") {
    //      // Call the updated API function for activeTabIndex === 3
    //   } 
    //   else{
     
    //   }
    // } ;
    // fetchDataFromEmployeerUsersAPI(id);
    // console.log(id,"fetchID")
  }, [
    userData.filterValue,
    userData.activeTabIndex,
    userData.reload,
    userData.page,
    userData.value,
    id
  ]);

  const [columnTalent, setColumnTalent] = useState([
    {
      title: "Status",
      width: 10,
      sort: false,
      filter: "dropDown",
      dropDownList: [
        "Completed Profile",
        "Incomplete Profile",
        "Yet To Be Verified",
        "Employed",
        "Available",
      ],
      filterValue: "",
      dataIndex: "status",
      position: 0,
      visibility: true,
    },
    {
      title: "Name",
      filterValue: "",
      dataIndex: "name",
      width: 100,
      filter: "text",
      sort: true,
      position: 1,
      visibility: true,
    },
    {
      title: "Address",
      filterValue: "",
      dataIndex: "address",
      filter: "text",
      width: 100,
      sort: false,
      position: 3,
      visibility: true,
    },
    {
      title: "Mobile Number",
      filterValue: "",
      dataIndex: "mnum",
      filter: "text",
      width: 100,
      sort: false,
      position: 4,
      visibility: true,
    },
    {
      title: "Gender",
      filterValue: "",
      dataIndex: "gender",
      filter: "dropDown",
      dropDownList: ["Male", "Female", "Others"],
      width: 100,
      sort: false,
      position: 5,
      visibility: true,
    },
    {
      title: "Blood Group",
      width: 100,
      filter: "dropDown",
      dropDownList: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
      sort: false,
      filterValue: "",
      dataIndex: "bGroup",
      position: 6,
      visibility: false,
    },
    {
      title: "Email",
      filter: "text",
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "email",
      position: 7,
      visibility: false,
    },
    {
      title: "Aadhaar Number",
      filter: "text",
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "aadhaar",
      position: 8,
      visibility: true,
    },
    {
      title: "PAN Number",
      filter: "text",
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "pan",
      position: 9,
      visibility: true,
    },
    {
      title: "BANK A/C NUM",
      filter: "text",
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "bankAC",
      position: 10,
      visibility: true,
    },
    {
      title: "Physically Challenged",
      filter: "dropDown",
      dropDownList: [
        "No, Not Physically Challenged",
        "Yes, Visual",
        "Yes, Hearing",
        "Yes, Locomotive",
      ],
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "handicapped",
      position: 11,
      visibility: false,
    },
    {
      title: "Contractor name",
      filter: "text",
      width: 100,
      sort: true,
      filterValue: "",
      dataIndex: "contractor",
      position: 12,
      visibility: true,
    },
    {
      title: "Employer name",
      filter: "text",
      width: 100,
      sort: true,
      filterValue: "",
      dataIndex: "employer",
      position: 13,
      visibility: true,
    },
    {
      title: "Pincode",
      filter: "text",
      width: 100,
      sort: true,
      filterValue: "",
      dataIndex: "pincode",
      position: 13,
      visibility: true,
    },
  ]);

  const contractorColumns = [
    ...(userData.activeTabIndex == '3' ? [{
      title: "Logo",
      dataIndex: "logo",
      sort: false,
      width: 100,
    }] : []),
    {
      title: "Business Name",
      dataIndex: "bname",
      sort: true,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    },
    {
      title: "Company Type",
      dataIndex: "company",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    }
    ,
    {
      title: "Address",
      dataIndex: "address",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    }
    ,
    {
      title: "PAN Number",
      dataIndex: "pan",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    }
    ,
    {
      title: "GSTIN Number",
      dataIndex: "gstin",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    }
    ,
    {
      title: "EPF Reg. Number",
      dataIndex: "epf",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    }
    ,
    {
      title: "ESIC Reg. Number",
      dataIndex: "esic",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    }
    ,
    {
      title: "PT Reg. Number",
      dataIndex: "pt",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    }
    ,
    {
      title: "TAN Number",
      dataIndex: "tan",
      sort: false,
      filter: "text",
      filterValue: "",
      sorter: (a, b) => a.name - b.name,
      width: 100,
    }
  ];

 const isMounted = useRef(false);
 useEffect(() => {
   if (isMounted.current) {
     const colName = auth.isSpectra ? "spectrawise_bizops_users" : auth.isContract ? "contractor_users" : auth.isPrimary ? "primary_employer_users" : "";
     let list = columnTalent.filter(itx => itx.visibility).map(itx => itx.dataIndex);
     let undefinedList = list.filter(function( element ) {
      return element !== undefined;
    });
     if(list.length > 0){
    //  console.log(db, colName, localStorage.getItem('uid'), list)
      updateDoc(doc(db, colName, localStorage.getItem('uid')), {
       "settings.talent_user_column_list": undefinedList,
     });
     }
   } else {
     isMounted.current = true;
   }
 }, [columnTalent]);


 const memoizedValues = useMemo(() => ({
  talents,
  contractor,
  userType,
  columnTalent,
  columns,
  contractorColumns,
  selectedColumn,
}), [talents, contractor, userType, columnTalent, columns, contractorColumns, selectedColumn]);

useEffect(() => {
  setSearchState((prevState) => ({
      ...prevState,
      ...memoizedValues,
  }));
}, []);

  return (
    <>
      <div className="search">
        {/* <UserPanelSearch
          talents={talents}
          contractor={contractor}
          userType={userType}
          columnTalent={columnTalent}
          columns={columns}
          contractorColumns={contractorColumns}
          selectedColumn={selectedColumn}
        /> */}
        <div className="searchRight">
          <HeadUsersTabs userType={userType} primaryEmployeerNameView={primaryEmployeerNameView} />
          {userData.activeTabIndex === "1" && (
            <MoreOption
              columnTalent={columnTalent}
              setColumnTalent={setColumnTalent}
              selectedColumn={selectedColumn}
              setSelectedColumn={setSelectedColumn}
            />
          )}
        </div>
      </div>
      {talents ? (
        <ResponsiveTable
          talents={talents}
          data={dataTalent}
          column={columnTalent}
          setColumn={setColumnTalent}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          userType={userType}
        />
      ) : contractor ? (
        <ResponsiveTable
          setColumnTalent={setColumnTalent}
          disableColumnOrder
          data={data}
          column={contractorColumns}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          userType={userType}
        />
      ) : (
        <ResponsiveTable
          disableColumnOrder
          setColumnTalent={setColumnTalent}
          data={data}
          column={columns}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          userType={"spectra"}
        />
      )}
    </>
  );
}

export default UserTabs;