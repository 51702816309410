import React, { useContext, useEffect, useState } from "react";
import { message, Tabs } from "antd";
import { dataContext } from "../../context/data";
import ContractUserList from "../contractuser/ContractUserList";
import "../../styles/Panel.scss";
import ContractorDetails from "./ContractorDetail";
import { getContractorById } from "../../services/contractService";

const { TabPane } = Tabs;

const tabStyle = {
  fontSize: "1.3vw",
  fontWeight: "600",
};

function ContractorTabs() {
  const url = window.location.href;
  const contract_id = url.split("/").pop();
  const { userData, setUserData } = useContext(dataContext);
  const [ primaryContrName, setPrimaryContrName ] = useState();

  useEffect(() => {
    loadContractorDetails();
  }, [contract_id]);

  const loadContractorDetails = async () => {
    try {
      const data = await getContractorById(contract_id);
      setPrimaryContrName(data?.name)
    } catch (error) {
      console.error("Error loading contractor details:", error);
      message.error("Failed to load contractor details");
    }
  };

  useEffect(() => {
    setUserData((prev) => ({
      ...prev,
      activePrimaryTabIndex: "1",
    }));
  }, [setUserData]);

  const handleTabChange = (tabKey) => {
    setUserData((prev) => ({
      ...prev,
      activePrimaryTabIndex: tabKey,
      selectedRows: [],
      filterValue: [],
      filterQuery: {},
      page: 0,
    }));
  };

  return (
    <div className="tabs">
      <Tabs
        destroyInactiveTabPane
        defaultActiveKey="1"
        activeKey={userData.activePrimaryTabIndex}
        onChange={handleTabChange}
        className="tabSlider"
        size="large"
      >
        <TabPane tab="Users Tab" key="1" style={tabStyle}>
          <ContractUserList contract_id={contract_id} primaryCname={primaryContrName}/>
        </TabPane>
        <TabPane tab="Company Tab" key="2" style={tabStyle}>
          <ContractorDetails contract_id={contract_id} />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default ContractorTabs;
