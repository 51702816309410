import axiosClient from "../axiosConfig";

export const fetchData = async (url) => {
  try {
    const response = await axiosClient.get(url);
    return response;
  } catch (error) {
    return error
  }
};

export const fetchDataEachRow = async (url) => {
  try {
    const response = await axiosClient.get(url);
    return response;
  } catch (error) {
    return error
  }
};

export const createPost = async (url, postData) => {
  try {
    const response = await axiosClient.post(url, postData);
    return response;
  } catch (error) {
    return error
  }
};

export const updatePost = async (url, updatedData) => {
  try {
    const response = await axiosClient.put(url, updatedData);
    return response;
  } catch (error) {
    return error
  }
};

export const deletePost = async (url) => {
  try {
    const response = await axiosClient.delete(url);
    return response;
  } catch (error) {
    return error
  }
};
