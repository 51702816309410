import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Input, Select, message, Row, Col, Spin } from "antd";
import {
  updateContractor,
  fetchCompanyTypes,
  getContractorById,
} from "../../services/contractService";
import { fetchPrimaryEmployer } from "../../services/jobPostService";
import "./ContractorDetail.css";

const { Option } = Select;

const ContractorDetails = ({ contract_id }) => {
  const [form] = Form.useForm();
  const [contractor, setContractor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [primaryEmployers, setPrimaryEmployers] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [panVerified, setPanVerified] = useState(false);
  const [panIdName, setPanIdName] = useState("");
  const isMounted = useRef(true);

  useEffect(() => {
    loadContractorDetails();
    fetchDropdownData();
    return () => {
      isMounted.current = false;
    };
  }, [contract_id]);

  const loadContractorDetails = async () => {
    try {
      setLoading(true);
      const data = await getContractorById(contract_id);
      if (isMounted.current) {
        setContractor(data);
        setPanVerified(data.panVerified || false);
        form.setFieldsValue({
          ...data,
          primaryEmployers: data.primaryEmployers
            ? Array.isArray(data.primaryEmployers)
              ? data.primaryEmployers[0]
              : data.primaryEmployers
            : undefined,
        });
      }
    } catch (error) {
      console.error("Error loading contractor details:", error);
      message.error("Failed to load contractor details");
    } finally {
      setLoading(false);
    }
  };

  const fetchDropdownData = async () => {
    try {
      const [employersData, typesData] = await Promise.all([
        fetchPrimaryEmployer(),
        fetchCompanyTypes(),
      ]);
      if (isMounted.current) {
        setPrimaryEmployers(employersData || []);
        setCompanyTypes(typesData?.data || []);
      }
    } catch (error) {
      console.error("Error loading dropdown data:", error);
      message.error("Failed to load dropdown data");
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Spin size="large" />
      </div>
    );
  }

  if (!contractor) {
    return (
      <div className="flex items-center justify-center h-full">
        <div>No contractor details found.</div>
      </div>
    );
  }

  return (
    <div className="Form">
      <div className="Vendor-Registered row">
        <div className="firstLine half-width">
          <label className="label_text" htmlFor="name">
            Registered business name <p style={{ color: "red" }}>*</p>
          </label>
          <input
            required
            value={contractor?.name || ""} // Set value to contractor's business name
            name="name"
            type="text"
            className="labelOnBorderInput"
            placeholder="Enter"
            onChange={(e) =>
              setContractor((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />
        </div>
        <div className="firstLine half-width">
          <label className="label_text" htmlFor="name">
            Company type <p style={{ color: "red" }}>*</p>
          </label>
          <select
            required
            name="companyTypeId"
            className="labelOnBorderInput"
            value={contractor?.companyTypeId || ""} // Set value to contractor's company type
            onChange={(e) =>
              setContractor((prev) => ({
                ...prev,
                companyTypeId: e.target.value,
              }))
            } // Update contractor state
            disabled={loading} // Disable dropdown while loading
          >
            <option value="" disabled hidden>
              {loading ? "Loading..." : "Select company type"}
            </option>
            {companyTypes.map((type) => (
              <option key={type.id} value={type.id}>
                {type.companyType}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="Compay-Address full-width">
        <label className="label_text" htmlFor="name">
          Address <p style={{ color: "red" }}>*</p>
        </label>
        <input
          required
          value={contractor.address}
          name="address"
          onChange={(e) =>
            setContractor((prev) => ({
              ...prev,
              address: e.target.value,
            }))
          }
          type="text"
          className="labelOnBorderInput"
          placeholder={"Enter"}
        />
      </div>

      <div className="Pan-Gstin row">
        <div className="firstLine half-width">
          <label
            className="label_text"
            htmlFor="pan"
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            PAN <p style={{ color: "red", margin: "0" }}>*</p>
          </label>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <input
              required
              style={{
                flex: "1", // Allows the input to take available space
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                backgroundColor: "#f9f9f9", // Light background for disabled input
              }}
              type="text"
              value={contractor.panNumber || ""}
              name="panNumber"
              maxLength="10"
              readOnly // Makes input read-only
              className="labelOnBorderInput"
              placeholder="Enter PAN"
            />
            <button
              style={{
                width: "100px",
                backgroundColor: "rgb(52, 181, 58)",
                border: "none",
                color: "white",
                fontWeight: "500",
                fontSize: "14px",
                cursor: "not-allowed", // Ensures button appears disabled
                padding: "10px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              disabled // Disables the button
            >
              {<span style={{ marginRight: "5px" }}>✓ Verified</span>}
            </button>
          </div>
        </div>
        <div className="firstLine half-width">
          <label className="label_text" htmlFor="name">
            GSTIN <p style={{ color: "red" }}>*</p>
          </label>
          <input
            style={{ margin: "-15px 0 0 0 !important" }}
            required
            value={contractor.gstinNumber}
            name="gstinNumber"
            maxLength="15"
            onChange={(e) =>
              setContractor((prev) => ({
                ...prev,
                gstinNumber: e.target.value,
              }))
            }
            className="labelOnBorderInput"
            placeholder={"Enter"}
          />
        </div>
      </div>

      <div className="Epf-Esic row">
        <div className="firstLine half-width">
          <label className="label_text" htmlFor="name">
            EPF Reg. no <p style={{ color: "red" }}>*</p>
          </label>
          <input
            required
            value={contractor.epfRegNumber}
            name="epfRegNumber"
            onChange={(e) =>
              setContractor((prev) => ({
                ...prev,
                epfRegNumber: e.target.value,
              }))
            }
            className="labelOnBorderInput"
            placeholder={"Enter"}
          />
        </div>
        <div className="firstLine half-width">
          <label className="label_text" htmlFor="name">
            ESIC Reg. no <p style={{ color: "red" }}>*</p>
          </label>
          <input
            required
            value={contractor.esicRegNumber}
            name="esic"
            onChange={(e) =>
              setContractor((prev) => ({
                ...prev,
                esicRegNumber: e.target.value,
              }))
            }
            className="labelOnBorderInput"
            placeholder={"Enter"}
          />
        </div>
      </div>

      <div className="Pt-Tan row">
        <div className="firstLine half-width">
          <label className="label_text" htmlFor="name">
            PT Reg. no <p style={{ color: "red" }}>*</p>
          </label>
          <input
            required
            value={contractor.ptRegNumber}
            name="pt"
            onChange={(e) =>
              setContractor((prev) => ({
                ...prev,
                ptRegNumber: e.target.value,
              }))
            }
            className="labelOnBorderInput"
            placeholder={"Enter"}
          />
        </div>
        <div className="firstLine half-width">
          <label className="label_text" htmlFor="name">
            TAN <p style={{ color: "red" }}>*</p>
          </label>
          <input
            required
            value={contractor.tanNumber}
            name="tan"
            onChange={(e) =>
              setContractor((prev) => ({
                ...prev,
                tanNumber: e.target.value,
              }))
            }
            className="labelOnBorderInput"
            placeholder={"Enter"}
          />
        </div>
      </div>
      <div className="modalFooter">
        <button
          className="cancelButton"
          onClick={() => {
            form.setFieldsValue(contractor); // Reset form fields to their initial state
          }}
          style={{
            background: "none",
            border: "0.12rem solid #0361fe",
            color: "#0361fe",
            fontWeight: 500,
          }}
        >
          Cancel
        </button>

        {/* Save Button */}
        <button
          className="saveButton"
          type="button" // Use "button" to prevent form submission
          onClick={async () => {
            try {
              await updateContractor(contract_id, contractor);
              message.success("Contractor updated successfully");
              setEditing(false); // Exit editing mode
              await loadContractorDetails(); // Reload contractor details
            } catch (error) {
              console.error("Error saving contractor details:", error);
              message.error("Failed to save contractor details");
            }
          }}
          style={{
            color: "white",
            background: "#0361fe",
            fontWeight: 500,
            border: "none",
            outline: "none",
            padding: "0.45rem",
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ContractorDetails;
