import React, { useEffect, useState } from "react";
import { Modal, Form, Input, message, Select, Button, Row, Col } from "antd";
import axios from "axios";
import {
  createContractor,
  fetchCompanyTypes,
  updateContractor,
} from "../../services/contractService";
import { fetchPrimaryEmployer } from "../../services/jobPostService";
import "./ContractorDetail.css";
import { fetchData } from "../../backendApi";
const { Option } = Select;

const ContractorForm = ({ visible, onClose, onSuccess, type, id, data }) => {
  const [form] = Form.useForm();
  const [primaryEmployers, setPrimaryEmployers] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [panVerified, setPanVerified] = useState(false);
  const [panIdName, setPanIdName] = useState("");

  useEffect(() => {
    if (visible) {
      if (type === "edit" && data) {
        form.setFieldsValue(data);
        setPanVerified(true); // Assume PAN is verified for edit mode
      } else {
        form.resetFields();
        setPanVerified(false);
      }
      fetchDropdownData();
    }
  }, [visible, type, data, form]);

  const fetchDropdownData = async () => {
    setLoading(true);
    try {
      const [employersData, typesData] = await Promise.all([
        fetchPrimaryEmployer(),
        fetchCompanyTypes(),
      ]);
      setPrimaryEmployers(employersData);
      setCompanyTypes(typesData.data);
    } catch (error) {
      message.error("Failed to load dropdown data");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (!panVerified) {
        message.error("Please verify PAN before submitting");
        return;
      }
      const contractorData = {
        ...values,
        panIdName,
      };

      if (type === "edit") {
        await updateContractor(id, contractorData);
                message.success("Contractor updated successfully");
      } else {
        await createContractor(contractorData);
                message.success("Contractor created successfully");
      }
      onSuccess();
      form.resetFields();
      setPanVerified(false);
      setPanIdName("");
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || // Access the API response error message
        error?.message
        if(errorMessage) message.error(errorMessage); // Display the error message in the UI
    }
  };

  const handleVerifyPAN = async () => {
    try {
      const panNumber = form.getFieldValue("panNumber");
      if (!panNumber) {
        message.error("Please enter a PAN number before verifying");
        return;
      }

      message.loading({
        content: "Verifying PAN...",
        key: "panVerify",
        duration: 0,
      });

      const existingContractors = await fetchData(`/primaryContractors`)
      setPanVerified(false);
      const panExists = existingContractors.data.data.some(
        (contractor) => contractor.panNumber === panNumber
      );

      if (panExists) {
        message.error({
          content: `PAN number "${panNumber}" already exists.`,
          key: "panVerify",
        });

        return;
      }

      // Step 2: Verify PAN with external service
      const functions_url = process.env.REACT_APP_FUNCTIONS_URL;
      console.log("Functions URL:", `${functions_url}/verifyCompanyPan`); // Debug

      const verifyResponse = await axios.post(
        `${functions_url}/verifyCompanyPan`,
        {
          id_number: panNumber,
        }
      );

      const {
        success,
        message: verifyMessage,
        data,
      } = verifyResponse.data || {};
      console.log("PAN Verification Response:", success, verifyMessage, data); // Debug

      if (verifyMessage) {
        setPanIdName(data.data.full_name || "");
        console.log(data.data.full_name, "gyugufhyfh");
        setPanVerified(true);
        message.success({
          content: "PAN verified successfully!",
          key: "panVerify",
        });
      } else {
        throw new Error(verifyMessage || "PAN verification failed");
      }
    } catch (error) {
      console.error("PAN Verification Error:", error);
      message.error({
        content: error.response?.data?.message || "PAN verification failed.",
        key: "panVerify",
      });
      setPanVerified(false);
    }
  };

  const validatePAN = (_, value) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    if (!value) {
      return Promise.reject("PAN is required");
    }

    if (/[a-z]/.test(value)) {
      return Promise.reject("PAN should only contain uppercase letters");
    }

    if (panRegex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(
      "Invalid PAN format"
    );
  };

  const validateTAN = (_, value) => {
    const tanRegex = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
    if (!value || tanRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Invalid TAN format");
  };

  const validateGSTIN = (_, value) => {
    const gstinRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (!value || gstinRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Invalid gstin number");
  };

  const ModelTitle = () => {
    return <h3 className="modalHeader">Add Contractor</h3>;
  };

  const ModelTitleEdit = () => {
    return <h3 className="modalHeader">Add Contractor</h3>;
  };
  return (
    <Modal
      title={type === "edit" ? <ModelTitleEdit /> : <ModelTitle />}
      visible={visible}
      onOk={handleSubmit}
      onCancel={onClose}
      width={800}
      bodyStyle={{
        padding: '20px',
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
      }}
      style={{
        top: '40px',
      }}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
  key="submit"
  type="primary"
  onClick={handleSubmit}
  loading={loading}
  disabled={!panVerified} // Disable button until panVerified is true
  style={{
    backgroundColor: panVerified ? "rgb(52, 181, 58)" : "rgb(217 17 217 / 50%)", // Purple when enabled, lighter color when disabled
    border: "none",
    width: "10%",
    color: 'white'
  }}
>
  Save
</Button>
,
      ]}
    >
      <Form form={form} layout="vertical" className="contractor-form">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Registered business name"
              rules={[
                { required: false, message: "Please enter business name" },
                {
                  min: 3,
                  message: "Business name must be at least 3 characters",
                },
                {
                  max: 100,
                  message: "Business name cannot exceed 100 characters",
                },
              ]}
            >
              <Input placeholder="Enter business name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="companyTypeId"
              label="Company Type"
              rules={[
                { required: false, message: "Please select company type" },
              ]}
            >
              <Select placeholder="Select company type" loading={loading}>
                {companyTypes.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.companyType}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                { required: true, message: "Please enter address" },
              ]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
  <Form.Item
    name="panNumber"
    label="PAN"
    style={{ flex: 1, marginBottom: 0 }}
    rules={[
      { required: true, message: "Please enter PAN number" },
      { validator: validatePAN },
    ]}
  >
    <Input placeholder="Enter PAN" disabled={panVerified} />
  </Form.Item>
  <Button
    type="primary"
    onClick={handleVerifyPAN}
    style={{
      backgroundColor: panVerified ? "rgb(52, 181, 58)" : 'blue', // Green when verified, Blue otherwise
      color: 'white',
      top: '15px'
    }}
    disabled={panVerified}
  >
    {panVerified ? "✓ Verified" : "Verify"}
  </Button>
</Col>

          <Col span={12}>
            <Form.Item
              name="gstinNumber"
              label="GSTIN"
              rules={[
                { required: true, message: "Please enter GSTIN number" },
                { validator: validateGSTIN },
              ]}
            >
              <Input placeholder="Enter GSTIN" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="epfRegNumber"
              label="EPF Reg. no"
              rules={[
                { required: true, message: "Please enter EPF number" },
                {
                  pattern: /^[A-Z]{5}\d{7}\d{10}$/,
                  message: "Invalid EPF Registration number format",
                },
              ]}
            >
              <Input placeholder="Enter EPF Reg. no" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="esicRegNumber"
              label="ESIC Reg. no"
              rules={[
                { required: true, message: "Please enter ESIC number" },
                {
                  pattern: /^\d{17}$/,
                  message: "ESIC Registration number should be 17 digits",
                },
              ]}
            >
              <Input placeholder="Enter ESIC Reg. no" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="ptRegNumber"
              label="PT Reg. no"
              rules={[
                { required: true, message: "Please enter PT number" },
                {
                  pattern: /^[A-Z0-9]{11}$/,
                  message: "Invalid PT Registration number format",
                },
              ]}
            >
              <Input placeholder="Enter PT Reg. no" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tanNumber"
              label="TAN"
              rules={[
                { required: true, message: "Please enter TAN number" },
                { validator: validateTAN },
              ]}
            >
              <Input placeholder="Enter TAN" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="primaryEmployers"
          label="Primary Employers"
          rules={[
            { required: true, message: "Please select primary employers" },
          ]}
        >
          <Select placeholder="Select Primary Details" loading={loading}>
            {primaryEmployers.map((employer) => (
              <Option key={employer.id} value={employer.id}>
                {employer.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ContractorForm;
