import React from "react";
import { Descriptions } from "antd";

function JobDetailBase({ jobData }) {
  return (
    <div className="jobDetailBase">
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Job Id">{jobData?.id}</Descriptions.Item>
        <Descriptions.Item label="Principal Employer">
          {jobData?.primaryEmployer}
        </Descriptions.Item>
        <Descriptions.Item label="Posted by">
          {jobData?.postedBy}
        </Descriptions.Item>
        <Descriptions.Item label="Location">
          {jobData?.location}
        </Descriptions.Item>
        <Descriptions.Item label="Salary">{`₹${jobData?.salary?.from} - ${jobData?.salary?.to}/${jobData?.salary?.period}`}</Descriptions.Item>
        <Descriptions.Item label="Start date">
          {jobData?.startDate}
        </Descriptions.Item>
        <Descriptions.Item label="Experience Required">{`${jobData?.experience?.from} - ${jobData?.experience?.to} ${jobData?.experience?.period}`}</Descriptions.Item>
        <Descriptions.Item label="No. of questions">
          {jobData?.questions?.length || 0}
        </Descriptions.Item>
      </Descriptions>

      <div className="description">
        <h3>Description</h3>
        <div className="content">{jobData?.description}</div>
      </div>

      <div className="formalities">
        <h3>Formalities</h3>
        <div className="content">
          {jobData?.formalities?.map((item, index) => (
            <div key={index}>{`${index + 1}. ${item}`}</div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default JobDetailBase;
