import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Input, Space, Select, message, Modal } from "antd";
import { MinusCircleOutlined, EditOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { useHistory } from "react-router-dom";
import {
  createContractUser,
  deleteContractUser,
  fetchContractUsersList,
  updateContractUser,
} from "../../services/contractUserService";
import ContractUserForm from "./ContractUserForm";
import { createContractor } from "../../services/contractService";
import ResponsiveTable from "../panel/panelHead/ResponsiveTable";

const { Option } = Select;

const ContractUserList = ({ contract_id, primaryCname }) => {
  const [contractUsers, setContractUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchField, setSearchField] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const history = useHistory();
  const primaryContractorId = contract_id;

  const loadContractUsers = useCallback(
    async (pageNum) => {
      if (!hasMore && pageNum > 1) return;
      setLoading(true);
      try {
        const searchParams = {
          searchField,
          searchTerm,
          primaryContractorId,
        };
        const response = await fetchContractUsersList(
          pageNum,
          10,
          primaryContractorId,
          searchParams
        );
        const newUsers = response.data;
        const total = response.totalCount || newUsers.length;

        setContractUsers((prevUsers) =>
          pageNum === 1 ? newUsers : [...prevUsers, ...newUsers]
        );
        setTotalItems(total);
        setHasMore(newUsers.length === 10);
        setPage(pageNum);
      } catch (error) {
        console.error("Error loading contract users:", error);
        message.error("Failed to load contract users");
      } finally {
        setLoading(false);
      }
    },
    [searchField, searchTerm, primaryContractorId]
  );

  const debouncedSearch = useCallback(
    debounce(() => {
      setContractUsers([]);
      setPage(1);
      setHasMore(true);
      loadContractUsers(1);
    }, 300),
    [loadContractUsers]
  );

  useEffect(() => {
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchField, searchTerm, debouncedSearch]);

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      loadContractUsers(page + 1);
    }
  };

  const handleCreate = () => {
    setEditingUser(null);
    setModalVisible(true);
  };

  const handleEdit = (user) => {
    setEditingUser(user);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteContractUser(id);
      message.success("Contract user deleted successfully");
      setContractUsers([]);
      setPage(1);
      setHasMore(true);
      loadContractUsers(1);
    } catch (error) {
      console.error("Error deleting contract user:", error);
      message.error("Failed to delete contract user");
    }
  };

  const handleSearch = (value, field) => {
    if (field === "term") {
      setSearchTerm(value);
    } else {
      setSearchField(value);
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      if (editingUser) {
        await updateContractUser(editingUser.id, values);
        message.success("Contract user updated successfully");
      } else {
        await createContractUser(values);
        message.success("Contract user created successfully");
      }
      setModalVisible(false);
      setEditingUser(null);
      loadContractUsers(1);
    } catch (error) {
      console.error("Error updating contract user:", error);
      message.error("Failed to update contract user");
    }
  };

  const columns = [
    { title: "NAME", dataIndex: "fullName", key: "fullName" },
    { title: "Business Name", dataIndex: "businessName", key: "businessName" },
    { title: "Mobile Number", dataIndex: "mobileNumber", key: "mobileNumber" },
    {
      title: "ACTIONS",
      key: "actions",
      render: (_, record) => (
        <Space size="small"> {/* Adjusts the spacing between buttons */}
        {/* Edit Button */}
        <Button
          type="text"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="14px" /* Smaller size for compactness */
              height="14px"
            >
              <path d="M 18 2 L 15.585938 4.4140625 L 19.585938 8.4140625 L 22 6 L 18 2 z M 14.076172 5.9238281 L 3 17 L 3 21 L 7 21 L 18.076172 9.9238281 L 14.076172 5.9238281 z" />
            </svg>
          }
          onClick={(e) => {
            e.stopPropagation();
            handleEdit(record);
          }}
          className="text-blue-500 hover:text-blue-600"
          style={{ padding: "2px", fontSize: "10px" }} /* Smaller padding and font size */
          shape="circle"
        />
      
        {/* Delete Button */}
        <Button
          type="text"
          icon={
            <svg
              fill="#FA5252"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="14px" /* Smaller size for compactness */
              height="14px"
            >
              <path d="M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3652344 7 L 6.0683594 22 L 17.931641 22 L 19.634766 7 L 4.3652344 7 z" />
            </svg>
          }
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(record.id);
          }}
          className="text-red-500 hover:text-red-600"
          style={{ padding: "2px", fontSize: "10px" }} /* Smaller padding and font size */
          shape="circle"
        />
      </Space>
      

      ),
    },
  ];

  return (
    <div className="contract-user-list">
      <div className="search-header" style={{ margin: "20px 0 0 10px" }}>
        <Space size="middle" className="flex-grow mb-4">
          <Select
            style={{ width: 150 }}
            value={searchField}
            onChange={(value) => handleSearch(value, "field")}
          >
            <Option value="All">All</Option>
            <Option value="fullName">Name</Option>
            <Option value="businessName">Business Name</Option>
            <Option value="mobileNumber">Mobile Number</Option>
          </Select>
          <Input
            placeholder="Search contract users..."
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value, "term")}
            style={{ width: 200 }}
          />
        </Space>
        <Button
          style={{ float: "right",right:"60px" }}
          type="primary"
          onClick={handleCreate}
          className="mb-4"
        >
          New Contract User
        </Button>
      </div>
       <Table
        rowClassName="table-row-light"
        columns={columns}
        dataSource={contractUsers}
        rowKey="id"
        loading={loading}
        pagination={false}
      />
      {hasMore && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Button onClick={handleLoadMore} loading={loading}>
            Load More
          </Button>
        </div>
      )}
      {!hasMore && contractUsers.length > 0 && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          {/* Showing all {totalItems} contract users */}
        </div>
      )}
      <ContractUserForm
      primaryCname={primaryCname}
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
          setEditingUser(null);
        }}
        onSubmit={handleFormSubmit}
        initialValues={editingUser}
        primaryContractorId={primaryContractorId}
      />
    </div>
  );
};

export default ContractUserList;
