import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Card, Button, Spin, Descriptions, message, Space, Grid } from "antd";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { getJobRoleById } from "../../services/jobRoleService";
import JobRoleForm from "./JobRoleForm";

const JobRoleDetails = () => {
  const [jobRole, setJobRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    loadJobRole();
  }, [id]);

  const loadJobRole = async () => {
    try {
      setLoading(true);
      const data = await getJobRoleById(id);
      setJobRole(data);
    } catch (error) {
      console.error("Error fetching job Role:", error);
      message.error("Failed to load job Role details");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (!jobRole) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <h2>Job Role not found</h2>
        <Button type="primary" onClick={() => history.push("job-Roles")}>
          Back to Job Roles
        </Button>
      </div>
    );
  }

  return (
    <div style={{ padding: "24px" }}>
      <div
        style={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          icon={<ArrowLeftOutlined />}
          onClick={() => history.replace("/Talntworx/jobpost")}
        >
          Back to List
        </Button>
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => setModalVisible(true)}
        >
          Edit Role
        </Button>
      </div>
      <Card title={jobRole.designation} bordered={false}>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Job ID">{jobRole.id}</Descriptions.Item>
          <Descriptions.Item label="Description">
            {jobRole.description}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <JobRoleForm
        data={jobRole}
        id={id}
        type="edit"
        onClose={() => setModalVisible(false)}
        visible={modalVisible}
        initialValues={jobRole}
        onCancel={() => setModalVisible(false)}
        onSuccess={() => {
          loadJobRole();
          setModalVisible(false);
          // message.success("Job Role updated successfully");
        }}
      />
    </div>
  );
};

export default JobRoleDetails;
