import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import userImg from "../../../assets/imgs/user.png";
import femaleUserImage from "../../../assets/imgs/femaleUserImage.svg";
import GoBack from "../../../assets/icons/leftArrow.svg";
import "../../../styles/UserDetails.scss";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";
import ImgPopupModal from "../ImgPopup";
import { Modal, Input, Select, message, DatePicker, Form } from "antd";
import {
  collection,
  where,
  doc,
  orderBy,
  getDoc,
  updateDoc,
  getDocs,
  getFirestore,
  query,
  Timestamp,
} from "firebase/firestore";
// import { message, Modal } from "antd";
import isUrl from "is-url";
import moment from "moment";
//status import
import YTV from "../../../assets/icons/status/YTV.svg";
import CP from "../../../assets/icons/status/CP.svg";
import IP from "../../../assets/icons/status/IP.svg";
import E from "../../../assets/icons/status/E.svg";
import UE from "../../../assets/icons/status/UE.svg";
import { useContext } from "react";
import { dataContext } from "../../../context/data";
import axios from "axios";
import baseUrl from "../../../utils/baseUrl";

import { Radio } from "antd";

import "./userdetails.css";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import { fetchDataEachRow, updatePost } from "../../../backendApi/index";

const { Option } = Select;
const { TextArea } = Input;

const functions_url = process.env.REACT_APP_FUNCTIONS_URL;

// const db = getFirestore(app);

function UserDetailsBase({ userType, from, userPath }) {
  const history = useHistory();
  const db = getFirestore();
  const { id } = useLocation().state;
  const [tabIndex, setTabIndex] = useState("1");
  const [visible, setVisible] = useState(false);
  const handleClose = () => setVisible((pre) => !pre);
  const [imgModal, setImgModal] = useState({ handleClose });
  const [userData, setUserData] = useState({});
  const [userDataOriginal, setUserDataOriginal] = useState({});
  const [data, setData] = useState({ employer: [], contractor: [] });
  const [showModal, setShowModal] = useState(false);
  const [isRejectionModal, setIsRejectionModal] = useState(false);
  const [reload, setReload] = useState(false);
  const userContext = useContext(dataContext);
  const { dashboardData, setDashboardData } = useContext(dataContext);
  // console.log(userData);

  const [errorMessage, setErrorMessage] = useState({
    mobile_number: "",
    email: "",
    gender: "",
    nationality: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    dateOfBirth: "",
  });

  const [isEdit, setIsEdit] = useState(false);

  const [countryCode, setCountryCode] = useState("+91");
  const [dob1, setDob] = useState(null);

  useEffect(() => {
    if (userData.personal?.dob) {
      const dobTimestamp = userData.personal.dob;
      // const dob = dobTimestamp?.toDate
      //   ? dobTimestamp.toDate()
      //   : new Date(dobTimestamp.seconds * 1000);
      setDob(moment(dobTimestamp));
    }
  }, [userData]);

  useEffect(() => {
    if (data?.personal?.dob) {
      setDob(data.personal.dob);
    }
  }, [data]);

  let [selectedMobileNum, setSelectNumb] = useState(
    userData?.contact?.mobile_number
  );

  useEffect(() => {
    setDashboardData((pre) => {
      return { ...pre, DblastFetch: undefined, page: 0 };
    });
    userContext.setUserData((pre) => {
      return { ...pre, page: 0 };
    });
  }, []);

  const handleChange = () => {
    setDashboardData((pre) => {
      return {
        ...pre,
        talentLastfetch: undefined,
        talentPage: 0,
        totalTalentFilterValue: [],
        totalTalentPage: 0,
        filterQuery: {},
      };
    });
  };

  const statusFinder = (s) => {
    if (s?.is_approved && s?.is_completed && s?.is_submitted && s?.is_employed)
      s = "Employed";
    else if (s?.is_approved && s?.is_completed && s?.is_submitted)
      s = "Available"; //need to clarify
    else if (!s?.is_approved && s?.is_completed && !s?.is_submitted)
      s = "Completed Profile";
    else if (!s?.is_approved && s?.is_submitted) s = "Yet To Be Verified";
    //if (!s?.is_approved && !s?.is_completed && s?.is_submitted)
    else s = "Incomplete Profile";
    return s;
  };

  useEffect(() => {
    async function fetch() {
      try {
        const fetchTalntUser = await fetchDataEachRow(
          `https://urchin-app-fi4og.ondigitalocean.app/api/web/talnt/${id}`
        );
        if (fetchTalntUser.status === 200) {
          setUserData(fetchTalntUser?.data?.talntUser);
          setData(fetchTalntUser?.data?.talntUser);
          setUserDataOriginal(fetchTalntUser?.data?.talntUser);
        } else {
          console.log("No such document!");
          history.replace("/Talntworx/userPanel");
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetch();
  }, [reload]);

  async function updateRec() {
    //   await updateDoc(doc(db, "talent_users", "job_data"), {
    //     last_used_job_code: result
    // })
  }

  const ImgPopup = (src) => {
    setVisible(true);
    setImgModal((pre) => {
      return { ...pre, src };
    });
    // console.log(visible);
  };
  const handleShowModal = () => {
    setShowModal((pre) => !pre);
  };
  const handleApprove = async () => {
    message.loading({ content: "Loading...", key: "approve", duration: 0 });
    try {
      const res = await axios.put(
        `https://urchin-app-fi4og.ondigitalocean.app/api/web/talnt/updateTalntStatus/${id}`,
        { "status.is_approved": true, type: "approve" }
      );
      if (res?.data?.talentData?.id) {
        message.success({ content: "Successfully Approved", key: "approve" });
        handleShowModal();
        setReload((pre) => !pre);
      }
    } catch (e) {
      console.log(e);
    }
    // axios
    //   .post(`${baseUrl}/messageNotification`, {
    //     title: "profile_verification",
    //     content: {
    //       userId: [id],
    //       status: true,
    //     },
    //   })
    //   .then((response) => {
    //     message.success({ content: "Successfully Approved", key: "approve" });
    //     handleShowModal();
    //     setReload((pre) => !pre);
    //   })
    //   .catch((error) => {
    //     message.error({ content: "Something went wrong!", key: "approve" });
    //     console.log(error);
    //   });
  };
  const handleRejection = async () => {
    message.loading({ content: "Loading...", key: "approve", duration: 0 });
    // const docRef = doc(db, "talent_users", id);
    // await updateDoc(docRef, {
    //   "status.is_submitted": false,
    // });
    try {
      const res = await axios.put(
        `https://urchin-app-fi4og.ondigitalocean.app/api/web/talnt/updateTalntStatus/${id}`,
        { "status.is_submitted": true, type: "reject" }
      );

      if (res?.data?.talentData?.id) {
        message.error({ content: "Rejected", key: "approve" });
        handleShowModal();
        setIsRejectionModal(false);
        setReload((pre) => !pre);
      }
    } catch (e) {
      console.log(e);
    }

    // axios
    //   .post(`${baseUrl}/messageNotification`, {
    //     title: "profile_verification",
    //     content: {
    //       userId: [id],
    //       status: false,
    //     },
    //   })
    //   .then((response) => {
    //     message.error({ content: "Rejected", key: "approve" });
    //     handleShowModal();
    //     setIsRejectionModal(false);
    //     setReload((pre) => !pre);
    //   })
    //   .catch((error) => {
    //     message.error({ content: "Something went wrong!", key: "approve" });
    //     console.log(error);
    //   });
  };
  let statusSRC;
  switch (statusFinder(userData?.status)) {
    case "Completed Profile":
      statusSRC = CP;
      break;
    case "Incomplete Profile":
      statusSRC = IP;
      break;
    case "Yet To Be Verified":
      statusSRC = YTV;
      break;
    case "Employed":
      statusSRC = E;
      break;
    case "Available":
      statusSRC = UE;
      break;
    default:
      statusSRC = IP;
      break;
  }

  const dob = userData.personal?.dob
    ? moment(
        new Timestamp(
          userData.personal?.dob.seconds || userData.personal?.dob._seconds,
          userData.personal?.dob.nanoseconds ||
            userData.personal?.dob._nanoseconds
        ).toDate()
      )
    : null;

  let address = `${userData.contact?.address_line_1} 
    ${userData.contact?.address_line_2} 
    ${userData.contact?.city} 
    ${userData.contact?.state}
    ${userData.contact?.pincode}`;

  let email = userData?.contact?.email;
  console.log(email);

  // const handleDobChange = (date) => {
  //   setDob(date);
  //   setErrorMessage((prev) => ({
  //     ...prev,
  //     dateOfBirth: "",
  //   }));
  //   setUserData((prev) => ({
  //     ...prev,
  //     personal: {
  //       ...prev.personal,
  //       dob: date ? Timestamp.fromDate(date.toDate()) : null,
  //     },
  //   }));
  // };

  const handleDobChange = (date) => {
    const formattedDate = date
      ? moment(date.toDate()).format("YYYY-MM-DD")
      : null;
    const formattedDate1 = date ? date.format("DD/MM/YYYY") : null;
    setDob(date);
    setUserData((prev) => ({
      ...prev,
      personal: {
        ...prev.personal,

        dob: formattedDate,
      },
    }));
  };

  const inputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const fieldName = e.target.getAttribute("data-fieldname");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (name === "mobile_number") {
      if (value.length <= 10) {
        setUserData((prev) => ({
          ...prev,
          contact: {
            ...prev.contact,
            [name]: countryCode + value,
          },
        }));
      }
      if (!value) {
        setErrorMessage((prev) => ({
          ...prev,
          [name]: `Please Enter Valid ${fieldName}`,
        }));
      } else if (value.length < 10) {
        setErrorMessage((prev) => ({
          ...prev,
          [name]: `${fieldName} must be 10 digits`,
        }));
      } else {
        setErrorMessage((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else if (name === "email") {
      let checkCorrectFormat = value.trim().endsWith(".com");
      if (!value || !checkCorrectFormat) {
        setErrorMessage((prev) => ({
          ...prev,
          [name]: `Please Enter ${fieldName}`,
        }));
      } else if (!emailRegex.test(value)) {
        setErrorMessage((prev) => ({
          ...prev,
          [name]: `Please Enter a valid ${fieldName}`,
        }));
      } else {
        setErrorMessage((prev) => ({
          ...prev,
          [name]: "",
        }));
      }

      setUserData((prev) => ({
        ...prev,
        contact: {
          ...prev.contact,
          [name]: value,
        },
      }));
    } else if (name === "nationality") {
      setUserData((prev) => ({
        ...prev,
        personal: {
          ...prev.personal,
          nationality: e.target.value,
        },
      }));
      if (!value) {
        setErrorMessage((prev) => ({
          ...prev,
          [name]: `Please Enter a valid ${fieldName}`,
        }));
      } else {
        setErrorMessage((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      setUserData((prev) => ({
        ...prev,
        contact: {
          ...prev.contact,
          [name]: value,
        },
      }));

      if (!value) {
        setErrorMessage((prev) => ({
          ...prev,
          [name]: `Please Enter ${fieldName}`,
        }));
      } else {
        setErrorMessage((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
  };

  const initialDOB = userData.personal?.dob
    ? moment(
        new Timestamp(
          userData.personal.dob.seconds,
          userData.personal.dob.nanoseconds
        ).toDate()
      )
    : null;

  // const submitHandler = (userData) => {
  //   let {
  //     contact: {
  //       address_line_1,
  //       address_line_2,
  //       city,
  //       country,
  //       email,
  //       mobile_number,
  //       pincode,
  //       state,
  //     },
  //     personal: { gender, nationality },
  //     education: { field, level, url },
  //     employment: { experience, uan },
  //     bank: {},
  //     education: {},
  //     employment: {},
  //     family: {},
  //     identity: {},
  //     nominee: {},
  //     pan: {
  //       dob ="",
  //       is_verified = "",
  //       name = "",
  //       pan_number = "",
  //       url: panUrl = "",
  //     } = {},
  //     personal: {},
  //     settings: {},
  //     status: {},
  //   } = userData;
  //   let checkCorrectFormat = email.trim().endsWith(".com");
  //   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   let emailCheck = emailRegex.test(email.trim());
  //   return true;
  //   // if (
  //   //   email &&
  //   //   checkCorrectFormat &&
  //   //   emailCheck &&
  //   //   mobile_number.length === 13 &&
  //   //   pincode
  //   // ) {
  //   //   return true;
  //   // } else {
  //   //   return false;
  //   // }
  // };

  const submitHandler = (userData) => {
    let {
      contact: {
        address_line_1,
        address_line_2,
        city,
        country,
        email,
        mobile_number,
        pincode,
        state,
      },
      personal: { gender, nationality },
      education: { field, level, url },
      employment: { experience, uan },
      bank: {},
      family: {},
      identity: {},
      nominee: {},
      pan = {},
      settings: {},
      status: {},
    } = userData;

    let checkCorrectFormat = email.trim().endsWith(".com");
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let emailCheck = emailRegex.test(email.trim());

    return true;

    // Optional validation logic:
    // if (
    //   email &&
    //   checkCorrectFormat &&
    //   emailCheck &&
    //   mobile_number.length === 13 &&
    //   pincode
    // ) {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  return (
    <>
      <div
        className="pageContainer"
        style={{
          height: "100%",
          overflowY: "scroll",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <section className="topNavSection">
          <Link
            to={
              from == "userPanel"
                ? `/${userPath}/userPanel`
                : dashboardData.totalTalentTrigger
                ? `/${userPath}/totalTalents`
                : `/${userPath}`
            }
          >
            <span className="leftArw">
              <img src={GoBack} alt="go back" onClick={() => handleChange()} />
            </span>{" "}
            Profile
          </Link>
          {userType === "spectra" && (
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              <div
                style={{
                  display: "flex",
                  // gridTemplateColumns: "1fr 1fr",
                  gap: "5%",
                }}
              >
                {isEdit ? (
                  <div className="mb-2" style={{ display: "flex" }}>
                    <button
                      style={{
                        backgroundColor: "#0361fe",
                        color: "white",
                        border: "none",
                        fontSize: "15px",
                        padding: "6px 10px",
                        marginLeft: "30px",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                      onClick={async () => {
                        let res = submitHandler(userData);
                        if (res) {
                          let {
                            contact: {
                              address_line_1 = "",
                              address_line_2 = "",
                              city = "",
                              country = "",
                              email = "",
                              mobile_number = "",
                              pincode = "",
                              state = "",
                            } = {},
                            personal: {
                              gender = "",
                              nationality = "",
                              blood_group = "",
                              dob: personalDob = "",
                              full_name = "",
                              handicapped_reason = "",
                              is_handicapped = "",
                              profile_image_url = "",
                            } = {},
                            education: {
                              field = "",
                              level = "",
                              url = [],
                            } = {},
                            employment: { experience = "", uan = "" } = {},
                            bank: {
                              account_name = "",
                              account_number = "",
                              bank_name = "",
                              branch_name = "",
                              ifsc_code = "",
                              is_verified: bank_is_verified = "",
                              url: bankUrl = [],
                            } = {},
                            family: {
                              father_name = "",
                              is_married = "",
                              spouse_name = "",
                            } = {},
                            identity: {
                              aadhaar: {
                                aadhaar_hash = "",
                                aadhaar_number: aadhaar_aadhaar_number = "",
                                address_line_1: aadhaar_address_line_1 = "",
                                address_line_2: aadhaar_address_line_2 = "",
                                city: aadhaar_city = "",
                                country: aadhaa_country = "",
                                dob: aadhaar_dob = "",
                                is_verified: aadhaar_is_verified = false,
                                name: aadhaar_name = "",
                                pincode: aadhaar_pincode = "",
                                state: aadhaar_state = "",
                                url: aadhaar_url = {},
                              } = {},
                              pan: {
                                dob: pan_dob = "",
                                is_verified: pan_is_verified = "",
                                name: pan_name = "",
                                pan_number: pan_pan_number = "",
                                url: pan_url = "",
                              } = {},
                            } = {},
                            nominee: {
                              aadhaar_number = "",
                              address_line_1: nominee_address_line_1 = "",
                              address_line_2: nominee_address_line_2 = "",
                              city: nominee_city = "",
                              country: nominee_country = "",
                              dob = "",
                              is_personal_address = false,
                              name = "",
                              pincode: nominee_pincode = "",
                              relationship = "",
                              state: nominee_state = "",
                            } = {},
                            settings: {
                              receive_messages_from: {
                                any_contractor = true,
                                any_employer = true,
                                current_contractor = true,
                                current_employer = true,
                                spectrawise = true,
                              } = {},
                            } = {},
                            status: {
                              is_approved = false,
                              is_completed = false,
                              is_employed = false,
                              is_submitted = false,
                            } = {},
                          } = userData || {};
                          let userUpdateData = {
                            contact: {
                              address_line_1,
                              address_line_2,
                              city,
                              country,
                              email,
                              mobile_number,
                              pincode,
                              state,
                            },
                            personal: {
                              gender,
                              nationality,
                              blood_group,
                              dob: personalDob,
                              full_name,
                              handicapped_reason,
                              is_handicapped,
                              profile_image_url,
                            },
                            education: {
                              field,
                              level,
                              url,
                            },
                            employment: {
                              experience,
                              uan,
                            },
                            bank: {
                              account_name,
                              account_number,
                              bank_name,
                              branch_name,
                              ifsc_code,
                              is_verified: bank_is_verified,
                              url: bankUrl,
                            },
                            family: {
                              father_name,
                              is_married,
                              spouse_name,
                            },
                            identity: {
                              aadhaar: {
                                aadhaar_hash,
                                aadhaar_number: aadhaar_aadhaar_number,
                                address_line_1: aadhaar_address_line_1,
                                address_line_2: aadhaar_address_line_2,
                                city: aadhaar_city,
                                country: aadhaa_country,
                                dob: aadhaar_dob,
                                is_verified: aadhaar_is_verified,
                                name: aadhaar_name,
                                pincode: aadhaar_pincode,
                                state: aadhaar_state,
                                url: aadhaar_url,
                              },
                              pan: {
                                dob: pan_dob,
                                is_verified: pan_is_verified,
                                name: pan_name,
                                pan_number: pan_pan_number,
                                url: pan_url,
                              },
                            },
                            nominee: {
                              aadhaar_number,
                              address_line_1: nominee_address_line_1,
                              address_line_2: nominee_address_line_2,
                              city: nominee_city,
                              country: nominee_country,
                              dob,
                              is_personal_address,
                              name,
                              pincode: nominee_pincode,
                              relationship,
                              state: nominee_state,
                            },
                            settings: {
                              receive_messages_from: {
                                any_contractor,
                                any_employer,
                                current_contractor,
                                current_employer,
                                spectrawise,
                              },
                            },
                            status: {
                              is_approved,
                              is_completed,
                              is_employed,
                              is_submitted,
                            },
                          };
                          const update = await updatePost(
                            `/talnt/${id}`,
                            userUpdateData
                          );
                          if (update.data.status === 200) {
                            setUserData(update.data.talentData);
                            setUserDataOriginal(update.data.talentData);
                            setIsEdit(false);
                            message.success({
                              content: "profile updated",
                              key: "saving",
                            });
                          }
                        } else {
                          setIsEdit(false);
                          message.error({
                            content: "Invalid data",
                            key: "massMessage",
                          });
                        }
                      }}
                    >
                      Update
                    </button>
                  </div>
                ) : (
                  <div className="mb-2" style={{ display: "flex" }}>
                    <button
                      style={{
                        backgroundColor: "#0361fe",
                        color: "white",
                        border: "none",
                        fontSize: "12px",
                        padding: "4px 11px",
                        cursor: "pointer",
                        marginBottom: "10px",
                        marginLeft: "70px",
                      }}
                      onClick={() => {
                          setIsEdit(true);
                        }}
                    >
                      Edit User
                    </button>
                  </div>
                )}
                {isEdit && (
                  <div className="mb-2" style={{ display: "flex" }}>
                    <button
                      style={{
                        backgroundColor: "#0361fe",
                        color: "white",
                        border: "none",
                        fontSize: "15px",
                        padding: "6px 10px",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                      onClick={() => {
                        let {
                          contact: {
                            address_line_1: contact_address_line_1,
                            address_line_2: contact_address_line_2,
                            city: contact_city,
                            country: contact_country,
                            email: contact_email,
                            mobile_number: contact_mobile_number,
                            pincode: contact_pincode,
                            state: contact_state,
                          } = {},
                          personal: existingPersonal = {},
                          education: { field, level, url } = {},
                          employment: { experience, uan } = {},
                          bank: {
                            account_name,
                            account_number,
                            bank_name,
                            branch_name,
                            ifsc_code,
                            is_verified,
                            url: bankUrl,
                          } = {},
                          family: { father_name, is_married, spouse_name } = {},
                          identity: {
                            aadhaar: {
                              aadhaar_hash,
                              aadhaar_number,
                              address_line_1: aadhaar_address_line_1,
                              address_line_2: aadhaar_address_line_2,
                              city: aadhaar_city,
                              country: aadhaar_country,
                              dob: aadhaarDob,
                              is_verified: aadhaar_is_verified,
                              name,
                              pincode: aadhaar_pincode,
                              state: aadhaar_state,
                              url: aadhaar_url,
                            } = {},
                            pan: {
                              dob,
                              is_verified: pan_is_verified,
                              name: pan_name,
                              pan_number,
                              url: pan_url,
                            } = {},
                          } = {},
                        } = userDataOriginal || {};

                        const personal = {
                          blood_group: "",
                          dob: "",
                          full_name: "",
                          gender: "",
                          handicapped_reason: "",
                          is_handicapped: false,
                          nationality: "",
                          profile_image_url: null,
                          ...existingPersonal,
                        };

                        setUserData((prev) => ({
                          ...prev,
                          contact: {
                            ...prev.contact,
                            address_line_1: contact_address_line_1,
                            address_line_2: contact_address_line_2,
                            city: contact_city,
                            country: contact_country,
                            email: contact_email,
                            mobile_number: contact_mobile_number,
                            pincode: contact_pincode,
                            state: contact_state,
                          },
                          personal,
                          education: {
                            field,
                            level,
                            url,
                          },
                          employment: {
                            experience,
                            uan,
                          },
                          bank: {
                            account_name,
                            account_number,
                            bank_name,
                            branch_name,
                            ifsc_code,
                            is_verified,
                            url: bankUrl,
                          },
                          family: {
                            father_name,
                            is_married,
                            spouse_name,
                          },
                          identity: {
                            aadhaar: {
                              aadhaar_hash,
                              aadhaar_number,
                              address_line_1: aadhaar_address_line_1,
                              address_line_2: aadhaar_address_line_2,
                              city: aadhaar_city,
                              country: aadhaar_country,
                              dob: aadhaarDob,
                              is_verified: aadhaar_is_verified,
                              name,
                              pincode: aadhaar_pincode,
                              state: aadhaar_state,
                              url: aadhaar_url,
                            },
                            pan: {
                              dob,
                              is_verified: pan_is_verified,
                              name: pan_name,
                              pan_number,
                              url: pan_url,
                            },
                          },
                        }));
                        setIsEdit(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
              <div className="action">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "50px",
                    marginBottom: "10px",
                    marginLeft: "15px",
                  }}
                >
                  {statusFinder(userData.status) == "Yet To Be Verified" ? (
                    <>
                      <button
                        style={{
                          backgroundColor: "#0361fe",
                          color: "white",
                          border: "none",
                          fontSize: "12px", // Reduce font size
                          // padding: '5px 10px',         // Adjust padding for size
                          marginLeft: "-10px",
                          cursor: "pointer",
                        }}
                        className={
                          !userData.status?.is_approved &&
                          !userData.status?.is_submitted
                            ? "btn disabled"
                            : !userData.status?.is_approved &&
                              userData.status?.is_submitted
                            ? "btn"
                            : "btn disabled"
                        }
                        onClick={() => {
                          setIsRejectionModal(true);
                          handleShowModal();
                        }}
                      >
                        {!userData.status?.is_approved &&
                        !userData.status?.is_submitted
                          ? "Rejected"
                          : "Reject"}
                      </button>
                      <button
                        style={{
                          backgroundColor: "#0361fe",
                          color: "white",
                          border: "none",
                          fontSize: "12px", // Reduce font size
                          padding: "5px 10px", // Adjust padding for size
                          cursor: "pointer",
                        }}
                        className={
                          !userData.status?.is_approved &&
                          !userData.status?.is_submitted
                            ? "btn btn-primary disabled"
                            : !userData.status?.is_approved &&
                              userData.status?.is_submitted
                            ? "btn btn-primary"
                            : "btn btn-primary disabled"
                        }
                        onClick={handleShowModal}
                      >
                        {userData.status?.is_approved &&
                        userData.status?.is_submitted
                          ? "Approved"
                          : "Approve"}
                      </button>
                    </>
                  ) : (
                    <span style={{ alignItems: "center" }}>
                      {statusFinder(userData.status)}
                      <img
                        style={{
                          margin: "0 1rem",
                          width: "1em",
                          height: "1em",
                        }}
                        src={statusSRC}
                        alt="status"
                      />
                    </span>
                  )}
                </div>
                <Modal
                  visible={showModal}
                  className="modalClass"
                  onCancel={() => {
                    setIsRejectionModal(false);
                    handleShowModal();
                  }}
                  style={{
                    width: "100px",
                    height: "100px",
                    padding: "10px",
                  }}
                >
                  <h1
                    style={{
                      textAlign: "center",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                      fontSize: "18px",
                    }}
                  >
                    Do you want to {isRejectionModal ? "Reject" : "Approve"}{" "}
                    {userData.personal?.full_name}?
                  </h1>
                  <div
                    className="btnContainer"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <button
                      className="btn"
                      style={{
                        padding: "6px 12px", // Matching smaller button size
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        setIsRejectionModal(false);
                        handleShowModal();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={
                        isRejectionModal ? handleRejection : handleApprove
                      }
                      style={{
                        padding: "6px 12px", // Matching smaller button size
                        fontSize: "14px", // Consistent font size for buttons
                      }}
                    >
                      {isRejectionModal ? "Reject" : "Approve"}
                    </button>
                  </div>
                </Modal>
              </div>
            </div>
          )}
        </section>
        <section className="bodyMain">
          <div
            className="split leftSide"
            style={{ height: "90vh", overflowY: "scroll" }}
          >
            <div>
              <div className="profile">
                <img
                  src={
                    isUrl(userData.personal?.profile_image_url)
                      ? userData.personal?.profile_image_url
                      : userData.personal?.gender == "female"
                      ? femaleUserImage
                      : userImg
                  }
                  onClick={(e) => ImgPopup(e.currentTarget.src)}
                  alt="user"
                  className="userImg imgPopup"
                />
                <div className="proNameSec">
                  <h3 className="empName">{userData.personal?.full_name}</h3>
                  <label className="badge">Employee Name</label>
                </div>
              </div>
              <div
                className="userInfoContainer"
                style={{ padding: "0.5rem 1vw", overflow: "auto" }}
              >
                <div className="detailsSec">
                  <div className="labelField">
                    Mobile number
                    {isEdit && <span style={{ color: "red" }}> </span>}
                  </div>
                  {!isEdit ? (
                    <div className="fieldDetail">
                      {userData.contact?.mobile_number &&
                        userData.contact?.mobile_number}
                    </div>
                  ) : (
                    <Input.Group compact>
                      <Select
                        value={userData?.contact?.mobile_code}
                        // value="+91"
                        onChange={(value) => {
                          setCountryCode(value);
                          setUserData((prev) => ({
                            ...prev,
                            contact: {
                              ...prev.contact,
                              mobile_number:
                                value +
                                userData?.contact?.mobile_number.slice(3),
                            },
                          }));
                        }}
                        className="countryCodeSelect"
                        disabled
                      >
                        <Option value="+91">+91</Option>
                        <Option value="+82">+82</Option>
                        <Option value="+72">+72</Option>
                        <Option value="+53">+53</Option>
                      </Select>
                      <Input
                        style={{ width: "70%" }}
                        placeholder="Enter mobile number"
                        className="phoneNumberInput"
                        value={userData.contact?.mobile_number}
                        name="mobile_number"
                        data-fieldname="Mobile Number"
                        onChange={inputChange}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        readOnly
                      />
                      {errorMessage?.mobile_number && (
                        <div style={{ color: "red", marginTop: "8px" }}>
                          {errorMessage?.mobile_number}
                        </div>
                      )}
                    </Input.Group>
                  )}
                </div>

                <div className="detailsSec">
                  <div className="labelField">Date of Birth</div>
                  {!isEdit ? (
                    <div className="fieldDetail">
                      {dob1
                        ? moment(dob1).format("DD/MM/YYYY")
                        : userData.personal?.dob
                        ? moment(
                            new Timestamp(
                              userData.personal?.dob.seconds ||
                                userData.personal?.dob._seconds,
                              userData.personal?.dob.nanoseconds ||
                                userData.personal?.dob._nanoseconds
                            ).toDate()
                          ).format("DD/MM/YYYY")
                        : "--"}
                    </div>
                  ) : (
                    <Form
                      initialValues={{
                        dateOfBirth: dob1
                          ? moment(dob1)
                          : userData.personal?.dob
                          ? moment(
                              new Timestamp(
                                userData.personal?.dob.seconds ||
                                  userData.personal?.dob._seconds,
                                userData.personal?.dob.nanoseconds ||
                                  userData.personal?.dob._nanoseconds
                              ).toDate()
                            )
                          : null,
                      }}
                    >
                      <Form.Item
                        name="dateOfBirth"
                        rules={[
                          {
                            required: true,
                            message: "Please select your date of birth!",
                          },
                        ]}
                        style={{ marginBottom: "10px" }}
                      >
                        {/* <DatePicker
                          format="YYYY-MM-DD"
                          style={{ width: "100%" }}
                          value={
                            dob1
                              ? moment(dob1)
                              : userData.personal?.dob
                              ? moment(
                                  new Timestamp(
                                    userData.personal?.dob.seconds ||
                                      userData.personal?.dob._seconds,
                                    userData.personal?.dob.nanoseconds ||
                                      userData.personal?.dob._nanoseconds
                                  ).toDate()
                                )
                              : null
                          }
                          onChange={handleDobChange}
                          disabledDate={(current) =>
                            current && current > moment().endOf("day")
                          } // Disable future dates
                        /> */}

                        <DatePicker
                          format="YYYY-MM-DD"
                          style={{ width: "100%" }}
                          value={moment(data?.personal?.dob) || null}
                          onChange={handleDobChange}
                          disabledDate={(current) =>
                            current && current > moment().endOf("day")
                          }
                        />
                      </Form.Item>
                      {errorMessage?.dateOfBirth && (
                        <div style={{ color: "red" }}>
                          {errorMessage?.dateOfBirth}
                        </div>
                      )}
                    </Form>
                  )}
                </div>

                <div className="detailsSec">
                  <div className="labelField">
                    Email Address{" "}
                    {isEdit && <span style={{ color: "red" }}> </span>}
                  </div>
                  {!isEdit ? (
                    <div className="fieldDetail">
                      {userData.contact?.email ? userData.contact.email : "--"}
                    </div>
                  ) : (
                    <div className="inputWrapper">
                      <div className="inputWrapper">
                        <Input
                          placeholder="Email"
                          value={email}
                          name="email"
                          onChange={inputChange}
                          data-fieldname="Email"
                        />
                        {errorMessage?.email && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage?.email}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {/* )} */}
                <div className="detailsSec">
                  <div className="labelField">
                    Gender {isEdit && <span style={{ color: "red" }}> </span>}
                  </div>
                  {!isEdit ? (
                    <div className="fieldDetail">
                      {userData.personal?.gender
                        ? userData.personal.gender
                        : "--"}
                    </div>
                  ) : (
                    <div>
                      <Radio.Group value={userData.personal?.gender}>
                        <Radio
                          value="male"
                          name="gender"
                          onChange={() => {
                            setUserData((prev) => ({
                              ...prev,
                              personal: {
                                ...prev.personal,
                                gender: "male",
                              },
                            }));
                            setErrorMessage((prev) => ({
                              ...prev,
                              gender: "",
                            }));
                          }}
                        >
                          Male
                        </Radio>
                        <Radio
                          value="female"
                          name="gender"
                          onChange={() => {
                            setUserData((prev) => ({
                              ...prev,
                              personal: {
                                ...prev.personal,
                                gender: "female",
                              },
                            }));
                            setErrorMessage((prev) => ({
                              ...prev,
                              gender: "",
                            }));
                          }}
                        >
                          Female
                        </Radio>
                      </Radio.Group>
                      {errorMessage?.gender && (
                        <div style={{ color: "red", marginTop: "8px" }}>
                          {errorMessage?.gender}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="detailsSec">
                  <div className="labelField">
                    Nationality{" "}
                    {isEdit && <span style={{ color: "red" }}> </span>}
                  </div>
                  {!isEdit ? (
                    <div className="fieldDetail">
                      {userData.personal?.nationality
                        ? userData.personal?.nationality
                        : "--"}
                    </div>
                  ) : (
                    <div className="inputWrapper">
                      <Input
                        placeholder="Nationality"
                        value={userData.personal?.nationality}
                        name="nationality"
                        onChange={inputChange}
                        data-fieldname="Nationality"
                      />
                      {errorMessage.nationality && (
                        <div style={{ color: "red", marginTop: "8px" }}>
                          {errorMessage?.nationality}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="detailsSec">
                  <div className="labelField">
                    Address {isEdit && <span style={{ color: "red" }}> </span>}
                  </div>
                  {!isEdit ? (
                    <div className="fieldDetail">
                      {userData.contact?.address_line_1 ||
                      userData.contact?.address_line_2 ||
                      userData.contact?.city ||
                      userData.contact?.state ||
                      userData.contact?.pincode
                        ? `${
                            userData.contact?.address_line_1 &&
                            `${userData.contact?.address_line_1},`
                          }${
                            userData.contact?.address_line_2 &&
                            `${userData.contact?.address_line_2},`
                          }
                              ${
                                userData.contact?.city &&
                                `${userData.contact?.city},`
                              } ${
                            userData.contact?.pincode &&
                            `${userData.contact?.pincode},`
                          } ${
                            userData.contact?.state && userData.contact?.state
                          }`
                        : "--"}
                    </div>
                  ) : (
                    <div style={{ display: "grid", gap: "10%" }}>
                      <div>
                        <label>
                          Address Line 1{" "}
                          {isEdit && <span style={{ color: "red" }}> </span>}
                        </label>
                        <Input
                          placeholder="Address Line 1"
                          value={userData.contact?.address_line_1}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setErrorMessage((prev) => ({
                                ...prev,
                                address_line_1: `Please Enter a valid address line 1`,
                              }));
                            } else {
                              setErrorMessage((prev) => ({
                                ...prev,
                                address_line_1: "",
                              }));
                            }
                            setUserData((prev) => ({
                              ...prev,
                              contact: {
                                ...prev.contact,
                                address_line_1: e.target.value,
                              },
                            }));
                          }}
                        />
                        {errorMessage.address_line_1 && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage?.address_line_1}
                          </div>
                        )}
                      </div>
                      <div>
                        <label>Address Line 2</label>
                        <Input
                          placeholder="Address Line 2"
                          value={userData.contact?.address_line_2}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setErrorMessage((prev) => ({
                                ...prev,
                                address_line_2: `Please Enter a valid address line 2`,
                              }));
                            } else {
                              setErrorMessage((prev) => ({
                                ...prev,
                                address_line_2: "",
                              }));
                            }
                            setUserData((prev) => ({
                              ...prev,
                              contact: {
                                ...prev.contact,
                                address_line_2: e.target.value,
                              },
                            }));
                          }}
                        />
                        {errorMessage.address_line_2 && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage?.address_line_2}
                          </div>
                        )}
                      </div>
                      <div>
                        <label>
                          City{" "}
                          {isEdit && <span style={{ color: "red" }}> </span>}
                        </label>
                        <Input
                          placeholder="City"
                          value={userData.contact?.city}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setErrorMessage((prev) => ({
                                ...prev,
                                city: `Please Enter a valid city`,
                              }));
                            } else {
                              setErrorMessage((prev) => ({
                                ...prev,
                                city: "",
                              }));
                            }
                            setUserData((prev) => ({
                              ...prev,
                              contact: {
                                ...prev.contact,
                                city: e.target.value,
                              },
                            }));
                          }}
                          name="city"
                          data-fieldname="City"
                        />
                        {errorMessage?.city && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage?.city}
                          </div>
                        )}
                      </div>
                      <div>
                        <label>
                          State{" "}
                          {isEdit && <span style={{ color: "red" }}> </span>}
                        </label>
                        <Input
                          placeholder="State"
                          value={userData.contact?.state}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setErrorMessage((prev) => ({
                                ...prev,
                                state: `Please Enter a valid state`,
                              }));
                            } else {
                              setErrorMessage((prev) => ({
                                ...prev,
                                state: "",
                              }));
                            }
                            setUserData((prev) => ({
                              ...prev,
                              contact: {
                                ...prev.contact,
                                state: e.target.value,
                              },
                            }));
                          }}
                        />
                        {errorMessage?.state && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage?.state}
                          </div>
                        )}
                      </div>
                      <div>
                        <label>
                          Country{" "}
                          {isEdit && <span style={{ color: "red" }}> </span>}
                        </label>
                        <Input
                          placeholder="Country"
                          value={userData.contact?.country}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setErrorMessage((prev) => ({
                                ...prev,
                                country: `Please Enter a valid country`,
                              }));
                            } else {
                              setErrorMessage((prev) => ({
                                ...prev,
                                country: "",
                              }));
                            }
                            setUserData((prev) => ({
                              ...prev,
                              contact: {
                                ...prev.contact,
                                country: e.target.value,
                              },
                            }));
                          }}
                        />
                        {errorMessage?.country && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage?.country}
                          </div>
                        )}
                      </div>
                      <div>
                        <label>Pincode</label>
                        <Input
                          placeholder="Pincode"
                          value={userData.contact?.pincode}
                          maxLength={6}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                              if (value.length <= 6) {
                                setUserData((prev) => ({
                                  ...prev,
                                  contact: {
                                    ...prev.contact,
                                    pincode: value,
                                  },
                                }));
                                if (value.length === 6) {
                                  setErrorMessage((prev) => ({
                                    ...prev,
                                    pincode: "",
                                  }));
                                } else {
                                  setErrorMessage((prev) => ({
                                    ...prev,
                                    pincode: "Pincode must be exactly 6 digits",
                                  }));
                                }
                              }
                            }
                          }}
                        />
                        {errorMessage?.pincode && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage?.pincode}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              {isEdit ? (
                <div
                  className="mb-2"
                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                >
                  <button
                    className="btn btn-primary tabBtn"
                    onClick={async () => {
                      const chkMobileQ = query(
                        collection(db, "talent_users"),
                        where("id", "==", id)
                      );

                      const querySnapshot = await getDocs(chkMobileQ);
                      if (!querySnapshot.empty) {
                        console.log("Record found:", id);
                      } else {
                        let res = submitHandler(userData);
                        console.log("No matching record found for:", id);
                        if (res) {
                          await updateDoc(doc(db, "talent_users", id), {
                            ...userData, // Spread userData to update the document correctly
                          });
                          message.success({
                            content: "profile updated",
                            key: "saving",
                          });
                          setIsEdit(false);
                        }
                      }
                    }}
                  >
                    Update
                  </button>
                </div>
              ) : (
                <div
                  className="mb-2"
                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                >
                  <button
                    className="btn btn-primary tabBtn"
                    onClick={() => {
                      setIsEdit(true);
                    }}
                  >
                    Edit User
                  </button>
                </div>
              )}
              {isEdit && (
                <div>
                  <button
                    className={"btn"}
                    onClick={() => {
                      setIsEdit(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div> */}
          </div>

          <div className="split right">
            <div className="tabContainer">
              <div
                aria-valuetext={"1"}
                onClick={(e) => {
                  console.log(e, e.target.getAttribute("aria-valuetext"));
                  setTabIndex(e.target.getAttribute("aria-valuetext"));
                }}
                className={tabIndex === "1" ? "tabItem active" : "tabItem"}
              >
                {"Aadhaar, PAN & Bank Account"}
              </div>
              <div
                aria-valuetext={"2"}
                onClick={(e) =>
                  setTabIndex(e.target.getAttribute("aria-valuetext"))
                }
                className={tabIndex === "2" ? "tabItem active" : "tabItem"}
              >
                {"Education & Employement"}
              </div>
              <div
                aria-valuetext={"3"}
                onClick={(e) =>
                  setTabIndex(e.target.getAttribute("aria-valuetext"))
                }
                className={tabIndex === "3" ? "tabItem active" : "tabItem"}
              >
                {"Contractor & Employer"}
              </div>
              <div
                aria-valuetext={"4"}
                onClick={(e) =>
                  setTabIndex(e.target.getAttribute("aria-valuetext"))
                }
                className={tabIndex === "4" ? "tabItem active" : "tabItem"}
              >
                {"Family & Nominee"}
              </div>
            </div>
            {tabIndex === "1" && (
              <Tab1
                ImgPopup={ImgPopup}
                data={userData}
                isEdit={isEdit}
                setUserData={setUserData}
              />
            )}
            {tabIndex === "2" && (
              <Tab2
                ImgPopup={ImgPopup}
                data={userData}
                isEdit={isEdit}
                setUserData={setUserData}
              />
            )}
            {tabIndex === "3" && data && (
              <Tab3
                ImgPopup={ImgPopup}
                data={data}
                isEdit={isEdit}
                setUserData={setUserData}
              />
            )}
            {tabIndex === "4" && (
              <Tab4
                ImgPopup={ImgPopup}
                data={userData}
                isEdit={isEdit}
                setUserData={setUserData}
              />
            )}
          </div>
        </section>
      </div>
      {visible && <ImgPopupModal {...imgModal} />}
    </>
  );
}

export default UserDetailsBase;
