import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, Descriptions, Typography } from "antd";
import JobDetailHeader from "./JobDetailHeader";
import { useParams } from "react-router-dom/cjs/react-router-dom";

import { dataContext } from "../../context/data";
import { useContext } from "react";

import axios from "axios";
const { Title, Paragraph } = Typography;

function RoleDetailBase() {
  const location = useLocation();
  const [roleData, setRoleData] = useState([]);
  const userContext = useContext(dataContext);

  useEffect(() => {
    setRoleData(location.state?.roleData);
  }, []);

  useEffect(() => {
    axios.get(`/api/api/web/jobRole/${id}`).then((resp) => {
      setRoleData(resp.data.data);
    });
  }, [userContext.isRefreshRole]);
  const { id } = useParams();
  if (!roleData) {
    return <div>No role data available.</div>;
  }

  return (
    <div>
      <JobDetailHeader isRoleList={true} id={roleData.id} roleData={roleData} />
      <div className="jobDetail">
        <div className="topContent">
          <div className="eachField">
            <span className="th">Designation</span>
            <span className="td">{roleData.designation}</span>
          </div>
          <div className="eachField">
            <span className="th">Department</span>
            <span className="td">{roleData.department}</span>
          </div>
          <div className="eachField">
            <span className="th">Principal Employer</span>
            <span className="td">ABC Pvt Ltd</span>
          </div>
          <div className="eachField">
            <span className="th">Salary</span>
            <span className="td">₹1 - 2 /day</span>
          </div>
          {/* <div className="eachField"><span className="th">Description</span><span className="td">{roleData.description}</span></div> */}
        </div>
        <div className="middleContent">
          <div className="description">
            <div className="contentHeader">
              <span>Description</span>
              <span></span>
            </div>
            <div
              className="descriptionContent"
              style={{
                marginTop: "10px",
                color: "rgb(53, 0, 107)",
                fontWeight: "500",
              }}
            >
              {roleData.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoleDetailBase;
