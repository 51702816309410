import React, { useState, useEffect, useCallback, useRef } from "react";
import { Table, Input, Space, Spin, Select, message } from "antd";
import { useHistory } from "react-router-dom";
import debounce from "lodash/debounce";

import { fetchAppliedJobs } from "../../services/jobPostService";

const { Option } = Select;

const AppliedJobsList = (jobPostId) => {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const history = useHistory();
  const [searchField, setSearchField] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");

  const observer = useRef();
  const lastJobElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const loadAppliedJobs = useCallback(
    async (pageNum) => {
      if (!hasMore) return;
      setLoading(true);
      try {
        const response = await fetchAppliedJobs(
          pageNum,
          10,
          jobPostId.jobPostId
        );
        const { totalCount, data } = response;
        if (totalCount <= 10) {
          setHasMore(false); // Prevent further API calls if total count is <= 10
        } else {
          setHasMore(data.length > 0); // Allow further calls if data is available
        }
        setAppliedJobs((prevJobs) =>
          pageNum === 1 ? data : [...prevJobs, ...data]
        );
        setHasMore(data.length > 0);
        setPage(pageNum);
      } catch (error) {
        console.error("Error loading applied jobs:", error);
        message.error("Failed to load applied jobs");
      } finally {
        setLoading(false);
      }
    },
    [searchField, searchTerm]
  );

  const debouncedSearch = useCallback(
    debounce(() => {
      setAppliedJobs([]);
      setPage(1);
      setHasMore(true);
      loadAppliedJobs(1);
    }, 300),
    [loadAppliedJobs]
  );

  useEffect(() => {
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchField, searchTerm, debouncedSearch]);

  useEffect(() => {
    if (page > 1 && hasMore) {
      loadAppliedJobs(page);
    }
  }, [loadAppliedJobs, page, hasMore]);

  const handleSearch = (value, field) => {
    if (field === "term") {
      setSearchTerm(value);
    } else {
      setSearchField(value);
    }
  };

  const handleRowClick = (record) => {
    history.push(`applied-jobs/${record.id}`);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "talentusername",
      key: "talentusername",
      render: (text) => (text ? text : "---"), // Check if data is present
    },
    {
      title: "Nationality",
      dataIndex: "talentusernationality",
      key: "talentusernationality",
      render: (text) => (text ? text : "---"), // Display "---" if no data
    },
    {
      title: "Mobile",
      dataIndex: "talentusermobile",
      key: "talentusermobile",
      ellipsis: true,
      render: (text) => (text ? text : "---"), // Handle missing mobile number
    },
  ];
  

  return (
    <div className="applied-jobs-list-container">
      {appliedJobs && appliedJobs.length > 0 ? (
        <>
          <h1 style={{ textAlign: "center" }}>Applied Jobs</h1>
          <Table
            className="applied-jobs-table"
            columns={columns}
            dataSource={appliedJobs}
            rowKey="id"
            loading={loading}
            onRow={(record, index) => ({
              onClick: () => handleRowClick(record),
              ref: index === appliedJobs.length - 1 ? lastJobElementRef : null,
            })}
            pagination={false}
          />
          {loading && hasMore && (
            <div className="loading-more">
              <Spin />
            </div>
          )}
        </>
      ) : ( ''
      )}
    </div>
  );
  
  
};

export default AppliedJobsList;
