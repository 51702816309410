import moment from "moment";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";

function base64ToBlob(base64String) {
  const byteString = atob(base64String.split(",")[1]);
  const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

export async function uploadImageAndUpdateFirestore(
  base64String,
  userId,
  setUserData,
  uploadImgFiledName,
  urlIndex,
  data
) {
  const storage = getStorage();
  const firestore = getFirestore();

  const storageRef = ref(
    storage,
    `${uploadImgFiledName}_images/${moment().format("YYYYMMDD_hhmmss_SSS")}.jpg`
  );

  const blob = base64ToBlob(base64String);

  try {
    const snapshot = await uploadBytes(storageRef, blob);
    const downloadURL = await getDownloadURL(snapshot.ref);
    const userDocRef = doc(firestore, "talent_users", userId);
    if (uploadImgFiledName.startsWith("education")) {
      const existingUrls = data.education.url || [];
      if (!Array.isArray(existingUrls)) {
        throw new Error("The 'url' field is not an array.");
      }

      const updatedUrls = [...existingUrls];
      updatedUrls[urlIndex] = downloadURL;

      setUserData((prev) => ({
        ...prev,
        education: {
          ...prev.education,
          url: updatedUrls,
        },
      }));

      console.log("Image uploaded and Firestore updated successfully!");
      return downloadURL;
    } else {
      setUserData((prev) => ({
        ...prev,
        identity: {
          ...prev.identity,
          [uploadImgFiledName]: {
            ...prev.identity[uploadImgFiledName],
            url: downloadURL,
          },
        },
      }));
    }
  } catch (error) {
    console.error("Error uploading image and updating Firestore:", error);
  }
}
