import React, { useEffect, useContext } from "react";
import { Tabs } from "antd";
import { dataContext } from "../../context/data";
import "../../styles/jobList.scss";
import JobManagement from "../../pages/JobManagement";

function TopTab() {
  const { TabPane } = Tabs;
  const { jobData, setJobData } = useContext(dataContext);

  useEffect(() => {
    if (setJobData) {
      setJobData((prev) => ({
        ...prev,
        activeTabIndex: prev.activeTabIndex || "1",
      }));
    }
  }, [setJobData]);

  if (!jobData) {
    return null;
  }

  return <JobManagement />;
}

export default TopTab;
