import React from "react";
import TopBar from "../component/TopBar";
import { Route, Switch } from "react-router-dom";
import DashBoard from "../component/DashBoard";
import JobList from "../component/JobList";
import UserPanel from "../component/UserPanel";
import UserDetailsBase from "../component/panel/UserDetails/UserDetailsBase";
// import TotalTalents from '../component/dashboard/TotalTalents';
import TotalTalensView from "../component/dashboard/TotalTalentsView";
import Requests from "../component/Requests";

function Primary(props) {
  const userPath = "primary";
  const userType = "primary";

  return (
    <div className="body">
      <TopBar userPath={userPath} />
      <div className="content">
        <Switch>
          <Route path="/primary/userPanel">
            <UserPanel userType={userType} userPath={userPath} />
          </Route>
          <Route path="/primary/" exact>
            <DashBoard userType={userType} userPath={userPath} />
          </Route>
          <Route path="/primary/jobpost">
            <JobList userType={userType} userPath={userPath} />
          </Route>
          <Route path="/primary/requests">
            <Requests userType={userType} userPath={userPath} />
          </Route>
          <Route path={"/" + userPath + "/user"}>
            <UserDetailsBase userType={userType} userPath={userPath} />
          </Route>
          <Route path={"/" + userPath + "/totalTalents"}>
            <TotalTalensView userPath={userPath} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default Primary;
